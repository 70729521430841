/* eslint-disable react/no-array-index-key */
import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Typography, IconButton, Popover, Divider } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

function Legend() {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'legend-popover' : undefined;

  const legendItems = [
    {
      colors: [
        theme.palette.cs2.smoke.base,
        theme.palette.cs2.smoke.t,
        theme.palette.cs2.smoke.ct,
      ],
      label: 'Smoke',
    },
    { colors: [theme.palette.cs2.molotov], label: 'Molotov' },
    { colors: [theme.palette.cs2.flash], label: 'Flashbang' },
    { colors: [theme.palette.cs2.he], label: 'High Explosive' },
  ];

  return (
    <>
      <IconButton
        onClick={handleClick}
        sx={{
          position: 'absolute',
          top: 10,
          left: 10,
          backgroundColor: 'rgba(255, 255, 255, 0.7)',
          '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.9)',
          },
        }}
        aria-describedby={id}
      >
        <InfoIcon />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          elevation: 3,
          sx: { borderRadius: 2 },
        }}
      >
        <Box sx={{ p: 2, maxWidth: 220 }}>
          <Typography variant="h7" fontWeight="bold" gutterBottom>
            Grenades
          </Typography>
          <Divider sx={{ my: 1 }} />
          {legendItems.map((item, index) => (
            <Box
              key={index}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              mb={1.5}
            >
              <Typography variant="body2" sx={{ mr: 2 }}>
                {item.label}
              </Typography>
              <Box display="flex">
                {item.colors.map((color, colorIndex) => (
                  <Box
                    key={colorIndex}
                    width={20}
                    height={20}
                    bgcolor={color}
                    borderRadius="50%"
                    ml={colorIndex > 0 ? 0.5 : 0}
                    border={2}
                    borderColor="grey.300"
                  />
                ))}
              </Box>
            </Box>
          ))}
        </Box>
      </Popover>
    </>
  );
}

export default Legend;
