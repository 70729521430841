import {
  Box,
  Card,
  CardContent,
  Grid,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import axiosInstance from '../../axiosInstance.js';

function PaymentHistory() {
  const [transactions, setTransactions] = useState([]);
  const [nextPayment, setNextPayment] = useState(null);

  // get transactions and calculate next payment on component mount
  useEffect(() => {
    // Fetch transactions and next payment from the API
    const fetchPaymentData = async () => {
      try {
        const transactionsResponse =
          await axiosInstance.get(`api/transactions`);
        setTransactions(transactionsResponse.data);
        const nextPaymentDate = new Date(transactionsResponse.data[0].date);
        nextPaymentDate.setMonth(nextPaymentDate.getMonth() + 1);
        const formattedDate = nextPaymentDate.toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        });
        setNextPayment({
          amount: transactionsResponse.data[0].amountPaid / 100,
          date: formattedDate,
        });
      } catch (error) {
        console.error('Failed to fetch payment data', error);
      }
    };

    fetchPaymentData();
  }, []);

  return (
    <Paper elevation={3} sx={{ padding: 3, marginTop: 1 }}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h6">Payment history</Typography>
      </Box>
      <Grid
        container
        direction="row"
        alignItems="flex-start"
        spacing={2}
        sx={{ marginTop: 1 }}
      >
        <Grid item xs={12} sm={4}>
          <Card>
            <CardContent>
              {nextPayment && (
                <Box>
                  <Typography gutterBottom variant="h6">
                    Upcoming payment
                  </Typography>
                  <Typography variant="body2">
                    Amount: €{nextPayment.amount}
                  </Typography>
                  <Typography variant="body2">
                    Date: {nextPayment.date}
                  </Typography>
                </Box>
              )}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Card variant="outlined">
            <CardContent>
              <Typography variant="h6">Past payments</Typography>
              <List>
                {transactions.slice(0, 3).map((transaction) => (
                  <ListItem key={transaction._id}>
                    <ListItemText
                      primary={`Transaction ID: ${transaction._id}`}
                      secondary={
                        <>
                          {`Amount: €${transaction.amountPaid / 100}`}
                          <br />
                          {`Date: ${new Date(
                            transaction.date,
                          ).toLocaleDateString('en-US', {
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric',
                          })}`}
                        </>
                      }
                    />
                    <ListItemSecondaryAction>
                      <Typography variant="body2">
                        <b>{transaction.status}</b>
                      </Typography>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default PaymentHistory;
