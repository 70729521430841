import React, { createContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [authState, setAuthState] = useState({ user: null });

  const setUser = (user) => {
    setAuthState({ user });
  };
  const removeUser = () => {
    setAuthState({ user: null });
  };

  const contextValue = useMemo(
    () => ({
      ...authState,
      setUser,
      removeUser,
    }),
    [authState],
  );

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useAuth = () => React.useContext(AuthContext);

export default AuthContext;
