import React, { useRef, useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import TeamInfo from './replay/TeamInfo';
import DemoControllerBar from './replay/DemoControllerBar';
import DemoPlayer from './replay/DemoPlayer';
import Loading from './common/Loading';
import InfoBar from './replay/InfoBar';
import NotificationSnackbar from './common/NotificationSnackbar';
import { useMatchData } from '../utils/MatchDataContext';
import CustomTooltip from './CustomTooltip.jsx';
import { useRefs } from '../utils/RefContext.js';

const NAV_RAIL_WIDTH = 80; // Adjust this value to match your nav rail width

const tooltipConfigs = [
  {
    component: 'Map',
    content: (
      <Box>
        <Typography variant="subtitle2">
          Experience rounds from a bird&apos;s-eye view:
        </Typography>
        <Typography variant="subtitle2" mt={1}>
          Track player movements, grenade usage, and bomb planting
        </Typography>
        <Typography variant="subtitle2" mt={1}>
          Sketch directly on the map to visualize strategy improvements and
          analyze the replay, save them by clicking notes and add note
        </Typography>
      </Box>
    ),
    placement: 'left',
    anchorRef: null,
  },
  {
    component: 'Team',
    content: (
      <Box>
        <Typography variant="subtitle2">
          Observe each player&apos;s equipped gear, health status, and money
          balance
        </Typography>
        <Typography variant="subtitle2" mt={1}>
          Click player cards to highlight them on the map for easy tracking
        </Typography>
      </Box>
    ),
    placement: 'right',
    anchorRef: null,
  },
  {
    component: 'Bar',
    content: (
      <Box>
        <Typography variant="subtitle2">
          View the current score, current round time, bomb defusal countdown and
          AI round predictions regarding the probability per side to win
        </Typography>
      </Box>
    ),
    placement: 'bottom',
    anchorRef: null,
  },
  {
    component: 'Controller',
    content: (
      <Box>
        <Typography variant="subtitle2">
          Select rounds (color-coded for the round winning team)
        </Typography>
        <Typography variant="subtitle2" mt={1}>
          Control the replay with common controls (e.g., space to play/pause) or
          the player slider. The player slider shows detailed round info,
          including player deaths and bomb plants. Click slider icons to jump
          right into the situation
        </Typography>
        <Typography variant="subtitle2" mt={1}>
          Take round related in-game notes with our handy note tool, note icons
          are added to the player slider for easy access
        </Typography>
      </Box>
    ),
    placement: 'top',
    anchorRef: null,
  },
  {
    component: 'Context',
    content: (
      <Box>
        <Typography variant="subtitle2">
          Change pencil thickness/color, save your drawings as a screenshot, or
          delete them
        </Typography>
      </Box>
    ),
    placement: 'bottom',
    anchorRef: null,
  },
];

function Replay() {
  const {
    currentMapData,
    currentRoundData,
    error,
    setError,
    fetchMapData,
    fetchRoundData,
    currentRoundNumber,
    mapID,
  } = useMatchData();

  const [demoPlayerSize, setDemoPlayerSize] = useState({
    width: 600,
    height: 600,
  });
  const [isNotesDrawerOpen, setIsNotesDrawerOpen] = useState(false);
  const demoPlayerContainerRef = useRef(null);

  const [tutReplay, setTutReplay] = useState(
    localStorage.getItem('tutReplay') === 'true' || false,
  );
  const [currentTooltipIndex, setCurrentTooltipIndex] = useState(0);
  const barAnchorRef = useRef(null);
  const teamAnchorRef = useRef(null);
  const controllerAnchorRef = useRef(null);
  const contextMenuAnchorRef = useRef(null);
  const { getRef } = useRefs();
  const refContextMenu = getRef('contextMenuReplay');

  useEffect(() => {
    if (refContextMenu) {
      contextMenuAnchorRef.current = refContextMenu;
    }
  }, [refContextMenu]);

  useEffect(() => {
    tooltipConfigs[2].anchorRef = barAnchorRef;
    tooltipConfigs[1].anchorRef = teamAnchorRef;
    tooltipConfigs[0].anchorRef = demoPlayerContainerRef;
    tooltipConfigs[3].anchorRef = controllerAnchorRef;
    tooltipConfigs[4].anchorRef = contextMenuAnchorRef;
  }, [
    barAnchorRef,
    teamAnchorRef,
    demoPlayerContainerRef,
    controllerAnchorRef,
    contextMenuAnchorRef,
  ]);

  useEffect(() => {
    fetchMapData();
  }, [mapID]);

  useEffect(() => {
    fetchRoundData();
  }, [currentRoundNumber]);

  useEffect(() => {
    // this is a little bit hacky but necessary to readjust all the players and the background image
    const updateDemoPlayerSize = () => {
      if (demoPlayerContainerRef.current) {
        const { width, height } =
          demoPlayerContainerRef.current.getBoundingClientRect();
        const size = Math.min(width, height);
        setDemoPlayerSize({ width: size, height: size });
      }
    };

    updateDemoPlayerSize();
    window.addEventListener('resize', updateDemoPlayerSize);

    return () => window.removeEventListener('resize', updateDemoPlayerSize);
  }, [currentRoundData, demoPlayerContainerRef.current]);

  if (!currentMapData || !currentRoundData) {
    return <Loading />;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        ml: `${NAV_RAIL_WIDTH}px`,
      }}
    >
      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          minHeight: 0,
        }}
      >
        <Box
          ref={teamAnchorRef}
          sx={{
            width: '25%',
            display: 'flex',
            justifyContent: 'center',
            overflow: 'hidden',
          }}
        >
          <TeamInfo left />
        </Box>
        <Box
          sx={{
            width: '50%',
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
          }}
        >
          <Box ref={barAnchorRef}>
            <InfoBar />
          </Box>
          <Box
            ref={demoPlayerContainerRef}
            sx={{
              flexGrow: 1,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              overflow: 'auto',
            }}
          >
            <DemoPlayer
              width={demoPlayerSize.width}
              height={demoPlayerSize.height}
              isNotesDrawerOpen={isNotesDrawerOpen}
            />
          </Box>
        </Box>
        <Box
          sx={{
            width: '25%',
            display: 'flex',
            justifyContent: 'center',
            overflow: 'hidden',
          }}
        >
          <TeamInfo left={false} />
        </Box>
      </Box>
      <Box ref={controllerAnchorRef}>
        <DemoControllerBar
          isNotesDrawerOpen={isNotesDrawerOpen}
          setIsNotesDrawerOpen={setIsNotesDrawerOpen}
          tutReplay={tutReplay}
        />
      </Box>
      <NotificationSnackbar
        open={!!error}
        message={error || ''}
        severity="error"
        onClose={() => setError(null)}
      />
      {tooltipConfigs.map((config, index) => (
        <CustomTooltip
          /* eslint-disable-next-line react/no-array-index-key */
          key={index}
          open={tutReplay && currentTooltipIndex === index}
          content={config.content}
          currentTooltipIndex={currentTooltipIndex}
          setCurrentTooltipIndex={setCurrentTooltipIndex}
          setTut={setTutReplay}
          placement={config.placement}
          anchorRef={config.anchorRef}
          maxIndex={tooltipConfigs.length - 1}
          sessionItem="tutReplay"
        />
      ))}
    </Box>
  );
}

export default Replay;
