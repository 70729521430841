import React, { createContext, useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

const RefContext = createContext();

export function RefProvider({ children }) {
  const [refs, setRefs] = useState({});

  const setRef = (key, ref) => {
    setRefs((prevRefs) => ({ ...prevRefs, [key]: ref }));
  };

  const getRef = (key) => refs[key];

  const contextValue = useMemo(
    () => ({
      ...refs,
      setRef,
      getRef,
    }),
    [refs],
  );

  return (
    <RefContext.Provider value={contextValue}>{children}</RefContext.Provider>
  );
}

RefProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useRefs = () => useContext(RefContext);

export default RefContext;
