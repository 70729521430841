import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
} from '@mui/material';
import { CancelOutlined, CheckCircleOutline } from '@mui/icons-material';
import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import axiosInstance from '../../axiosInstance.js';

const validationSchema = Yup.object({
  oldPassword: Yup.string().required('Old password is required'),
  password: Yup.string()
    .min(6, 'Password must be at least 6 characters long')
    .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
    .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
    .matches(/\d/, 'Password must contain at least one number')
    .required('Password is required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Password confirmation is required'),
});

const passwordRequirements = [
  { label: 'At least 6 characters long', regex: /.{6,}/ },
  { label: 'Contains an uppercase letter', regex: /[A-Z]/ },
  { label: 'Contains a lowercase letter', regex: /[a-z]/ },
  { label: 'Contains a number', regex: /\d/ },
];

function PasswordChangeDialog({
  isPasswordPopupOpen,
  setIsPasswordPopupOpen,
  setSnackbarMessage,
  setSnackbarSeverity,
  setSnackbarOpen,
}) {
  const handlePasswordSubmit = async (values) => {
    try {
      const passwordData = {
        oldPassword: values.oldPassword,
        newPassword: values.password,
      };
      const response = await axiosInstance.post(
        '/api/users/change-password',
        passwordData,
      );
      if (
        response.status === 200 &&
        response.data.message === 'Password successfully changed'
      ) {
        setIsPasswordPopupOpen(false);
        setSnackbarMessage('Password successfully changed');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.debug('Failed to change password');
      if (error.response && error.response.status === 401) {
        setSnackbarMessage('Password change failed, old password is incorrect');
      } else if (error.response && error.response.status === 409) {
        setSnackbarMessage('Password change failed, new password is the same');
      } else {
        setSnackbarMessage('Failed to change password, please try again');
      }
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      // needed to ensure state reset after form submission
      // eslint-disable-next-line no-use-before-define
      formik.setSubmitting(false);
      // eslint-disable-next-line no-use-before-define
      formik.resetForm();
    }
  };

  const formik = useFormik({
    initialValues: {
      oldPassword: '',
      password: '',
      confirmPassword: '',
    },
    validationSchema,
    onSubmit: handlePasswordSubmit,
  });

  const getPasswordStrength = (password) =>
    passwordRequirements.map((requirement) => ({
      ...requirement,
      isMet: requirement.regex.test(password),
    }));

  return (
    <Dialog
      open={isPasswordPopupOpen}
      onClose={() => setIsPasswordPopupOpen(false)}
      PaperProps={{
        sx: {
          width: '400px',
          maxWidth: '90vw',
        },
      }}
    >
      <DialogTitle>Change password</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please enter your old password and new password
        </DialogContentText>
        <TextField
          margin="normal"
          required
          fullWidth
          id="oldPassword"
          label="Old Password"
          name="oldPassword"
          type="password"
          autoComplete="password"
          autoFocus
          value={formik.values.oldPassword}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched.oldPassword && Boolean(formik.errors.oldPassword)
          }
          helperText={formik.touched.oldPassword && formik.errors.oldPassword}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          id="password"
          label="New Password"
          name="password"
          type="password"
          autoComplete="new-password"
          value={formik.values.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.password && Boolean(formik.errors.password)}
        />
        {(formik.values.password !== '' || formik.touched.password) &&
          formik.errors.password && (
            <List
              dense
              sx={{
                fontSize: '0.75rem',
                padding: 0,
              }}
            >
              {getPasswordStrength(formik.values.password).map(
                (requirement, index) => (
                  <ListItem
                    /* eslint-disable-next-line react/no-array-index-key */
                    key={index}
                    sx={{
                      padding: '0 0',
                    }}
                  >
                    <ListItemIcon>
                      {requirement.isMet ? (
                        <CheckCircleOutline
                          color="success"
                          sx={{ fontSize: '1rem' }}
                        />
                      ) : (
                        <CancelOutlined
                          color="error"
                          sx={{ fontSize: '1rem' }}
                        />
                      )}
                    </ListItemIcon>
                    <ListItemText
                      primary={requirement.label}
                      sx={{
                        color: requirement.isMet
                          ? 'text.primary'
                          : 'text.secondary',
                        fontSize: '0.75rem',
                        margin: 0,
                      }}
                    />
                  </ListItem>
                ),
              )}
            </List>
          )}
        <TextField
          margin="normal"
          required
          fullWidth
          id="confirmPassword"
          label="Confirm New Password"
          name="confirmPassword"
          type="password"
          autoComplete="new-password"
          value={formik.values.confirmPassword}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched.confirmPassword &&
            Boolean(formik.errors.confirmPassword)
          }
          helperText={
            formik.touched.confirmPassword && formik.errors.confirmPassword
          }
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setIsPasswordPopupOpen(false)} sx={{ mb: 1 }}>
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={formik.handleSubmit}
          sx={{ mb: 1, mr: 1 }}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}

PasswordChangeDialog.propTypes = {
  isPasswordPopupOpen: PropTypes.bool.isRequired,
  setIsPasswordPopupOpen: PropTypes.func.isRequired,
  setSnackbarMessage: PropTypes.func.isRequired,
  setSnackbarSeverity: PropTypes.func.isRequired,
  setSnackbarOpen: PropTypes.func.isRequired,
};

export default PasswordChangeDialog;
