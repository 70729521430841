import {
  Box,
  Button,
  IconButton,
  LinearProgress,
  Paper,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  CloudUpload as CloudUploadIcon,
  Close as CloseIcon,
} from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axiosInstance from '../../axiosInstance.js';
import { useAuth } from '../../utils/AuthContext.js';

// Custom tab component to show tooltip when disabled
// workaround for Tooltip is adding DOM attributes to the component which is not supported
function CustomTab({ label, value, disabled, tooltip, ...props }) {
  return (
    <Tooltip title={tooltip} disableHoverListener={!disabled}>
      <span>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Tab label={label} value={value} disabled={disabled} {...props} />
      </span>
    </Tooltip>
  );
}

CustomTab.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  tooltip: PropTypes.string.isRequired,
};

function UploadFunctionality({
  setUploadComplete,
  tabSelection,
  setTabSelection,
}) {
  const [disableUpload, setDisableUpload] = useState(false);
  const [uploads, setUploads] = useState(
    JSON.parse(localStorage.getItem('uploads')) || [],
  );
  const { user } = useAuth();
  const myDemosDisabled = !user || !user.verified || user.subscription === 0;

  // check user subscription status and verification status
  useEffect(() => {
    if (!user) return;
    if (!user.verified || user.subscription === 0) {
      setDisableUpload(true);
    } else {
      setDisableUpload(false);
    }
  }, [user]);

  // get uploads from session storage on component mount
  useEffect(() => {
    const savedUploads = JSON.parse(localStorage.getItem('uploads')) || [];
    setUploads(savedUploads);
  }, []);

  // save uploads to session storage on change and update upload button state
  useEffect(() => {
    localStorage.setItem('uploads', JSON.stringify(uploads));
    const activeUploads = uploads.filter(
      (upload) =>
        upload.status === 'Uploading' || upload.status === 'Processing',
    ).length;
    if (!user) return;
    if (!user.verified || user.subscription === 0) {
      setDisableUpload(true);
    } else {
      // set value of max simultaneous uploads
      setDisableUpload(activeUploads >= 2);
    }
  }, [uploads, user]);

  // when uploads in progress, fetch progress every 5 seconds
  useEffect(() => {
    let intervalId;

    if (uploads.some((upload) => upload.status === 'Processing')) {
      intervalId = setInterval(() => {
        uploads.forEach((upload) => {
          if (upload.status === 'Processing') {
            axiosInstance
              .get(`/api/actions/upload-progress/${upload.jobId}`)
              .then((response) => {
                const progressData = response.data;
                if (progressData.status === 'Completed') {
                  setUploadComplete(true);
                }
                setUploads((prevUploads) =>
                  prevUploads.map((u) =>
                    u.jobId === upload.jobId
                      ? {
                          ...u,
                          progress: progressData.progress,
                          status: progressData.status,
                        }
                      : u,
                  ),
                );
              })
              .catch((error) => {
                if (
                  error.response &&
                  (error.response.status === 404 ||
                    error.response.status === 500)
                ) {
                  setUploads((prevUploads) =>
                    prevUploads.map((u) =>
                      u.jobId === upload.jobId
                        ? { ...u, status: 'Failed', progress: 0 }
                        : u,
                    ),
                  );
                }
                console.error('Failed to fetch upload progress:', error);
              });
          }
        });
      }, 4000);
    }

    // when the component unmounts or when there are no uploads in progress,
    // the cleanup function returned clears the interval, stopping the polling.
    return () => clearInterval(intervalId);
  }, [uploads]);

  // update upload progress in the state for the given upload id
  const updateUploadProgress = (jobId, progress) => {
    setUploads((prevUploads) =>
      prevUploads.map((upload) =>
        upload.jobId === jobId ? { ...upload, progress } : upload,
      ),
    );
  };

  // handle the file upload
  const handleUpload = async (upload) => {
    const formData = new FormData();
    formData.append('demo', upload.file);
    try {
      const response = await axiosInstance.post(
        '/api/actions/upload-demo',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress: (progressEvent) => {
            const progress = Math.round(
              (progressEvent.loaded / progressEvent.total) * 100,
            );
            updateUploadProgress(upload.jobId, progress);
          },
        },
      );
      if (
        response.status === 200 &&
        response.data.message === 'File already uploaded'
      ) {
        setUploads((prevUploads) =>
          prevUploads.map((u) =>
            u.jobId === upload.jobId
              ? { ...u, status: 'Already existing', progress: 100 }
              : u,
          ),
        );
      } else if (response.status === 200) {
        const { jobId } = response.data;
        setUploads((prevUploads) =>
          prevUploads.map((u) =>
            u.jobId === upload.jobId
              ? {
                  ...u,
                  status: 'Processing',
                  jobId,
                  progress: 0,
                  startTime: Date.now(),
                }
              : u,
          ),
        );
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.debug('Failed to upload demo');
        setUploads((prevUploads) =>
          prevUploads.map((u) =>
            u.jobId === upload.jobId
              ? { ...u, status: 'Failed', progress: 0 }
              : u,
          ),
        );
      } else if (error.response && error.response.status === 403) {
        console.debug('Upload limit reached');
        setUploads((prevUploads) =>
          prevUploads.map((u) =>
            u.jobId === upload.jobId
              ? {
                  ...u,
                  status: 'Limit reached',
                  progress: 0,
                }
              : u,
          ),
        );
      }
    }
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const newUpload = {
        jobId: Date.now(),
        file,
        progress: 0,
        status: 'Uploading',
        fileName: file.name,
        startTime: Date.now(),
      };
      await setUploads((prevUploads) => [...prevUploads, newUpload]);
      await handleUpload(newUpload);
    }
  };

  const removeUpload = (jobId) => {
    setUploads((prevUploads) =>
      prevUploads.filter((upload) => upload.jobId !== jobId),
    );
  };

  const calculateRemainingTime = (startTime, progress) => {
    if (progress < 9) return '';
    const elapsed = Date.now() - startTime;
    const remaining = elapsed * (100 / progress) - elapsed;
    // convert to minutes and round up
    const remainingMinutes = Math.ceil(remaining / 60000);
    return remainingMinutes <= 1
      ? ', less than a minute left'
      : `, ${remainingMinutes} minutes left`;
  };

  const getDisabledReason = () => {
    if (!user) return 'You need to be logged in';
    if (!user.verified) return 'Verify your email first';
    if (user.subscription === 0)
      return 'Subscribe to a plan to access and upload your own demos';
    return '';
  };

  const getTooltipTitle = () => {
    if (disableUpload) {
      if (!user.verified) {
        return 'Verify your email first';
      }
      if (user.subscription === 0) {
        return 'Subscribe to a plan to upload demos';
      }
      return 'Maximum limit of simultaneous demo uploads reached';
    }
    return '';
  };

  const handleTabChange = (event, newValue) => {
    setTabSelection(newValue);
  };

  return (
    <Paper elevation={3} sx={{ padding: 3 }}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Tooltip
          title={getTooltipTitle()}
          disableHoverListener={!disableUpload}
        >
          <span>
            <Button
              size="large"
              component="label"
              variant="contained"
              startIcon={<CloudUploadIcon />}
              disabled={disableUpload}
            >
              Upload demo
              <input
                type="file"
                accept=".dem"
                id="file-upload"
                onChange={handleFileChange}
                style={{ display: 'none' }}
              />
            </Button>
          </span>
        </Tooltip>
        <Tabs value={tabSelection} onChange={handleTabChange}>
          <CustomTab
            label="My demos"
            value="My demos"
            disabled={myDemosDisabled}
            tooltip={getDisabledReason()}
          />
          <Tab label="Public demos" value="Public demos" />
        </Tabs>
      </Box>
      {uploads.map((upload) => (
        <Box key={upload.jobId} mt={2}>
          <Typography variant="body2">{upload.fileName}</Typography>
          {upload.status === 'Uploading' && (
            <Box>
              <LinearProgress variant="determinate" value={upload.progress} />
              <Typography variant="body2">
                Uploading demo {upload.progress}%
              </Typography>
            </Box>
          )}
          {upload.status === 'Processing' && (
            <Box>
              <LinearProgress variant="determinate" value={upload.progress} />
              <Typography variant="body2">
                Processing
                {calculateRemainingTime(upload.startTime, upload.progress)}
              </Typography>
            </Box>
          )}
          {upload.status === 'Completed' && (
            <Box display="flex" alignItems="center" flexGrow={1}>
              <Typography variant="body2" color="green" flexGrow={1}>
                Demo successfully processed
              </Typography>
              <IconButton
                size="small"
                onClick={() => removeUpload(upload.jobId)}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          )}
          {upload.status === 'Already existing' && (
            <Box display="flex" alignItems="center" flexGrow={1}>
              <Typography variant="body2" color="green" flexGrow={1}>
                Demo successfully uploaded (already exists)
              </Typography>
              <IconButton
                size="small"
                onClick={() => removeUpload(upload.jobId)}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          )}
          {upload.status === 'Failed' && (
            <Box display="flex" alignItems="center" flexGrow={1}>
              <Typography variant="body2" color="red" flexGrow={1}>
                Failed to upload demo
              </Typography>
              <IconButton
                size="small"
                onClick={() => removeUpload(upload.jobId)}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          )}
          {upload.status === 'Limit reached' && (
            <Box display="flex" alignItems="center" flexGrow={1}>
              <Typography variant="body2" color="red" flexGrow={1}>
                You have reached your monthly upload limit
              </Typography>
              <IconButton
                size="small"
                onClick={() => removeUpload(upload.jobId)}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          )}
        </Box>
      ))}
    </Paper>
  );
}

UploadFunctionality.propTypes = {
  setUploadComplete: PropTypes.func.isRequired,
  tabSelection: PropTypes.string.isRequired,
  setTabSelection: PropTypes.func.isRequired,
};

export default UploadFunctionality;
