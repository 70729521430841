import {
  Container,
  Typography,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Link,
} from '@mui/material';
import React, { useState } from 'react';

function Help() {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <Container>
      <Typography variant="h4" align="center">
        <strong>FAQ</strong>
      </Typography>
      <Box mt={1}>
        <Typography align="center" gutterBottom>
          Have a look at frequently asked questions
        </Typography>
      </Box>
      <Box mt={10} mb={1}>
        <Typography variant="h5">General Information</Typography>
      </Box>
      <Accordion
        expanded={expanded === 'panel1'}
        onChange={handleChange('panel1')}
      >
        <AccordionSummary>What is DEFUZE?</AccordionSummary>
        <AccordionDetails>
          DEFUZE is a cutting edge tool designed to revolutionize game analysis
          for Counter-Strike players and coaches by leveraging deep learning. It
          provides in-depth game insights, performance metrics, and strategic
          recommendations.
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === 'panel2'}
        onChange={handleChange('panel2')}
      >
        <AccordionSummary>Who can benefit from using DEFUZE?</AccordionSummary>
        <AccordionDetails>
          DEFUZE is beneficial for a wide range of users including casual
          players, ambitious players, and professional coaches. It helps
          strategic understanding and performance improvement through detailed
          analysis and insights.
        </AccordionDetails>
      </Accordion>
      <Box mt={5} mb={1}>
        <Typography variant="h5">Features and Functionality</Typography>
      </Box>
      <Accordion
        expanded={expanded === 'panel3'}
        onChange={handleChange('panel3')}
      >
        <AccordionSummary>
          What are the key features of DEFUZE?
        </AccordionSummary>
        <AccordionDetails>
          Our key features include:
          <ul>
            <li>2D replays of matches</li>
            <li>Advanced round filtering</li>
            <li>Detailed statistics and insights</li>
            <li>AI-generated win predictions</li>
          </ul>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === 'panel4'}
        onChange={handleChange('panel4')}
      >
        <AccordionSummary>How does the AI analysis work?</AccordionSummary>
        <AccordionDetails>
          The AI analyzes past games to predict win probabilities at crucial
          points such as the start of the round and when the bomb is planted.
          This helps users strategize effectively by understanding potential
          outcomes and making informed decisions.
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === 'panel5'}
        onChange={handleChange('panel5')}
      >
        <AccordionSummary>
          Can I upload my own demo files for analysis?
        </AccordionSummary>
        <AccordionDetails>
          Yes, users can upload demo files, which are then parsed and analyzed
          to provide structured data, predictions, and strategic insights.
        </AccordionDetails>
      </Accordion>
      <Box mt={5} mb={1}>
        <Typography variant="h5">Subscription and Pricing</Typography>
      </Box>
      <Accordion
        expanded={expanded === 'panel6'}
        onChange={handleChange('panel6')}
      >
        <AccordionSummary>
          What subscription plans are available?
        </AccordionSummary>
        <AccordionDetails>
          DEFUZE offers a freemium model where users can access basic features
          for free. For more advanced analytics and features, there are premium
          subscription plans available for individuals and customized packages
          for esports organizations.
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === 'panel7'}
        onChange={handleChange('panel7')}
      >
        <AccordionSummary>
          How do I upgrade to a premium subscription?
        </AccordionSummary>
        <AccordionDetails>
          To upgrade, you can select the desired subscription plan on the
          website and choose from a variety of payment options.
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === 'panel8'}
        onChange={handleChange('panel8')}
      >
        <AccordionSummary>
          What are the benefits of a premium subscription?
        </AccordionSummary>
        <AccordionDetails>
          As a premium user, you can upload your own Counter-Strike game demos
          and have access to advanced filtering options as well as strategic
          insights.
        </AccordionDetails>
      </Accordion>
      <Box mt={5} mb={1}>
        <Typography variant="h5">Account Management</Typography>
      </Box>
      <Accordion
        expanded={expanded === 'panel9'}
        onChange={handleChange('panel9')}
      >
        <AccordionSummary>
          Can I change my username associated with my account?
        </AccordionSummary>
        <AccordionDetails>
          Yes, you can change your username in the &quot;General
          Information&quot; section within our user profile.
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === 'panel10'}
        onChange={handleChange('panel10')}
      >
        <AccordionSummary>How can I delete my account?</AccordionSummary>
        <AccordionDetails>
          You can delete your account by accessing the profile settings and
          initiating the deletion process. Your data will be safely removed from
          the database, and all related entries will be anonymized or deleted.
        </AccordionDetails>
      </Accordion>
      <Box mt={5} mb={1}>
        <Typography variant="h5">Technical Support</Typography>
      </Box>
      <Accordion
        expanded={expanded === 'panel11'}
        onChange={handleChange('panel11')}
      >
        <AccordionSummary>
          What should I do if I encounter a technical issue?
        </AccordionSummary>
        <AccordionDetails>
          If you encounter any technical issues, please contact our customer
          support through the help section on the website or email us directly
          at <Link href="mailto:support@defuze.gg">support@defuze.gg</Link>. Our
          team will assist you in resolving the issue promptly.
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === 'panel12'}
        onChange={handleChange('panel12')}
      >
        <AccordionSummary>How is my data protected?</AccordionSummary>
        <AccordionDetails>
          We prioritize user data privacy and security. All data transactions
          are encrypted, and user information is stored securely. We adhere to
          strict data protection policies to ensure your information is safe.
        </AccordionDetails>
      </Accordion>
      <Box mt={10} />
    </Container>
  );
}

export default Help;
