import {
  Paper,
  IconButton,
  Button,
  Grid,
  Slider,
  Typography,
  Box,
  Tooltip,
} from '@mui/material';
import {
  PlayArrow,
  SkipNext,
  SkipPrevious,
  Pause,
  SyncAlt,
  ArrowRight,
  TextSnippet,
} from '@mui/icons-material';
import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import { useTheme } from '@mui/material/styles';
import NotesDrawer from './NotesDrawer.jsx';
import { useMatchData } from '../../utils/MatchDataContext.js';
import { ReactComponent as C4Icon } from './assets/weapon_c4.svg';
import { ReactComponent as Skull } from './assets/skull.svg';
import { mapNameToIcon } from './utils/mapNameToIcon.js';

/* PlayerControl Component (used to control demoplayer)
- Fetch ticks from backend and pass them to demoplayer, and teaminfo
- Receive from replay component map and round information and fetch ticks from backend accordingly to these two params
- Include tick slider to select tick
- Pass button functionality to DemoPlayer component (play/pause, forward, backward, speed control, tick selection slider)
*/

const TICKS_PER_SECOND = 16;

// helper hook to efficiently and correctly update playback state
function useEfficientPlayback(
  isPlaying,
  speed,
  totalTicks,
  setCurrentTickIndex,
) {
  const requestRef = useRef();
  const previousTimeRef = useRef();
  const accumulatedTimeRef = useRef(0);

  const animate = useCallback(
    (time) => {
      if (previousTimeRef.current !== undefined) {
        const deltaTime = time - previousTimeRef.current;
        accumulatedTimeRef.current += deltaTime * speed;

        const tickDuration = 1000 / TICKS_PER_SECOND;
        const ticksToAdvance = Math.floor(
          accumulatedTimeRef.current / tickDuration,
        );

        if (ticksToAdvance > 0) {
          setCurrentTickIndex((prevIndex) => {
            const nextIndex = prevIndex + ticksToAdvance;
            return nextIndex >= totalTicks ? totalTicks - 1 : nextIndex;
          });
          accumulatedTimeRef.current -= ticksToAdvance * tickDuration;
        }
      }
      previousTimeRef.current = time;
      requestRef.current = requestAnimationFrame(animate);
    },
    [speed, setCurrentTickIndex, totalTicks],
  );

  useEffect(() => {
    if (isPlaying) {
      accumulatedTimeRef.current = 0;
      previousTimeRef.current = undefined;
      requestRef.current = requestAnimationFrame(animate);
    }

    return () => {
      if (requestRef.current) {
        cancelAnimationFrame(requestRef.current);
      }
    };
  }, [isPlaying, animate]);

  // reset accumulated time when playback stops
  useEffect(() => {
    if (!isPlaying) {
      accumulatedTimeRef.current = 0;
    }
  }, [isPlaying]);

  return null;
}

function CustomMark({
  icon,
  side,
  victim,
  attacker,
  planter,
  weapon,
  ...props
}) {
  const theme = useTheme();

  const renderIcon = () => {
    switch (icon) {
      case 'C4Icon':
        return (
          <Tooltip title={planter} placement="top" arrow>
            <C4Icon width={16} height={16} />
          </Tooltip>
        );
      case 'Note':
        return (
          <Tooltip title="Note" placement="top" arrow>
            <TextSnippet
              sx={{
                width: '16px',
                height: '16px',
                color: theme.palette.primary.main,
              }}
            />
          </Tooltip>
        );
      default:
        return (
          <Tooltip
            title={
              <>
                {attacker}{' '}
                {mapNameToIcon(weapon, 16, 16, {
                  position: 'relative',
                  top: '5px',
                })}{' '}
                {victim}
              </>
            }
            placement="top"
            arrow
          >
            <Skull
              style={{
                fill:
                  side === 'CT' ? theme.palette.cs2.ct : theme.palette.cs2.t,
              }}
              width={16}
              height={16}
            />
          </Tooltip>
        );
    }
  };

  return (
    <Box
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...props}
      sx={{
        position: 'absolute',
        width: 20,
        height: 20,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        '&:hover': {
          transform: 'scale(1.2)',
        },
      }}
    >
      {renderIcon()}
    </Box>
  );
}

CustomMark.propTypes = {
  icon: PropTypes.string.isRequired,
  side: PropTypes.string,
  victim: PropTypes.string,
  attacker: PropTypes.string,
  planter: PropTypes.string,
  weapon: PropTypes.string,
};

function DemoControllerBar({
  isNotesDrawerOpen,
  setIsNotesDrawerOpen,
  tutReplay,
}) {
  const [speed, setSpeed] = useState(1);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentRoundSet, setCurrentRoundSet] = useState(0);
  const [sliderWidth, setSliderWidth] = useState(1200);
  const [note, setNote] = useState(null);
  const [marks, setMarks] = useState([]);
  const sliderRef = useRef(null);

  const {
    selectedRounds,
    currentRoundData,
    currentRoundNumber,
    currentTickIndex,
    setCurrentTickIndex,
    setCurrentRoundNumber,
    currentMapData,
    metadata,
  } = useMatchData();
  const otButtonCount = useMemo(
    () => Math.ceil(currentMapData.rounds.length / 24),
    [currentMapData.rounds.length],
  );
  const theme = useTheme();

  // set currentRoundSet to the correct value if first roundNumber in selectedRounds is in OT
  useEffect(() => {
    if (currentRoundNumber > 24) {
      // set ot page, ot pages always consist of 18 rounds
      const otPage = Math.floor((currentRoundNumber - 6) / 18);
      setCurrentRoundSet(otPage);
    }
  }, [currentRoundNumber]);

  // playback hook using requestAnimationFrame
  useEfficientPlayback(
    isPlaying,
    speed,
    currentRoundData.ticks.length,
    setCurrentTickIndex,
  );

  // Update slider width on window resize, using a ref to the slider element
  useEffect(() => {
    const updateSliderWidth = () => {
      if (sliderRef.current) {
        setSliderWidth(sliderRef.current.clientWidth);
      }
    };

    updateSliderWidth();
    window.addEventListener('resize', updateSliderWidth);

    return () => {
      window.removeEventListener('resize', updateSliderWidth);
    };
  }, []);

  const handlePlaySpeedClick = (asc) => {
    if (asc) {
      if (speed === 1) {
        setSpeed(1.5);
      } else if (speed === 1.5) {
        setSpeed(2);
      } else if (speed === 2) {
        setSpeed(2.5);
      } else if (speed === 2.5) {
        setSpeed(3);
      } else if (speed === 3) {
        setSpeed(1);
      }
    } else if (speed === 1) {
      setSpeed(3);
    } else if (speed === 3) {
      setSpeed(2.5);
    } else if (speed === 2.5) {
      setSpeed(2);
    } else if (speed === 2) {
      setSpeed(1.5);
    } else if (speed === 1.5) {
      setSpeed(1);
    }
  };

  // stop playback when user manually changes the slider
  const handleSliderChange = useCallback(
    (event, newValue) => {
      setCurrentTickIndex(newValue);
      if (isPlaying) {
        setIsPlaying(false);
      }
    },
    [setCurrentTickIndex, isPlaying, setIsPlaying],
  );

  const handleSliderIconClick = (tickIndex) => {
    setCurrentTickIndex(tickIndex);
    setIsPlaying(false);
  };

  const handleSkipPreviousClick = () => {
    setIsPlaying(false);
    if (currentTickIndex - TICKS_PER_SECOND >= 0) {
      setCurrentTickIndex(currentTickIndex - TICKS_PER_SECOND);
    } else {
      setCurrentTickIndex(0);
    }
  };

  const handlePlayClick = useCallback(() => {
    setIsPlaying((prevIsPlaying) => !prevIsPlaying);
  }, []);

  const debouncedHandlePlayClick = useCallback(
    debounce(handlePlayClick, 200, { leading: true, trailing: false }),
    [handlePlayClick],
  );

  const handleSkipNextClick = () => {
    setIsPlaying(false);
    if (
      currentTickIndex + TICKS_PER_SECOND * 5 <
      currentRoundData.ticks.length
    ) {
      setCurrentTickIndex(currentTickIndex + TICKS_PER_SECOND * 5);
    } else {
      setCurrentTickIndex(currentRoundData.ticks.length - 1);
    }
  };

  // Listener to keyboard input for playback control
  useEffect(() => {
    if (!isNotesDrawerOpen && !tutReplay) {
      const handleKeyDown = (event) => {
        if (event.key === ' ' || event.key === 'k') {
          if (event.repeat) return;
          event.preventDefault();
          debouncedHandlePlayClick();
        } else if (event.key === 'ArrowRight' || event.key === 'l') {
          handleSkipNextClick();
        } else if (event.key === 'ArrowLeft' || event.key === 'j') {
          handleSkipPreviousClick();
        } else if (event.key === 'ArrowUp' || event.key === 'i') {
          event.preventDefault();
          handlePlaySpeedClick(true);
        } else if (event.key === 'ArrowDown' || event.key === 'm') {
          event.preventDefault();
          handlePlaySpeedClick(false);
        }
      };

      window.addEventListener('keydown', handleKeyDown);

      return () => {
        window.removeEventListener('keydown', handleKeyDown);
      };
    }
    return undefined;
  }, [
    handlePlayClick,
    handleSkipNextClick,
    handleSkipPreviousClick,
    isNotesDrawerOpen,
  ]);

  const handleOtButtonClick = () => {
    setCurrentRoundSet((prevSet) => (prevSet + 1) % otButtonCount);
  };

  useEffect(() => {
    const markings = [];
    if (metadata.playerDeaths) {
      const reversedDeaths = [...metadata.playerDeaths].reverse();
      reversedDeaths.forEach((death) => {
        markings.push({
          value: death.from,
          label: (
            <CustomMark
              icon="Headshot"
              side={death.side}
              victim={death.victim}
              attacker={death.attacker}
              weapon={death.weapon}
              onClick={() => handleSliderIconClick(death.from)}
            />
          ),
        });
      });
    }
    if (metadata.bombPlant) {
      markings.push({
        value: metadata.bombPlant.from,
        label: (
          <CustomMark
            icon="C4Icon"
            planter={metadata.bombPlant.planter}
            onClick={() => handleSliderIconClick(metadata.bombPlant.from)}
          />
        ),
      });
    }
    if (note) {
      Object.keys(note.content).forEach((tickIndex) => {
        markings.push({
          value: parseInt(tickIndex, 10),
          label: (
            <CustomMark
              icon="Note"
              onClick={() => handleSliderIconClick(parseInt(tickIndex, 10))}
            />
          ),
        });
      });
    }
    setMarks(markings);
  }, [note, metadata]);

  // helper function to calculate remaining time of the current round
  const calculateRemainingTime = () => {
    const totalTicks = currentRoundData.ticks.length - 1;
    const totalSeconds = Math.floor(totalTicks / TICKS_PER_SECOND);
    const currentSeconds = Math.floor(currentTickIndex / TICKS_PER_SECOND);

    const remainingSeconds = totalSeconds - currentSeconds;

    const minutes = Math.floor(remainingSeconds / 60);
    const seconds = remainingSeconds % 60;

    return `-${minutes}:${seconds.toString().padStart(2, '0')}`;
  };

  // helper function to calculate button colors/disable state and round number
  const calculateButtons = (i) => {
    const roundNumber = i + 1 + currentRoundSet * 24;
    let roundIndex = i + currentRoundSet * 24;
    const roundFiltered = selectedRounds.some(
      (r) => r === i + 1 + currentRoundSet * 24,
    );
    let firstMissing = 0;
    if (currentMapData.rounds[0].roundNumber === 2) {
      if (i !== 0) {
        roundIndex -= 1;
      } else if (currentRoundSet !== 0) {
        roundIndex -= 1;
      }
      firstMissing = 1;
    }
    let roundExists = false;
    if (roundIndex < currentMapData.rounds.length) {
      roundExists =
        currentMapData.rounds[roundIndex].roundNumber === roundNumber;
    }
    let buttonColor = 'grey';
    let hoverColor = 'grey';
    let disabled = false;
    if (roundExists) {
      if (
        currentMapData.rounds.length !== 0 &&
        currentMapData.rounds.length > roundNumber - 1 - firstMissing
      ) {
        let index = roundNumber - 1;
        if (currentMapData.rounds[0].roundNumber === 2) {
          index = roundNumber - 2;
        }
        const roundWinnerSide = currentMapData.rounds[index].winnerSide;
        if (roundFiltered) {
          if (roundWinnerSide === 'CT') {
            buttonColor = theme.palette.cs2.ct;
            hoverColor = theme.palette.cs2.ctLight;
          } else {
            buttonColor = theme.palette.cs2.t;
            hoverColor = theme.palette.cs2.tLight;
          }
        }
      }
    } else {
      buttonColor = 'rgba(211, 211, 211, 0.5)';
      hoverColor = 'rgba(211, 211, 211, 0.5)';
      disabled = true;
    }
    let winnerSide = '';
    if (
      roundExists &&
      currentMapData.rounds.length > roundNumber - 1 - firstMissing
    ) {
      let index = roundNumber - 1;
      if (currentMapData.rounds[0].roundNumber === 2) {
        index = roundNumber - 2;
      }
      winnerSide = currentMapData.rounds[index].winnerSide;
    }

    return {
      buttonColor,
      hoverColor,
      disabled,
      roundNumber,
      roundExists,
      winnerSide,
    };
  };

  return (
    <Paper sx={{ padding: 1, borderRadius: 0 }}>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item>
          <Tooltip title="Back 1s (left arrow)" arrow placement="top">
            <IconButton size="small" onClick={handleSkipPreviousClick}>
              <SkipPrevious />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item>
          <Tooltip
            title={isPlaying ? 'Pause (space)' : 'Play (space)'}
            arrow
            placement="top"
          >
            <IconButton size="small" onClick={handlePlayClick}>
              {isPlaying ? <Pause /> : <PlayArrow />}
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item>
          <Tooltip title="Forward 5s (right arrow)" arrow placement="top">
            <IconButton size="small" onClick={handleSkipNextClick}>
              <SkipNext />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item xs ref={sliderRef}>
          <Slider
            sx={{
              '& .MuiSlider-thumb': {
                transition: 'none',
                width: 16,
                height: 16,
                backgroundColor: '#fff',
                border: '2px solid currentColor',
                '&:hover, &.Mui-focusVisible': {
                  boxShadow: 'none',
                },
                '&:before': {
                  display: 'none',
                },
              },
              '& .MuiSlider-track': {
                transition: 'none',
              },
              '& .MuiSlider-rail': {
                transition: 'none',
              },
              '& .MuiSlider-mark': {
                height: 20,
                width: 20,
                borderRadius: '50%',
                backgroundColor: 'transparent',
              },
              '& .MuiSlider-markLabel': {
                height: 20,
                width: 20,
                borderRadius: '50%',
                backgroundColor: 'transparent',
                position: 'absolute',
                transform: 'translateX(-50%) translateY(-50%)', // Center marks below their values
              },
              ml: 1,
              position: 'relative',
              top: '12.5px',
              padding: '5px 0', // Reduce clickable area
            }}
            defaultValue={0}
            min={0}
            max={currentRoundData.ticks.length - 1}
            valueLabelDisplay="auto"
            onChange={handleSliderChange}
            value={currentTickIndex}
            timeout={0}
            valueLabelFormat={(value) => {
              // Custom format function
              const seconds = Math.floor(value / TICKS_PER_SECOND);
              const minutes = Math.floor(seconds / 60);
              const remainingSeconds = seconds % 60;
              return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
            }}
            getAriaValueText={(value) => `${value}`}
            step={1}
            marks={marks}
          />
        </Grid>
        <Grid item>
          <Typography variant="subtitle2" sx={{ ml: 3, mr: 1.5 }}>
            {calculateRemainingTime()}
          </Typography>
        </Grid>
        <Grid item sx={{ mr: 1 }}>
          <NotesDrawer
            setIsNotesDrawerOpen={setIsNotesDrawerOpen}
            note={note}
            setNote={setNote}
          />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ padding: 1, mt: 1.5 }}>
        <Tooltip title="Playback speed (up/down arrow)" arrow>
          <Button
            onClick={() => handlePlaySpeedClick(true)}
            sx={{
              width: '60px',
              height: '30px',
              minWidth: '0px',
              padding: '0',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              ml: 1.5,
            }}
          >
            {speed}x
          </Button>
        </Tooltip>
        <Box
          sx={{
            width: `${sliderWidth}px`,
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            ml: 3.75,
          }}
        >
          {currentRoundSet === 0 ? (
            <>
              {Array.from({ length: 24 }, (_, i) => {
                const {
                  buttonColor,
                  hoverColor,
                  disabled,
                  roundNumber,
                  roundExists,
                  winnerSide,
                } = calculateButtons(i);
                return (
                  <React.Fragment key={roundNumber}>
                    <Tooltip
                      title={
                        roundExists
                          ? `Round ${roundNumber} - ${winnerSide} win`
                          : 'Round does not exist'
                      }
                      arrow
                      placement="top"
                    >
                      <span>
                        <Button
                          key={roundNumber}
                          size="small"
                          variant="contained"
                          onClick={() => {
                            if (roundExists) {
                              setCurrentRoundNumber(roundNumber);
                              setIsPlaying(false);
                            }
                          }}
                          disabled={disabled}
                          sx={{
                            width: '30px',
                            height: '30px',
                            minWidth: '30px',
                            padding: 0,
                            backgroundColor: buttonColor,
                            fontWeight: 'bold',
                            color: 'textColor',
                            position: 'relative',
                            border:
                              currentRoundNumber === roundNumber
                                ? '2px solid black'
                                : 'none',
                            borderRadius: '2px',
                            display: 'flex',
                            transition: 'background-color 0.3s ease',
                            '&:hover': {
                              backgroundColor: hoverColor,
                            },
                          }}
                        >
                          {roundNumber}
                        </Button>
                      </span>
                    </Tooltip>
                    {i === 11 && (
                      <Tooltip title="Change of sides" arrow>
                        <SyncAlt
                          sx={{
                            width: '24px',
                            height: '24px',
                            color: 'action.active',
                            margin: '0 4px',
                          }}
                        />
                      </Tooltip>
                    )}
                  </React.Fragment>
                );
              })}
            </>
          ) : (
            <>
              {Array.from({ length: 18 }, (_, i) => {
                const {
                  buttonColor,
                  hoverColor,
                  disabled,
                  roundNumber,
                  roundExists,
                } = calculateButtons(i);
                return (
                  <React.Fragment key={roundNumber}>
                    <Button
                      key={roundNumber}
                      size="small"
                      variant="contained"
                      onClick={() => {
                        if (roundExists) {
                          setCurrentRoundNumber(roundNumber);
                          setIsPlaying(false);
                        }
                      }}
                      disabled={disabled}
                      sx={{
                        width: '30px',
                        height: '30px',
                        minWidth: '30px',
                        padding: 0,
                        backgroundColor: buttonColor,
                        fontWeight: 'bold',
                        color: 'textColor',
                        position: 'relative',
                        border:
                          currentRoundNumber === roundNumber
                            ? '2px solid black'
                            : 'none',
                        borderRadius: '2px',
                        display: 'flex',
                        transition: 'background-color 0.3s ease',
                        '&:hover': {
                          backgroundColor: hoverColor,
                        },
                      }}
                    >
                      {roundNumber}
                    </Button>
                    {i % 3 === 2 && i % 18 !== 17 && (
                      <Tooltip title="Change of sides" arrow>
                        <SyncAlt
                          sx={{
                            width: '24px',
                            height: '24px',
                            color: 'action.active',
                            margin: '0 4px',
                          }}
                        />
                      </Tooltip>
                    )}
                  </React.Fragment>
                );
              })}
            </>
          )}
        </Box>
        <Box sx={{ ml: 8.5 }}>
          {/* eslint-disable-next-line no-nested-ternary */}
          {otButtonCount === 1 ? (
            <Button disabled sx={{ width: '80px', ml: 1, display: 'none' }}>
              No OT
            </Button>
          ) : currentRoundSet === 0 ? (
            <Tooltip title="Overtime" arrow>
              <Button
                onClick={handleOtButtonClick}
                sx={{ width: '80px', ml: 1 }}
                endIcon={<ArrowRight />}
              >
                OT
              </Button>
            </Tooltip>
          ) : (
            <Tooltip title="Overtime" arrow>
              <Button
                onClick={handleOtButtonClick}
                sx={{ width: '80px', ml: 1 }}
              >
                OT {currentRoundSet}/{otButtonCount - 1}
              </Button>
            </Tooltip>
          )}
        </Box>
      </Grid>
    </Paper>
  );
}

DemoControllerBar.propTypes = {
  isNotesDrawerOpen: PropTypes.bool.isRequired,
  setIsNotesDrawerOpen: PropTypes.func.isRequired,
  tutReplay: PropTypes.bool.isRequired,
};

export default DemoControllerBar;
