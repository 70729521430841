import {
  Delete as EraseIcon,
  PhotoCamera as ScreenshotIcon,
} from '@mui/icons-material';
import {
  Box,
  ClickAwayListener,
  IconButton,
  Paper,
  Slider,
  Tooltip,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

const presetColors = [
  '#FF0000', // Red
  '#00FF00', // Green
  '#0000FF', // Blue
  '#FFFF00', // Yellow
  '#FF00FF', // Magenta
  '#00FFFF', // Cyan
  '#FFFFFF', // White
  '#000000', // Black
];

function ContextMenu({
  drawingColor,
  setDrawingColor,
  strokeWidth,
  setStrokeWidth,
  onDelete,
  onClose,
  onScreenshot,
}) {
  return (
    <ClickAwayListener onClickAway={onClose}>
      <Paper
        sx={{
          position: 'absolute',
          top: 50,
          right: 10,
          padding: 2,
          width: 315, // Reduced width
        }}
      >
        <Box mb={1} display="flex" justifyContent="space-between">
          <Tooltip title="Take screenshot (ctrl+s/print)" arrow>
            <IconButton onClick={onScreenshot}>
              <ScreenshotIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Erase drawings (del)" arrow>
            <IconButton onClick={onDelete}>
              <EraseIcon />
            </IconButton>
          </Tooltip>
        </Box>
        <Box mb={2}>
          <Typography variant="subtitle2" gutterBottom>
            Color
          </Typography>
          <Box display="flex" flexWrap="wrap" gap={1}>
            {presetColors.map((color) => (
              <Box
                key={color}
                onClick={() => setDrawingColor(color)}
                sx={{
                  width: 28,
                  height: 28,
                  backgroundColor: color,
                  border:
                    color === drawingColor
                      ? '2px solid #666'
                      : '1px solid #ccc',
                  cursor: 'pointer',
                  '&:hover': {
                    opacity: 0.8,
                  },
                }}
              />
            ))}
          </Box>
        </Box>

        <Box>
          <Typography variant="subtitle2" gutterBottom>
            Stroke width
          </Typography>
          <Box px={1}>
            {/* Added padding to contain the slider */}
            <Slider
              value={strokeWidth}
              onChange={(_, newValue) => setStrokeWidth(newValue)}
              aria-labelledby="stroke-width-slider"
              step={1}
              marks
              min={1}
              max={8}
              valueLabelDisplay="auto"
              sx={{
                '& .MuiSlider-thumb': {
                  transition: 'none',
                  width: 16,
                  height: 16,
                  backgroundColor: '#fff',
                  border: '2px solid currentColor',
                },
                width: '100%', // Ensure the slider takes full width of its container
              }}
            />
          </Box>
        </Box>
      </Paper>
    </ClickAwayListener>
  );
}

ContextMenu.propTypes = {
  drawingColor: PropTypes.string.isRequired,
  setDrawingColor: PropTypes.func.isRequired,
  strokeWidth: PropTypes.number.isRequired,
  setStrokeWidth: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onScreenshot: PropTypes.func.isRequired,
};

export default ContextMenu;
