import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Container, Grid, Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import NotificationSnackbar from './common/NotificationSnackbar';
import EmailVerificationSnackbar from './common/EmailVerificationSnackbar';
import RecentMatches from './dashboard/RecentMatches';
import MatchPerformance from './dashboard/MatchPerformance';
import MatchInsights from './dashboard/MatchInsights';
import axiosInstance from '../axiosInstance.js';
import PlayerStats from './dashboard/PlayerStats.jsx';
import { useAuth } from '../utils/AuthContext.js';
import CustomTooltip from './CustomTooltip.jsx';
import { useRefs } from '../utils/RefContext.js';

const dummyTeam = {
  name: 'Dummy',
  aliases: [],
  players: {
    '76561198246607476': 'Player 1',
    '76561198050250233': 'Player 2',
    '76561198013243326': 'Player 3',
    '76561199063068840': 'Player 4',
    '76561198176878303': 'Player 5',
  },
  maps: [],
  mapStats: [],
  analytics: null,
};
const dummyAnalytics = {
  countOfAddedMatches: {
    de_nuke: [1],
    de_mirage: [1],
    de_dust2: [1],
    de_inferno: [1],
    de_overpass: [1],
    de_vertigo: [1],
  },
  wins: {
    de_nuke: [1],
    de_mirage: [1],
    de_dust2: [1],
  },
  losses: {
    de_inferno: [1],
    de_overpass: [1],
    de_vertigo: [1],
  },
  kills: {
    de_nuke: [50],
    de_mirage: [60],
    de_dust2: [65],
    de_inferno: [45],
    de_overpass: [49],
    de_vertigo: [52],
  },
  deaths: {
    de_nuke: [45],
    de_mirage: [50],
    de_dust2: [60],
    de_inferno: [50],
    de_overpass: [54],
    de_vertigo: [56],
  },
  adr: {
    de_nuke: [80],
    de_mirage: [79],
    de_dust2: [85],
    de_inferno: [60],
    de_overpass: [65],
    de_vertigo: [70.5],
  },
  insights: {
    general: [
      {
        text: "🏆 Dominating the scoreboard! Your team's 100.00% win rate across all maps is impressive. You're not just winning; you're crushing it! Keep this momentum going and aim for even greater heights! 💪🚀",
        importance: 1,
      },
      {
        text: "🔫 Pistol round masters! With a 100.00% win rate, you're starting strong across all maps. Your early game is on point - keep setting the tone for success! 🎖️🌟",
        importance: 1,
      },
      {
        text: "👊 Solid damage output! Your team's average ADR of 74.75 is commendable. You're making your presence felt, but imagine the impact if you push it even higher! 😍💪",
        importance: 1,
      },
      {
        text: "🏹 Sharpshooters alert! Your team's K/D ratio of 1.19 is stellar. You're not just surviving; you're thriving in every engagement. Keep hunting those frags! 🌟🔫",
        importance: 1,
      },
      {
        text: "💣 Utility virtuosos! Your team's average utility damage of 105.20 per player is off the charts. You're not just using nades; you're painting the map with them! Explosive work! 🔥🎨",
        importance: 1,
      },
    ],
  },
  deathsPerPlayer: {
    '76561198246607476': 9,
    '76561198050250233': 11,
    '76561198013243326': 8,
    '76561199063068840': 9,
    '76561198176878303': 9,
  },
  killsPerPlayer: {
    '76561198246607476': 15,
    '76561198050250233': 18,
    '76561198013243326': 8,
    '76561199063068840': 17,
    '76561198176878303': 8,
  },
  adrPerPlayer: {
    '76561198246607476': 76.1875,
    '76561198050250233': 136.4375,
    '76561198013243326': 70.3125,
    '76561199063068840': 102.4375,
    '76561198176878303': 62.1875,
  },
  headshotsPerPlayer: {
    '76561198246607476': 11,
    '76561198050250233': 12,
    '76561198013243326': 1,
    '76561199063068840': 10,
    '76561198176878303': 6,
  },
  flashAssistsPerPlayer: {
    '76561198246607476': 3,
    all: 20,
    '76561198050250233': 9,
    '76561198013243326': 1,
    '76561199063068840': 5,
    '76561198176878303': 2,
  },
  utilityDamagePerPlayer: {
    '76561198246607476': 16,
    '76561198050250233': 31,
    '76561198013243326': 262,
    '76561199063068840': 54,
    '76561198176878303': 10,
  },
};

const tooltipConfigs = [
  {
    component: 'RecentMatches',
    content: (
      <Box>
        <Typography variant="subtitle2">
          See recently uploaded matches here and jump right into the replay by
          clicking &quot;Play&quot;
        </Typography>
      </Box>
    ),
    placement: 'left',
    anchorRef: null,
  },
  {
    component: 'Dashboard',
    content: (
      <Box>
        <Typography variant="subtitle2">
          For a selected team, view detailed player stats, team performance, and
          engaging insights
        </Typography>
      </Box>
    ),
    placement: 'right',
    anchorRef: null,
  },
  {
    component: 'Matches',
    content: (
      <Box>
        <Typography variant="subtitle2">
          Filter and select matches and rounds of interest
        </Typography>
      </Box>
    ),
    placement: 'right',
    anchorRef: null,
  },
  {
    component: 'Replay',
    content: (
      <Box>
        <Typography variant="subtitle2">
          Review and analyze previously selected matches or filtered rounds
        </Typography>
      </Box>
    ),
    placement: 'right',
    anchorRef: null,
  },
  {
    component: 'PlayerStats',
    content: (
      <Box>
        <Typography variant="subtitle2">
          Check out your selected team&apos;s player average stats and
          performance trend here
        </Typography>
        <Typography variant="body2" gutterBottom sx={{ mt: 1 }}>
          Color Coding:
        </Typography>
        <Typography variant="body2">🟢 Green: Great performance</Typography>
        <Typography variant="body2">🟠 Orange: Average performance</Typography>
        <Typography variant="body2" mb={1}>
          🔴 Red: Subpar performance
        </Typography>
        <Typography variant="body2" gutterBottom>
          Trend Arrows:
        </Typography>
        <Typography variant="body2">↑ Upward trend (improving)</Typography>
        <Typography variant="body2">→ Stable trend</Typography>
        <Typography variant="body2">↓ Downward trend (declining)</Typography>
      </Box>
    ),
    placement: 'left',
    anchorRef: null,
  },
  {
    component: 'MatchInsights',
    content: (
      <Box>
        <Typography variant="subtitle2">
          Gain valuable insights for your team&apos;s matches. You can view
          general insights and insights for each map. Only critical insights are
          displayed by default, but you can also choose to view all insights
        </Typography>
      </Box>
    ),
    placement: 'left',
    anchorRef: null,
  },
  {
    component: 'MatchPerformance',
    content: (
      <Box>
        <Typography variant="subtitle2">
          Get a fast overview and track team performance across different maps
          and matches here
        </Typography>
      </Box>
    ),
    placement: 'right',
    anchorRef: null,
  },
];

function Dashboard() {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const [verifiedSuccess, setVerifiedSuccess] = useState(
    params.get('verified_success') === 'true',
  );
  const [matches, setMatches] = useState([]);
  const [analytics, setAnalytics] = useState(null);
  const [lastMapStats, setLastMapStats] = useState(null);
  const [isLoadingMatches, setIsLoadingMatches] = useState(true);
  const [isLoadingAnalytics, setIsLoadingAnalytics] = useState(true);
  const [team, setTeam] = useState(null);
  const [isTeamLoading, setIsTeamLoading] = useState(true);
  const [isBlurred, setIsBlurred] = useState(false);
  const { user } = useAuth();

  const [tutDashboard, setTutDashboard] = useState(
    localStorage.getItem('tutDashboard') === 'true' ||
      localStorage.getItem('tutDashboardSub') === 'true' ||
      false,
  );
  const [currentTooltipIndex, setCurrentTooltipIndex] = useState(
    localStorage.getItem('tutDashboardSub') === 'true' ? 4 : 0,
  );
  const recentAnchorRef = useRef(null);
  const sidebarDashboardAnchorRef = useRef(null);
  const sidebarMatchesAnchorRef = useRef(null);
  const sidebarReplayAnchorRef = useRef(null);
  const playerStatsAnchorRef = useRef(null);
  const matchInsightsAnchorRef = useRef(null);
  const matchPerformanceAnchorRef = useRef(null);

  const { getRef } = useRefs();
  const refDashboard = getRef('barDashboard');
  const refMatches = getRef('barMatches');
  const refReplay = getRef('barReplay');
  const theme = useTheme();

  useEffect(() => {
    if (refDashboard) {
      sidebarDashboardAnchorRef.current = refDashboard;
    }
    if (refMatches) {
      sidebarMatchesAnchorRef.current = refMatches;
    }
    if (refReplay) {
      sidebarReplayAnchorRef.current = refReplay;
    }
  }, [refMatches, refDashboard, refReplay]);

  useEffect(() => {
    tooltipConfigs[0].anchorRef = recentAnchorRef;
    tooltipConfigs[1].anchorRef = sidebarDashboardAnchorRef;
    tooltipConfigs[2].anchorRef = sidebarMatchesAnchorRef;
    tooltipConfigs[3].anchorRef = sidebarReplayAnchorRef;
    tooltipConfigs[4].anchorRef = playerStatsAnchorRef;
    tooltipConfigs[5].anchorRef = matchInsightsAnchorRef;
    tooltipConfigs[6].anchorRef = matchPerformanceAnchorRef;
  }, [
    recentAnchorRef,
    sidebarDashboardAnchorRef,
    sidebarMatchesAnchorRef,
    sidebarReplayAnchorRef,
    playerStatsAnchorRef,
    matchInsightsAnchorRef,
    matchPerformanceAnchorRef,
  ]);

  // fetch team info
  useEffect(() => {
    const fetchTeamData = async () => {
      try {
        if (user) {
          if (user.preferredTeam === undefined) {
            setTeam(null);
            return;
          }
          // Fetch team info
          axiosInstance
            .get(`/api/teams/${user.preferredTeam}`)
            .then((response) => {
              if (response.status === 200) {
                setTeam(response.data);
              }
            })
            .catch((error) => {
              if (
                error.response.status === 400 ||
                error.response.status === 404
              ) {
                if (user.verified && user.subscription !== 0) {
                  setTeam(null);
                } else {
                  setTeam(dummyTeam);
                }
              }
            });
        }
      } catch (error) {
        console.debug('Failed to fetch team info');
      }
    };

    fetchTeamData().then(() => {
      setIsTeamLoading(false);
    });
  }, [user]);

  // fetch recent matches and analytics
  useEffect(() => {
    axiosInstance
      .get('/api/maps/recent-matches')
      .then((response) => {
        if (response.status === 200) {
          setMatches(response.data);
          console.debug('Matches fetched successfully');
        }
      })
      .catch(() => {
        console.error('Failed to fetch matches');
      })
      .finally(() => {
        setIsLoadingMatches(false);
      });

    axiosInstance
      .get('/api/analytics')
      .then((response) => {
        if (response.status === 200) {
          const tmp = response.data;
          tmp.insights = Object.fromEntries(
            Object.entries(tmp.insights).map(([key, insights]) => [
              key,
              insights.sort((a, b) => b.importance - a.importance),
            ]),
          );
          setAnalytics(tmp);
          console.debug('Analytics fetched successfully');
        }
      })
      .catch((error) => {
        if (error.response.status === 404) {
          console.debug('No analytics found');
        }
        console.error('Failed to fetch analytics');
      })
      .finally(() => {
        setIsLoadingAnalytics(false);
      });
  }, [user]);

  // fetch last map stats
  useEffect(() => {
    if (matches.length === 0 || !matches || !analytics) {
      return;
    }
    const totalMatches = Object.values(analytics.countOfAddedMatches).reduce(
      (acc, curr) => Number(acc) + Number(curr),
      0,
    );
    let recentMapIds = matches.map((match) => match._id);
    if (totalMatches < 5) {
      recentMapIds = recentMapIds.slice(0, totalMatches - 1);
    }
    axiosInstance
      .get(`/api/analytics/recent-match-stats/`, {
        params: {
          matchIds: recentMapIds,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setLastMapStats(response.data);
          console.debug('Last map stats fetched successfully');
        }
      })
      .catch(() => {
        console.error('Failed to fetch last map stats');
      });
  }, [matches, analytics]);

  // check user status
  useEffect(() => {
    if (!user) {
      return;
    }
    if (!user.verified || user.subscription === 0) {
      setIsBlurred(true);
    } else {
      setIsBlurred(false);
    }
  }, [user]);

  // set dummy team if no team is selected and isBlurred
  useEffect(() => {
    if (isBlurred) {
      console.log('setting dummy team');
      setTeam(dummyTeam);
    }
  }, [team, isBlurred]);

  // set dummy analytics if no analytics are found
  useEffect(() => {
    if (isBlurred) {
      console.log('setting dummy analytics');
      setAnalytics(dummyAnalytics);
    }
  }, [analytics, isBlurred]);

  const handleClose = () => {
    setVerifiedSuccess(false);
  };

  return (
    <Container>
      <EmailVerificationSnackbar />
      <NotificationSnackbar
        open={verifiedSuccess}
        message="Email verification successful!"
        severity="success"
        onClose={handleClose}
      />
      {tooltipConfigs.map((config, index) => {
        let maxIndex = tooltipConfigs.length - 1;
        let offset = 0;
        if (isBlurred) {
          maxIndex = 3;
        }
        if (localStorage.getItem('tutDashboardSub') === 'true') {
          offset = 4;
        }
        if (isBlurred && (index === 4 || index === 5 || index === 6)) {
          return null;
        }
        return (
          <CustomTooltip
            /* eslint-disable-next-line react/no-array-index-key */
            key={index}
            open={tutDashboard && currentTooltipIndex === index}
            content={config.content}
            currentTooltipIndex={currentTooltipIndex}
            setCurrentTooltipIndex={setCurrentTooltipIndex}
            setTut={setTutDashboard}
            placement={config.placement}
            anchorRef={config.anchorRef}
            maxIndex={maxIndex}
            sessionItem="tutDashboard"
            offset={offset}
          />
        );
      })}
      <Grid container spacing={2} sx={{ marginTop: 1 }}>
        <Grid item xs={12} sm={12} ref={recentAnchorRef}>
          <Box
            sx={{
              borderRadius: '16px',
              outline:
                currentTooltipIndex === 0 && tutDashboard
                  ? `2px solid ${theme.palette.secondary.main}`
                  : 'none',
            }}
          >
            <RecentMatches
              matches={matches}
              isLoadingMatches={isLoadingMatches}
              team={team}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} ref={playerStatsAnchorRef}>
          <Box
            sx={{
              borderRadius: '16px',
              outline:
                currentTooltipIndex === 4 && tutDashboard
                  ? `2px solid ${theme.palette.secondary.main}`
                  : 'none',
            }}
          >
            <PlayerStats
              analytics={analytics}
              isLoadingAnalytics={isLoadingAnalytics}
              team={team}
              isTeamLoading={isTeamLoading}
              lastMapStats={lastMapStats}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} ref={matchInsightsAnchorRef}>
          <Box
            sx={{
              borderRadius: '16px',
              outline:
                currentTooltipIndex === 5 && tutDashboard
                  ? `2px solid ${theme.palette.secondary.main}`
                  : 'none',
            }}
          >
            <MatchInsights
              analytics={analytics}
              isLoadingAnalytics={isLoadingAnalytics}
              team={team}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} ref={matchPerformanceAnchorRef}>
          <Box
            sx={{
              borderRadius: '16px',
              outline:
                currentTooltipIndex === 6 && tutDashboard
                  ? `2px solid ${theme.palette.secondary.main}`
                  : 'none',
            }}
          >
            <MatchPerformance
              analytics={analytics}
              isLoadingAnalytics={isLoadingAnalytics}
              team={team}
            />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Dashboard;
