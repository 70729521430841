/* eslint-disable react/no-array-index-key */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import { Circle, Image, Layer, Line, Stage, Text } from 'react-konva';
import useImage from 'use-image';
import SettingsIcon from '@mui/icons-material/Settings';
import IconButton from '@mui/material/IconButton';
import { useMatchData } from '../../utils/MatchDataContext.js';
import ancientImage from './assets/de_ancient.png';
import dust2Image from './assets/de_dust2.png';
import mirageImage from './assets/de_mirage.png';
import infernoImage from './assets/de_inferno.png';
import anubisImage from './assets/de_anubis.png';
import vertigoImage from './assets/de_vertigo.png';
import nukeImage from './assets/de_nuke.png';
import bombImage from './assets/weapon_c4.svg';
import ContextMenu from './ContextMenu.jsx';
import Legend from './Legend.jsx';
import { useRefs } from '../../utils/RefContext.js';

// Constants
const BASE_WIDTH = 600;
const MAP_CONFIGS = {
  de_ancient: {
    image: ancientImage,
    offsetX: 2600,
    offsetY: 1850,
    scaleX: 4420,
    scaleY: 4420,
  },
  de_dust2: {
    image: dust2Image,
    offsetX: 2430,
    offsetY: 3200,
    scaleX: 4420,
    scaleY: 4420,
  },
  de_mirage: {
    image: mirageImage,
    offsetX: 2700,
    offsetY: 1250,
    scaleX: 4200,
    scaleY: 4200,
  },
  de_inferno: {
    image: infernoImage,
    offsetX: 1860,
    offsetY: 3720,
    scaleX: 4620,
    scaleY: 4620,
  },
  de_anubis: {
    image: anubisImage,
    offsetX: 2800,
    offsetY: 3300,
    scaleX: 5330,
    scaleY: 5330,
  },
  de_vertigo: {
    image: vertigoImage,
    offsetX: 2850,
    offsetY: 1300,
    scaleX: 3100,
    scaleY: 3100,
  },
  de_nuke: {
    image: nukeImage,
    offsetX: 2400,
    offsetY: 1650,
    scaleX: 5200,
    scaleY: 5200,
  },
};

const calculateLineEnd = (x, y, direction, length) => {
  const degrees = ((direction % 360) + 360) % 360;
  const angle = degrees * (Math.PI / 180);
  return {
    endX: x + length * Math.cos(angle),
    endY: y - length * Math.sin(angle),
  };
};

function DemoPlayer({ width, height, isNotesDrawerOpen }) {
  const [bombImg] = useImage(bombImage);
  const theme = useTheme();
  const stageRef = useRef(null);

  const {
    currentTickIndex,
    currentRoundData,
    currentMapData,
    players,
    metadata,
    addDrawing,
    getDrawingsForTick,
    deleteDrawing,
    selectedPlayers,
  } = useMatchData();

  const mapName = currentMapData
    ? currentMapData.mapName.toLowerCase()
    : 'de_ancient';
  const mapConfig = MAP_CONFIGS[mapName] || MAP_CONFIGS.de_ancient;

  const [mapImage] = useImage(mapConfig.image);

  const [isDrawing, setIsDrawing] = useState(false);
  const [currentLine, setCurrentLine] = useState([]);
  const [drawingColor, setDrawingColor] = useState('#df4b26');
  const [strokeWidth, setStrokeWidth] = useState(2);
  const [isContextMenuOpen, setIsContextMenuOpen] = useState(false);

  const scale = useMemo(() => width / BASE_WIDTH, [width]);

  const refContextMenu = useRef(null);
  const { setRef } = useRefs();

  // Set the ref for the context menu, timeout needed to wait for the ref to be set
  useEffect(() => {
    const timer = setTimeout(() => {
      setRef('contextMenuReplay', refContextMenu.current);
    }, 2000);

    return () => clearTimeout(timer);
  }, [refContextMenu]);

  const mapPositionX = useCallback(
    (pos) => (pos + mapConfig.offsetX) * (width / mapConfig.scaleX),
    [width, mapConfig],
  );

  const mapPositionY = useCallback(
    (pos) => (-pos + mapConfig.offsetY) * (height / mapConfig.scaleY),
    [height, mapConfig],
  );

  const getWeaponProperties = useMemo(
    () => (weaponName) => {
      const lowerWeaponName = weaponName ? weaponName.toLowerCase() : '';
      const properties = {
        length: 11 * scale,
        width: 2 * scale,
        lineCap: 'butt',
        type: 'line',
        nadeColor: null,
      };

      if (
        ['knife', 'c4', 'grenade', 'flashbang', 'molotov'].some((w) =>
          lowerWeaponName.includes(w),
        )
      ) {
        properties.length -= 6 * scale;
        properties.width += 1 * scale;
        properties.lineCap = 'round';

        const nadeColors = {
          'incendiary grenade': 'orange',
          molotov: 'orange',
          'high explosive grenade': 'red',
          flashbang: 'yellow',
          'smoke grenade': 'lightgray',
        };
        properties.nadeColor = nadeColors[lowerWeaponName];
      } else if (lowerWeaponName === 'awp' || lowerWeaponName === 'ssg 08') {
        properties.length += 3 * scale;
      }

      return properties;
    },
    [scale],
  );

  const handleScreenshot = useCallback(() => {
    if (stageRef.current) {
      const uri = stageRef.current.toDataURL();
      const link = document.createElement('a');
      const sanitizeName = (name) =>
        name
          .toLowerCase()
          .replace(/\s+/g, '_')
          .replace(/[^a-zA-Z0-9_-]/g, '');
      // Sanitize team names
      const winnerName = sanitizeName(currentMapData.winnerName);
      const loserName = sanitizeName(currentMapData.loserName);
      link.download = `defuze_gg_${winnerName}_${loserName}_${currentMapData.mapName}_round${currentRoundData.roundNumber}.png`;
      link.href = uri;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }, []);

  const handleMouseDown = useCallback((e) => {
    setIsDrawing(true);
    const pos = e.target.getStage().getPointerPosition();
    setCurrentLine([pos.x, pos.y]);
  }, []);

  const handleMouseMove = useCallback(
    (e) => {
      if (!isDrawing) return;
      const stage = e.target.getStage();
      const point = stage.getPointerPosition();
      setCurrentLine((prevLine) => [...prevLine, point.x, point.y]);
    },
    [isDrawing],
  );

  const handleMouseUp = useCallback(() => {
    setIsDrawing(false);
    if (currentLine.length > 2) {
      addDrawing(currentTickIndex, {
        points: currentLine.map((point) => point / scale), // Normalize to scale 1
        color: drawingColor,
        strokeWidth: strokeWidth / scale, // Normalize strokeWidth too
      });
    }
    setCurrentLine([]);
  }, [
    addDrawing,
    currentTickIndex,
    currentLine,
    drawingColor,
    strokeWidth,
    scale,
  ]);

  const handleDelete = useCallback(() => {
    deleteDrawing(currentTickIndex);
  }, [deleteDrawing, currentTickIndex]);

  useEffect(() => {
    if (!isNotesDrawerOpen) {
      const handleKeyDown = (e) => {
        if (e.key === 'Delete') {
          handleDelete();
        } else if ((e.ctrlKey && e.key === 's') || e.key === 'PrintScreen') {
          e.preventDefault();
          handleScreenshot();
        }
      };

      window.addEventListener('keydown', handleKeyDown);

      return () => {
        window.removeEventListener('keydown', handleKeyDown);
      };
    }
    return undefined;
  }, [handleDelete, handleScreenshot, isNotesDrawerOpen]);

  const renderSmokeGrenades = useCallback(() => {
    if (!metadata.smokeGrenades) return [];

    return metadata.smokeGrenades.map((smoke) => {
      if (currentTickIndex < smoke.from || currentTickIndex > smoke.to)
        return null;

      const x = mapPositionX(smoke.x);
      const y = mapPositionY(smoke.y);
      const radius = 20 * scale;

      let color;
      if (smoke.side === 'CT') {
        color = theme.palette.cs2.smoke.ct;
      } else if (smoke.side === 'T') {
        color = theme.palette.cs2.smoke.t;
      } else {
        color = theme.palette.cs2.smoke.base;
      }

      return (
        <Circle
          key={`smoke-${smoke.from}-${smoke.x}-${smoke.y}`}
          x={x}
          y={y}
          radius={radius}
          fill={color}
          opacity={0.8}
        />
      );
    });
  }, [
    metadata.smokeGrenades,
    currentTickIndex,
    mapPositionX,
    mapPositionY,
    scale,
    theme.palette.cs2.smoke,
  ]);

  const renderMolotovs = useCallback(() => {
    if (!metadata.molotovs) return [];

    return metadata.molotovs.map((molotov) => {
      if (currentTickIndex < molotov.from || currentTickIndex > molotov.to)
        return null;

      const x = mapPositionX(molotov.x);
      const y = mapPositionY(molotov.y);
      const radius = 15 * scale; // Smaller than smoke

      return (
        <Circle
          key={`molotov-${molotov.from}-${molotov.x}-${molotov.y}`}
          x={x}
          y={y}
          radius={radius}
          fill={theme.palette.cs2.molotov}
          opacity={0.7}
        />
      );
    });
  }, [
    metadata.molotovs,
    currentTickIndex,
    mapPositionX,
    mapPositionY,
    scale,
    theme.palette.cs2,
  ]);

  const renderFlashbangs = useCallback(() => {
    if (!metadata.flashbangs) return [];

    return metadata.flashbangs.map((flash) => {
      if (currentTickIndex < flash.from || currentTickIndex > flash.to)
        return null;

      const x = mapPositionX(flash.x);
      const y = mapPositionY(flash.y);
      const maxRadius = 15 * scale;
      const minRadius = 5 * scale;

      const progress = (currentTickIndex - flash.from) / flash.duration;
      const currentRadius = maxRadius - (maxRadius - minRadius) * progress;
      const currentOpacity = 1 - progress;

      return (
        <Circle
          key={`flash-${flash.from}-${flash.x}-${flash.y}`}
          x={x}
          y={y}
          radius={currentRadius}
          fill={theme.palette.cs2.flash}
          opacity={currentOpacity}
        />
      );
    });
  }, [
    metadata.flashbangs,
    currentTickIndex,
    mapPositionX,
    mapPositionY,
    scale,
    theme.palette.cs2,
  ]);

  const renderHEGrenades = useCallback(() => {
    if (!metadata.heGrenades) return [];

    return metadata.heGrenades.map((he) => {
      if (currentTickIndex < he.from || currentTickIndex > he.to) return null;

      const x = mapPositionX(he.x);
      const y = mapPositionY(he.y);
      const maxRadius = 15 * scale;
      const minRadius = 6 * scale;

      const progress = (currentTickIndex - he.from) / he.duration;
      const currentRadius = maxRadius - (maxRadius - minRadius) * progress;
      const currentOpacity = 1 - progress;

      return (
        <Circle
          key={`he-${he.from}-${he.x}-${he.y}`}
          x={x}
          y={y}
          radius={currentRadius}
          fill={theme.palette.cs2.he}
          opacity={currentOpacity}
        />
      );
    });
  }, [
    metadata.heGrenades,
    currentTickIndex,
    mapPositionX,
    mapPositionY,
    scale,
    theme.palette.cs2,
  ]);

  const renderGrenades = useCallback(
    () => [
      ...renderSmokeGrenades(),
      ...renderMolotovs(),
      ...renderFlashbangs(),
      ...renderHEGrenades(),
    ],
    [renderSmokeGrenades, renderMolotovs, renderFlashbangs, renderHEGrenades],
  );

  const renderDeathMarkers = useCallback(() => {
    if (!metadata.playerDeaths) return null;

    const markerSize = 4.5 * scale;
    const markerThickness = 2 * scale;

    return metadata.playerDeaths.map((death) => {
      if (currentTickIndex < death.from) return null;

      const x = mapPositionX(death.x);
      const y = mapPositionY(death.y);
      const color =
        death.side === 'CT' ? theme.palette.cs2.ct : theme.palette.cs2.t;
      const deathKey = `death-${death.from}-${death.x}-${death.y}`;

      const markerPoints = [
        {
          id: 'diagonal1',
          points: [
            x - markerSize,
            y - markerSize,
            x + markerSize,
            y + markerSize,
          ],
        },
        {
          id: 'diagonal2',
          points: [
            x - markerSize,
            y + markerSize,
            x + markerSize,
            y - markerSize,
          ],
        },
      ];

      return (
        <React.Fragment key={deathKey}>
          {markerPoints.map(({ id, points }) => (
            <Line
              key={`${deathKey}-${id}`}
              points={points}
              stroke={color}
              strokeWidth={markerThickness}
              opacity={0.7}
            />
          ))}
        </React.Fragment>
      );
    });
  }, [
    metadata.playerDeaths,
    scale,
    currentTickIndex,
    mapPositionX,
    mapPositionY,
    theme.palette.cs2,
  ]);

  const renderPlayer = useCallback(
    (steamId, playerData) => {
      const { posX, posY, direction, weaponName, isAlive, isFlashed } =
        playerData;
      if (!isAlive) return null;

      const x = mapPositionX(posX);
      const y = mapPositionY(posY);
      const playerInfo = players[steamId] || {
        name: 'Unknown',
        team: 'Unknown',
        side: 'Unknown',
      };
      const {
        length,
        width: lineWidth,
        lineCap,
        type,
        nadeColor,
      } = getWeaponProperties(weaponName);
      const { endX, endY } = calculateLineEnd(x, y, direction, length);
      const nadePos = calculateLineEnd(x, y, direction - 65, length);

      let teamColor;
      if (isFlashed) {
        teamColor =
          playerInfo.side === 'CT'
            ? theme.palette.cs2.ctLight
            : theme.palette.cs2.tLight;
      } else {
        teamColor =
          playerInfo.side === 'CT' ? theme.palette.cs2.ct : theme.palette.cs2.t;
      }

      const isSelected = selectedPlayers[steamId];
      const scalePlayer = isSelected ? 1.15 * scale : 1 * scale;

      return (
        <React.Fragment key={steamId}>
          <Text
            x={x}
            y={y - 18 * scale}
            text={playerInfo.name}
            fontSize={10 * scale}
            fontStyle="bold"
            fill={theme.palette.secondary.dark}
            align="center"
            offsetX={(playerInfo.name.length * 10 * scale) / 4}
          />
          <Circle
            x={x}
            y={y}
            radius={isSelected ? 3 * scalePlayer : 5 * scalePlayer}
            fill={teamColor}
            stroke={isSelected ? 'red' : undefined}
            strokeWidth={isSelected ? scalePlayer * 4 : 0}
            fillAfterStrokeEnabled
          />
          {type === 'line' && (
            <>
              <Line
                points={[x, y, endX, endY]}
                stroke={teamColor}
                strokeWidth={lineWidth}
                lineCap={lineCap}
                fillAfterStrokeEnabled
              />
              {nadeColor && (
                <Circle
                  x={nadePos.endX}
                  y={nadePos.endY}
                  radius={3 * scalePlayer}
                  fill={nadeColor}
                />
              )}
            </>
          )}
        </React.Fragment>
      );
    },
    [
      mapPositionX,
      mapPositionY,
      scale,
      getWeaponProperties,
      players,
      theme.palette,
      selectedPlayers,
    ],
  );

  const renderPlayers = () => {
    const currentTick = currentRoundData.ticks[currentTickIndex];
    if (!currentTick) return null;

    return Object.entries(currentTick.playerX).map(([steamId, posX]) =>
      renderPlayer(steamId, {
        posX,
        posY: currentTick.playerY[steamId],
        direction: currentTick.playerDirection[steamId],
        weaponName: currentTick.playerActiveWeaponName[steamId],
        isAlive: currentTick.playerIsAlive[steamId],
        isFlashed: currentTick.playerFlashDuration[steamId] > 0,
      }),
    );
  };

  const renderBomb = () => {
    const currentTick = currentRoundData.ticks[currentTickIndex];
    if (!currentTick || !currentTick.gameIsBombPlanted || !metadata.bombPlant)
      return null;

    return (
      <Image
        x={mapPositionX(metadata.bombPlant.x) - 9 * scale}
        y={mapPositionY(metadata.bombPlant.y) - 9 * scale}
        image={bombImg}
        width={18 * scale}
        height={18 * scale}
      />
    );
  };

  const renderDrawings = useCallback(() => {
    const tickDrawings = getDrawingsForTick(currentTickIndex);
    return tickDrawings.map((drawing, index) => (
      <Line
        key={`drawing-${index}`}
        points={drawing.points.map((point) => point * scale)} // Scale back to current size
        stroke={drawing.color}
        strokeWidth={drawing.strokeWidth * scale} // Scale strokeWidth too
        tension={0.5}
        lineCap="round"
        globalCompositeOperation="source-over"
      />
    ));
  }, [currentTickIndex, getDrawingsForTick, scale]);

  if (!mapImage || !bombImg) {
    return null; // or a loading indicator
  }

  return (
    <div style={{ position: 'relative', width, height }}>
      <Stage
        width={width}
        height={height}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
        ref={stageRef}
      >
        <Layer>
          <Image image={mapImage} width={width} height={height} />
          {renderDeathMarkers()}
          {renderBomb()}
          {renderPlayers()}
          {renderGrenades()}
          {renderDrawings()}
          <Line
            points={currentLine}
            stroke={drawingColor}
            strokeWidth={strokeWidth}
            tension={0.5}
            lineCap="round"
            globalCompositeOperation="source-over"
          />
        </Layer>
      </Stage>
      <IconButton
        style={{ position: 'absolute', top: 10, right: 10 }}
        onClick={() => setIsContextMenuOpen(!isContextMenuOpen)}
        ref={refContextMenu}
      >
        <SettingsIcon />
      </IconButton>
      {isContextMenuOpen && (
        <ContextMenu
          drawingColor={drawingColor}
          setDrawingColor={setDrawingColor}
          strokeWidth={strokeWidth}
          setStrokeWidth={setStrokeWidth}
          onDelete={handleDelete}
          onClose={() => setIsContextMenuOpen(false)}
          onScreenshot={handleScreenshot}
        />
      )}
      <Legend />
    </div>
  );
}

DemoPlayer.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  isNotesDrawerOpen: PropTypes.bool.isRequired,
};

export default DemoPlayer;
