import {
  Box,
  Button,
  Checkbox,
  Collapse,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  EmojiEvents,
  ExpandMore,
  Info as InfoIcon,
  KeyboardArrowRight,
} from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

function WinnerFilter({
  roundStatusRadio,
  setRoundStatusRadio,
  team1Filter,
  team2Filter,
}) {
  // State to manage the expanded section
  const [expanded, setExpanded] = useState(
    JSON.parse(sessionStorage.getItem('expandedWinner')) || true,
  );
  const [homeChecked, setHomeChecked] = useState(
    roundStatusRadio === 'team1' || roundStatusRadio === 'all' || false,
  );
  const [awayChecked, setAwayChecked] = useState(
    roundStatusRadio === 'team2' || roundStatusRadio === 'all' || false,
  );

  // save the filters in the session storage
  useEffect(() => {
    sessionStorage.setItem('expandedWinner', expanded);
  }, [expanded]);

  useEffect(() => {
    if (roundStatusRadio === 'team1') {
      setHomeChecked(true);
      setAwayChecked(false);
    } else if (roundStatusRadio === 'team2') {
      setHomeChecked(false);
      setAwayChecked(true);
    } else {
      setHomeChecked(true);
      setAwayChecked(true);
    }
  }, [roundStatusRadio]);

  useEffect(() => {
    if (homeChecked && awayChecked) {
      setRoundStatusRadio('all');
    } else if (homeChecked) {
      setRoundStatusRadio('team1');
    } else if (awayChecked) {
      setRoundStatusRadio('team2');
    } else {
      setRoundStatusRadio('all');
    }
  }, [homeChecked, awayChecked]);

  const handleHomeChecked = () => {
    if (homeChecked && !awayChecked) {
      return;
    }
    setHomeChecked(!homeChecked);
  };
  const handleAwayChecked = () => {
    if (!homeChecked && awayChecked) {
      return;
    }
    setAwayChecked(!awayChecked);
  };

  return (
    <>
      <Button
        variant="text"
        fullWidth
        onClick={() => setExpanded(!expanded)}
        sx={{
          justifyContent: 'flex-start',
          color: 'black',
          fontSize: '1rem',
        }}
        startIcon={expanded ? <ExpandMore /> : <KeyboardArrowRight />}
      >
        <Box display="flex" alignItems="center">
          <EmojiEvents sx={{ mr: 1 }} />
          Winner
        </Box>
      </Button>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Grid container alignItems="center">
          <Grid item xs={12} sm={4}>
            <Typography
              variant="subtitle2"
              sx={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                textDecoration: 'underline',
                ml: 1,
              }}
            >
              {team1Filter !== '' ? team1Filter : 'Home'}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4} container justifyContent="center">
            <Checkbox
              size="small"
              checked={homeChecked}
              onChange={handleHomeChecked}
            />
          </Grid>

          <Grid item xs={12} sm={4} container justifyContent="flex-end">
            <Tooltip title="Select the team that won the round">
              <IconButton size="small">
                <InfoIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography
              variant="subtitle2"
              sx={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                textDecoration: 'underline',
                ml: 1,
              }}
            >
              {team2Filter !== '' ? team2Filter : 'Away'}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4} container justifyContent="center">
            <Checkbox
              size="small"
              checked={awayChecked}
              onChange={handleAwayChecked}
            />
          </Grid>
          <Grid item xs={12} sm={4} />
        </Grid>
      </Collapse>
    </>
  );
}

WinnerFilter.propTypes = {
  roundStatusRadio: PropTypes.string.isRequired,
  setRoundStatusRadio: PropTypes.func.isRequired,
  team1Filter: PropTypes.string.isRequired,
  team2Filter: PropTypes.string.isRequired,
};

export default WinnerFilter;
