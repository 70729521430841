import { Delete, Edit, Save } from '@mui/icons-material';
import {
  Box,
  Card,
  CardContent,
  Chip,
  IconButton,
  TextField,
  Typography,
  Tooltip,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useMatchData } from '../../utils/MatchDataContext';

const TICKS_PER_SECOND = 16;

function NoteCard({
  tickIndex,
  content,
  onSave,
  onDelete,
  editingTickIndex,
  drawingsSaved,
  className,
}) {
  const theme = useTheme();
  const [text, setText] = useState(content || '');
  const [isEditing, setIsEditing] = useState(false);
  const {
    currentTickIndex,
    setCurrentTickIndex,
    getDrawingsForTick,
    drawings,
    currentRoundNumber,
  } = useMatchData();
  const [isHighlighted, setIsHighlighted] = useState(false);
  const [drawingsForTick, setDrawingsForTick] = useState([]);
  const textFieldRef = useRef(null);

  // to focus text field when editing
  useEffect(() => {
    if (isEditing && textFieldRef.current) {
      textFieldRef.current.focus();
      textFieldRef.current.setSelectionRange(text.length, text.length);
    }
  }, [isEditing, text.length]);

  // get drawings for the tick
  useEffect(() => {
    setDrawingsForTick(getDrawingsForTick(tickIndex));
  }, [tickIndex, drawings]);

  useEffect(() => {
    // check if the editing tick index is the same as the tick index of the note
    // Number(editingTickIndex) cant be used as it will be 0 if editingTickIndex is null
    if (
      editingTickIndex === Number(tickIndex) ||
      editingTickIndex === tickIndex
    ) {
      setIsEditing(true);
    }
  }, [editingTickIndex, tickIndex]);

  useEffect(() => {
    setIsHighlighted(Number(currentTickIndex) === Number(tickIndex));
  }, [currentTickIndex, tickIndex]);

  // check if there are drawings for the tick and if the drawing differs from the note's drawing
  useEffect(() => {
    if (!drawingsForTick || !drawingsSaved) return;
    if (drawingsForTick.length === 0) return;
    if (drawingsForTick.length !== drawingsSaved.length) {
      setIsEditing(true);
    }
  }, [drawingsSaved, drawingsForTick]);

  const handleSave = () => {
    onSave(tickIndex, text, drawingsForTick);
    setIsEditing(false);
  };

  const handleCardClick = (event) => {
    // Prevent changing tick index if clicking on buttons or text field
    if (
      !event.target.closest('button') &&
      !event.target.closest('.MuiTextField-root')
    ) {
      setCurrentTickIndex(Number(tickIndex));
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleSave();
    }
  };

  const minutes = Math.floor(Math.floor(tickIndex / TICKS_PER_SECOND) / 60);
  const seconds = (Math.floor(tickIndex / TICKS_PER_SECOND) % 60)
    .toString()
    .padStart(2, '0');

  return (
    <Card
      className={className}
      onClick={handleCardClick}
      sx={{
        mb: 1,
        borderRadius: 2,
        transition: 'all 0.3s ease',
        boxShadow: isHighlighted
          ? `0 0 8px ${theme.palette.primary.light}`
          : 'none',
      }}
    >
      <CardContent sx={{ p: 1.5, '&:last-child': { pb: 1 } }}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={1}
        >
          <Chip
            label={`Round ${currentRoundNumber} | ${minutes}:${seconds}`}
            color="primary"
            variant="outlined"
            size="small"
          />
          <Box>
            {isEditing ? (
              <Tooltip title="Save changes">
                <IconButton onClick={handleSave} size="small" color="primary">
                  <Save fontSize="small" />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title="Edit note">
                <IconButton onClick={() => setIsEditing(true)} size="small">
                  <Edit fontSize="small" />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title="Delete note permanently">
              <IconButton onClick={() => onDelete(tickIndex)} size="small">
                <Delete fontSize="small" />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>

        {isEditing ? (
          <TextField
            fullWidth
            multiline
            minRows={2}
            maxRows={6}
            value={text}
            onChange={(e) => setText(e.target.value)}
            variant="outlined"
            size="small"
            onKeyDown={handleKeyDown}
            inputRef={textFieldRef}
          />
        ) : (
          <Typography
            variant="body1"
            sx={{
              whiteSpace: 'pre-wrap',
              wordBreak: 'break-word',
              maxHeight: '120px',
              overflow: 'auto',
              bgcolor: 'grey.100',
              p: 1,
              mb: 0.5,
              borderRadius: 1,
            }}
          >
            {content}
          </Typography>
        )}

        {drawingsSaved && (
          <Typography variant="caption" color="text.secondary">
            {drawingsForTick &&
            drawingsSaved &&
            drawingsForTick.length !== drawingsSaved.length
              ? 'Drawings updated, not saved'
              : drawingsForTick &&
                  drawingsForTick.length === 0 &&
                  drawingsSaved &&
                  drawingsSaved.length === 0
                ? 'No drawings'
                : 'Drawings saved'}
          </Typography>
        )}
      </CardContent>
    </Card>
  );
}

NoteCard.propTypes = {
  tickIndex: PropTypes.number.isRequired,
  content: PropTypes.string,
  onSave: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  editingTickIndex: PropTypes.number,
  drawingsSaved: PropTypes.arrayOf(PropTypes.shape({})),
  className: PropTypes.string,
};

export default NoteCard;
