import React, { useState } from 'react';
import {
  Box,
  Button,
  Container,
  Paper,
  TextField,
  Snackbar,
  Alert as MuiAlert,
} from '@mui/material';
import {
  ArrowBack as ArrowBackIcon,
  Send as SendIcon,
} from '@mui/icons-material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import logo from '../DefuzeLogoColor.svg';
import axiosInstance from '../axiosInstance.js';

// validation schema with yup
const validationSchema = Yup.object({
  username: Yup.string().required('Username is required'),
  email: Yup.string()
    .email('Invalid email address')
    .matches(
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      'Invalid email address',
    )
    .required('Email is required'),
});

export default function forgotPassword() {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const formik = useFormik({
    initialValues: {
      username: '',
      email: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        const response = await axiosInstance.post(
          '/api/users/forgot-password',
          {
            username: values.username,
            email: values.email,
          },
        );
        if (
          response.status === 200 &&
          response.data.message === 'Reset password email sent'
        ) {
          setSnackbarMessage('Password reset link sent to email');
          setSnackbarSeverity('success');
          setOpenSnackbar(true);
        }
      } catch (error) {
        console.debug(error);
        if (
          error.response &&
          error.response.status === 404 &&
          error.response.data.message === 'User not found'
        ) {
          setSnackbarMessage(
            'Your credentials are incorrect, please try again',
          );
        } else {
          setSnackbarMessage('Error sending email');
        }
        setSnackbarSeverity('error');
        setOpenSnackbar(true);
      }
    },
  });

  return (
    <Paper
      elevation={3}
      sx={{
        padding: 1,
        width: 350,
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
      }}
    >
      <Snackbar
        open={openSnackbar}
        autoHideDuration={10000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          severity={snackbarSeverity}
          onClose={() => setOpenSnackbar(false)}
          sx={{ width: 300 }}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
      <Container component="main" maxWidth="xs">
        <Link to="/signin">
          <ArrowBackIcon style={{ paddingTop: '10px', fontSize: 'xx-large' }} />
        </Link>
        <Box
          sx={{
            marginTop: 2,
            marginBottom: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <img src={logo} alt="Logo" style={{ height: '60px' }} />
          <Box component="form" onSubmit={formik.handleSubmit} sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label="Username"
              name="username"
              autoComplete="username"
              autoFocus
              value={formik.values.username}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.username && Boolean(formik.errors.username)}
              helperText={formik.touched.username && formik.errors.username}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email address"
              name="email"
              autoComplete="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              endIcon={<SendIcon />}
            >
              Reset Password
            </Button>
          </Box>
        </Box>
      </Container>
    </Paper>
  );
}
