import React, { useState } from 'react';
import NotificationSnackbar from './NotificationSnackbar';
import { useAuth } from '../../utils/AuthContext';

function EmailVerificationSnackbar() {
  const { user } = useAuth();
  if (!user || user.verified) {
    return null;
  }
  const { verified } = user;
  const [open, setOpen] = useState(!verified);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <NotificationSnackbar
      open={open}
      message="Verify your email to unlock all features"
      severity="info"
      onClose={handleClose}
    />
  );
}

export default EmailVerificationSnackbar;
