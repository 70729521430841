import React from 'react';
import { Link } from 'react-router-dom';
import { AppBar, Box, Button, Toolbar } from '@mui/material';
import styled from '@mui/material/styles/styled';
import logo2 from '../../DefuzeLogoColor.svg';
import logo from '../../DefuzeLogoWhite.svg';

const StyledAppBar = styled(AppBar)({
  borderRadius: 0,
  border: 'none',
});

const CustomButton = styled(Button)(({ theme }) => ({
  color: theme.palette.primary.main,
  position: 'relative',
  backgroundColor: 'transparent',
  '&:hover': {
    backgroundColor: 'transparent',
  },
  '&::after': {
    content: '""',
    position: 'absolute',
    width: '100%',
    transform: 'scaleX(0)',
    height: '2px',
    bottom: 0,
    left: 0,
    backgroundColor: theme.palette.primary.main,
    transformOrigin: 'center',
    transition: 'transform 0.25s ease-out',
  },
  '&:hover::after': {
    transform: 'scaleX(1)',
    transformOrigin: 'center',
  },
}));

function TopBarLandingPage() {
  return (
    <StyledAppBar
      position="sticky"
      style={{ backgroundColor: '#fff', boxShadow: 'none' }}
    >
      <Toolbar>
        <a href="/">
          <img
            src={logo2}
            alt="Logo"
            style={{ marginRight: 'auto', width: '150px', height: 'auto' }}
          />
        </a>
        <Box display="flex" justifyContent="center" flexGrow={1}>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Link to="/" style={{ margin: '0 10px' }}>
              <CustomButton variant="text" style={{ color: 'primary.main' }}>
                Home
              </CustomButton>
            </Link>
            <Link to="/pricing" style={{ margin: '0 10px' }}>
              <CustomButton variant="text" style={{ color: 'primary.main' }}>
                Pricing
              </CustomButton>
            </Link>
            <Link to="/help" style={{ margin: '0 10px' }}>
              <CustomButton variant="text" style={{ color: 'pirmary.main' }}>
                Help
              </CustomButton>
            </Link>
          </Box>
          <a href="/">
            <img
              src={logo}
              alt="Logo"
              style={{ marginRight: 'auto', width: '150px', height: 'auto' }}
            />
          </a>
        </Box>
      </Toolbar>
    </StyledAppBar>
  );
}

export default TopBarLandingPage;
