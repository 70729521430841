import { useTheme } from '@mui/material/styles';
import { Box, Button, IconButton, Popper, Typography } from '@mui/material';
import { Close, NavigateBefore, NavigateNext } from '@mui/icons-material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

function CustomTooltip({
  open,
  anchorRef,
  content,
  setCurrentTooltipIndex,
  setTut,
  currentTooltipIndex,
  placement,
  maxIndex,
  sessionItem,
  offset = 0,
}) {
  const theme = useTheme();
  const [arrowRef, setArrowRef] = useState(null);

  const onPrev = () => {
    setCurrentTooltipIndex((prev) => (prev > 0 ? prev - 1 : prev));
  };

  const onNext = () => {
    setCurrentTooltipIndex((prev) => (prev < maxIndex ? prev + 1 : prev));
  };

  const onClose = () => {
    setTut(false);
    setCurrentTooltipIndex(0);
    localStorage.removeItem(sessionItem);
    if (sessionItem === 'tutDashboard') {
      localStorage.removeItem('tutDashboardSub');
    }
    if (sessionItem === 'tutMatches') {
      localStorage.removeItem('tutMatchesSub');
    }
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (open) {
        switch (event.key) {
          case 'ArrowLeft':
            onPrev();
            break;
          case 'ArrowRight':
            onNext();
            break;
          case 'Escape':
            onClose();
            break;
          default:
            break;
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [open, currentTooltipIndex, maxIndex]);

  if (!anchorRef) {
    return null;
  }

  const TooltipContent = (
    <Box>
      <Box alignItems="center" justifyContent="space-between" display="flex">
        <Typography variant="body1" sx={{ fontSize: '0.8rem' }}>
          Step {currentTooltipIndex + 1 - offset} of {maxIndex + 1 - offset}
        </Typography>
        <IconButton onClick={onClose}>
          <Close />
        </IconButton>
      </Box>
      <Typography variant="subtitle2" sx={{ mt: 1 }}>
        {content}
      </Typography>
      <Box
        sx={{ justifyContent: 'space-between', display: 'flex', marginTop: 2 }}
      >
        <Button
          size="small"
          sx={{ minWidth: 'auto', padding: '4px' }}
          disabled={currentTooltipIndex === 0}
          onClick={onPrev}
        >
          <NavigateBefore fontSize="small" />
        </Button>
        <Button
          size="small"
          sx={{ minWidth: 'auto', padding: '4px' }}
          disabled={currentTooltipIndex === maxIndex}
          onClick={onNext}
        >
          <NavigateNext fontSize="small" />
        </Button>
      </Box>
    </Box>
  );

  return (
    <Popper
      anchorEl={anchorRef.current}
      disablePortal={false}
      open={open}
      placement={placement}
      modifiers={[
        {
          name: 'offset',
          options: {
            offset: [0, 10],
          },
        },
        {
          name: 'preventOverflow',
          enabled: true,
          options: {
            altAxis: true,
            altBoundary: false,
            tether: false,
            rootBoundary: 'document',
            padding: { top: 20, right: 20, bottom: 0, left: 90 },
          },
        },
        {
          name: 'arrow',
          enabled: true,
          options: {
            element: arrowRef,
          },
        },
      ]}
      sx={{
        zIndex: 1000,
      }}
    >
      <Box
        sx={{
          backgroundColor: 'white',
          borderRadius: '16px',
          border: `2px solid ${theme.palette.primary.main}`,
          color: 'black',
          padding: '16px',
          maxWidth: '250px',
          position: 'relative',
        }}
      >
        {TooltipContent}
        <Box
          ref={setArrowRef}
          sx={{
            position: 'absolute',
            width: 0,
            height: 0,
            border: 'solid transparent',
            borderWidth: '8px',
            '&::before': {
              content: '""',
              position: 'absolute',
              width: 0,
              height: 0,
              border: 'solid transparent',
              borderWidth: '7px',
            },
            '[data-popper-placement^="top"] &': {
              bottom: -16,
              borderTopColor: theme.palette.primary.main,
              '&::before': {
                bottom: -5,
                left: -7,
                borderTopColor: 'white',
              },
            },
            '[data-popper-placement^="bottom"] &': {
              top: -16,
              borderBottomColor: theme.palette.primary.main,
              '&::before': {
                top: -5,
                left: -7,
                borderBottomColor: 'white',
              },
            },
            '[data-popper-placement^="left"] &': {
              right: -16,
              borderLeftColor: theme.palette.primary.main,
              '&::before': {
                top: -7,
                right: -5,
                borderLeftColor: 'white',
              },
            },
            '[data-popper-placement^="right"] &': {
              left: -16,
              borderRightColor: theme.palette.primary.main,
              '&::before': {
                top: -7,
                left: -5,
                borderRightColor: 'white',
              },
            },
          }}
        />
      </Box>
    </Popper>
  );
}

CustomTooltip.propTypes = {
  open: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  anchorRef: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  content: PropTypes.object.isRequired,
  setCurrentTooltipIndex: PropTypes.func.isRequired,
  setTut: PropTypes.func.isRequired,
  currentTooltipIndex: PropTypes.number.isRequired,
  placement: PropTypes.string.isRequired,
  maxIndex: PropTypes.number,
  sessionItem: PropTypes.string.isRequired,
  offset: PropTypes.number,
};

export default CustomTooltip;
