import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Box, Paper, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useMatchData } from '../../utils/MatchDataContext.js';
import PlayerCard from './PlayerCard';

function TeamInfo({ left }) {
  const theme = useTheme();
  const {
    currentMapData,
    currentRoundData,
    players,
    currentTickIndex,
    selectedPlayers,
    setSelectedPlayers,
    metadata,
  } = useMatchData();

  const teamName = useMemo(
    () => (left ? currentMapData.winnerName : currentMapData.loserName),
    [left, currentMapData],
  );

  const { teamColor, teamColorDark } = useMemo(() => {
    const isWinningTeam =
      (left && currentRoundData.winnerName === currentMapData.winnerName) ||
      (!left && currentRoundData.winnerName === currentMapData.loserName);

    const color = isWinningTeam
      ? currentRoundData.winnerSide === 'CT'
        ? theme.palette.cs2.ct
        : theme.palette.cs2.t
      : currentRoundData.winnerSide === 'CT'
        ? theme.palette.cs2.t
        : theme.palette.cs2.ct;

    const colorDark = isWinningTeam
      ? currentRoundData.winnerSide === 'CT'
        ? theme.palette.cs2.ctDark
        : theme.palette.cs2.tDark
      : currentRoundData.winnerSide === 'CT'
        ? theme.palette.cs2.tDark
        : theme.palette.cs2.ctDark;

    return { teamColor: color, teamColorDark: colorDark };
  }, [
    left,
    currentRoundData.winnerName,
    currentRoundData.winnerSide,
    currentMapData,
    theme.palette.cs2,
  ]);

  const calculatePlayerKills = (playerName) => {
    if (!metadata.playerDeaths) {
      return null;
    }
    return metadata.playerDeaths.filter(
      (death) =>
        death.from <= currentTickIndex && death.attacker === playerName,
    ).length;
  };

  const togglePlayerSelection = useCallback(
    (steamId) => {
      setSelectedPlayers((prevSelected) => ({
        ...prevSelected,
        [steamId]: !prevSelected[steamId],
      }));
    },
    [setSelectedPlayers],
  );

  const teamPlayers = useMemo(
    () =>
      Object.keys(players).filter((steamId) =>
        left
          ? players[steamId].team === currentMapData.winner.name
          : players[steamId].team === currentMapData.loser.name,
      ),
    [players, left, currentMapData],
  );

  const currentTick = currentRoundData.ticks[currentTickIndex];

  return (
    <Paper
      sx={{
        width: '100%',
        height: '100%',
        overflow: 'auto',
        borderRadius: 0,
        borderBottom: 0,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          minHeight: '87px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          mb: 1,
          borderBottom: '1px solid #ccc',
        }}
      >
        <Typography
          variant="h4"
          fontalign="center"
          fontWeight="bold"
          color={teamColorDark}
        >
          {teamName}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          flexGrow: 1,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            pr: 1,
            pl: 1,
          }}
        >
          {teamPlayers.map((steamId) => (
            <PlayerCard
              key={steamId}
              steamId={steamId}
              playerName={players[steamId].name}
              playerTeam={players[steamId].team}
              playerHealth={currentTick.playerHealth[steamId]}
              playerMoney={currentTick.playerBalance[steamId]}
              playerInventory={currentTick.playerInventory[steamId]}
              playerIsAlive={currentTick.playerIsAlive[steamId]}
              playerHasKevlar={currentTick.playerArmorValue[steamId] > 0}
              playerHasHelmet={currentTick.playerHasHelmet[steamId]}
              playerHasDefuser={currentTick.playerHasDefuser[steamId]}
              playerActiveWeaponName={
                currentTick.playerActiveWeaponName[steamId]
              }
              playerKills={calculatePlayerKills(players[steamId].name)}
              isSelected={selectedPlayers[steamId]}
              teamColor={teamColor}
              teamColorDark={teamColorDark}
              onToggleSelection={togglePlayerSelection}
            />
          ))}
        </Box>
      </Box>
    </Paper>
  );
}

TeamInfo.propTypes = {
  left: PropTypes.bool,
};

export default React.memo(TeamInfo);
