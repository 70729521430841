import React, { useEffect, useRef, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  Box,
  IconButton,
  TextField,
  Autocomplete,
  Link,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Close } from '@mui/icons-material';
import { ReactComponent as Logo } from '../../DefuzeLogoColor.svg';
import axiosInstance from '../../axiosInstance.js';
import { useAuth } from '../../utils/AuthContext.js';

function OnboardDialog({ onboardDialogOpen, setOnboardDialogOpen }) {
  const [options, setOptions] = useState([]);
  const [team, setTeam] = useState(null);
  const autocompleteRef = useRef(null);
  const navigate = useNavigate();
  const { user, setUser } = useAuth();

  // get teams on component mount
  useEffect(() => {
    const getTeams = async () => {
      try {
        const response = await axiosInstance.get('/api/teams');
        setOptions(
          response.data.map((t) => ({
            label: t.name,
            id: t._id,
          })),
        );
      } catch (error) {
        console.error('Failed to fetch teams');
      }
    };

    getTeams().then();
  }, []);

  const forward = () => {
    navigate('/dashboard');
  };

  const demo = () => {
    navigate('/matches');
  };

  const handleSaveClick = async (event, newValue) => {
    if (!newValue || !user) {
      return;
    }
    try {
      // Send request to patch users endpoint
      const userId = user._id;
      const updatedData = {
        preferredTeam: newValue.id,
      };
      if (updatedData.preferredTeam === '0') {
        updatedData.preferredTeam = null;
      }
      const response = await axiosInstance.patch(
        `/api/users/${userId}`,
        updatedData,
      );
      if (
        response.status === 200 &&
        response.data.message === 'User successfully updated'
      ) {
        setUser(response.data.user);
      }
    } catch (error) {
      console.debug('Update user failed');
    }
  };

  // set random first team by default
  useEffect(() => {
    if (options.length === 0) {
      return;
    }
    setTeam(options[0]);
    handleSaveClick(null, options[0]).then();
  }, [options]);

  return (
    <Dialog
      open={onboardDialogOpen}
      onClose={() => setOnboardDialogOpen(false)}
    >
      <Box position="absolute" top={0} right={0} sx={{ mt: 1, mr: 1 }}>
        <IconButton onClick={() => setOnboardDialogOpen(false)}>
          <Close />
        </IconButton>
      </Box>
      <DialogContent sx={{ width: '500px' }}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          sx={{ mt: -2 }}
        >
          <Logo width={300} height={100} />
        </Box>
        <Typography variant="h5" align="center" gutterBottom fontWeight="bold">
          Welcome on board!
        </Typography>
        <Typography variant="body1" align="center" gutterBottom>
          We&apos;re thrilled to have you join us! Get ready to explore all the
          amazing features our platform has to offer.
        </Typography>
        <Typography variant="body1" align="center">
          Start by {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Link
            component="button"
            variant="body1"
            fontWeight="bold"
            onClick={() => demo()}
            sx={{
              color: 'light.primary.main',
              backgroundColor: 'transparent',
              textDecoration: 'none',
              verticalAlign: 'top',
              '&:hover': {
                backgroundColor: 'transparent',
                textDecoration: 'none',
              },
              '&::after': {
                content: '""',
                position: 'absolute',
                width: '100%',
                transform: 'scaleX(0)',
                height: '2px',
                bottom: 0,
                left: 0,
                backgroundColor: 'primary.main',
                transformOrigin: 'center',
                transition: 'transform 0.25s ease-out',
              },
              '&:hover::after': {
                transform: 'scaleX(1)',
                transformOrigin: 'center',
              },
            }}
          >
            uploading your first demo
          </Link>{' '}
          to unlock the full potential of our site, or select one of the teams
          below to instantly discover our advanced analytics and insights
          features.
        </Typography>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{ mt: 4, width: '100%' }}
        >
          <Box width="80%">
            <Autocomplete
              fullWidth
              ref={autocompleteRef}
              options={options}
              name="preferredTeam"
              value={team}
              onChange={(event, newValue) => {
                handleSaveClick(event, newValue).then(() => {
                  setTeam(newValue);
                  requestAnimationFrame(() => {
                    if (autocompleteRef.current) {
                      const input =
                        autocompleteRef.current.querySelector('input');
                      if (input) {
                        input.blur();
                        // Remove focus from the entire Autocomplete component
                        autocompleteRef.current.blur();
                      }
                    }
                    // Move focus to the body
                    // to ensure it's completely removed from the Autocomplete
                    document.body.focus();
                  });
                });
              }}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              getOptionLabel={(option) => option.label}
              disableClearable
              noOptionsText="No teams available"
              renderInput={(params) => (
                <TextField
                  /* eslint-disable-next-line react/jsx-props-no-spreading */
                  {...params}
                  label="Team"
                  fullWidth
                />
              )}
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box sx={{ mt: 1, mr: 1, mb: 1 }}>
          <Button onClick={forward} color="primary" variant="contained">
            Go to dashboard
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}

OnboardDialog.propTypes = {
  onboardDialogOpen: PropTypes.bool.isRequired,
  setOnboardDialogOpen: PropTypes.func.isRequired,
};

export default OnboardDialog;
