import axios from 'axios';
import { expressUrl } from './config.js';

/*
  Axios instance for making API requests
  - Sets the base URL for the API
  - Sets the content type to JSON
  - Adds a request interceptor to add the JWT access token to all request headers
  - Adds a response interceptor to refresh the JWT access token if it is expired
  -> If the token is invalid or expired, the refresh token endpoint is called to get a new access token
  -> If the token refresh is successful, the new access token is updated in local storage
  -> The original request is retried with the new access token
  -> If the token refresh fails, the user is redirected to the sign-in page
 */

const axiosInstance = axios.create({
  baseURL: expressUrl,
  headers: {
    'Content-Type': 'application/json',
  },
});

// request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('jwt-token');
    const newConfig = config;
    if (token) {
      // the token is sent with a Bearer prefix
      newConfig.headers.authorization = `Bearer ${token}`;
    }
    return newConfig;
  },
  (error) => Promise.reject(error),
);

// response interceptor
axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (
      (error.response &&
        error.response.status === 401 &&
        error.response.data.message === 'Token is invalid or expired!') ||
      (error.response &&
        error.response.status === 403 &&
        error.response.data.message === 'Forbidden')
    ) {
      const originalRequest = error.config;
      // set a flag to prevent an infinite loop

      if (!originalRequest._retry) {
        originalRequest._retry = true;
        try {
          const data = await axiosInstance.post(
            '/api/users/refresh-token',
            {},
            { withCredentials: true },
          );
          localStorage.setItem('jwt-token', data.data.token);
          axiosInstance.defaults.headers.authorization = `Bearer ${data.data.token}`;
          return axiosInstance(originalRequest);
        } catch (err) {
          console.debug(
            'Failed to refresh token (could be that no refresh token is available)',
          );
          // Handle invalid token
          localStorage.removeItem('jwt-token');
          // use href instead of history.push to force a full page reload
          window.location.href = '/signin';
        }
      }
    }
    return Promise.reject(error);
  },
);

export default axiosInstance;
