import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Box, Typography } from '@mui/material';
import LandingPageBackground from '../LandingPage_Sketch_small.svg';

function LandingPage() {
  return (
    <Box
      sx={{
        position: 'relative',
        height: 'calc(100vh - 64px)',
        overflow: 'hidden',
        textAlign: 'center',
        backgroundImage: `url(${LandingPageBackground})`,
        backgroundSize: 'cover',
        backgroundPosition: 'bottom',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '25%',
          width: '100%',
        }}
      >
        <Typography variant="h3" component="h1" gutterBottom>
          Stay ahead of competition and <br />
          <Box
            component="span"
            sx={{ fontWeight: 'bold', color: 'primary.main' }}
          >
            DEFUZE
          </Box>{' '}
          your opponents strategy
        </Typography>
        <Box>
          <Button
            component={Link}
            to="/signin"
            color="primary"
            size="large"
            sx={{ mt: 3, mr: 2 }}
          >
            Sign in
          </Button>
          <Button
            component={Link}
            to="/signup"
            variant="contained"
            color="primary"
            size="large"
            sx={{ mt: 3 }}
          >
            Try now
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default LandingPage;
