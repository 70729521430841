import React, { useEffect, useState } from 'react';
import {
  Box,
  Card,
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import {
  East,
  HorizontalRule,
  Info,
  North,
  NorthEast,
  South,
  SouthEast,
} from '@mui/icons-material';
import { useAuth } from '../../utils/AuthContext.js';
import BlurredBox from '../common/BlurredBox.jsx';
import TooltipTeamName from './TooltipTeamName.jsx';

function PlayerStats({
  analytics,
  isLoadingAnalytics,
  team,
  isTeamLoading,
  lastMapStats,
}) {
  const [totalMatchesOfTeam, setTotalMatchesOfTeam] = useState(0);
  const [isBlurred, setIsBlurred] = useState(false);
  const { user } = useAuth();

  // check user status
  useEffect(() => {
    if (!user) {
      return;
    }
    if (!user.verified || user.subscription === 0) {
      setIsBlurred(true);
    } else {
      setIsBlurred(false);
    }
  }, [user]);

  // set team stats (count of matches, wins losses)
  useEffect(() => {
    if (analytics) {
      let totalMatches = 0;
      Object.values(analytics.countOfAddedMatches).forEach((count) => {
        totalMatches += count[0];
      });
      setTotalMatchesOfTeam(totalMatches);
    }
  }, [analytics]);

  const getIconByPerformanceRatio = (performanceRatio) => {
    const iconSize = { fontSize: '16px' };
    if (performanceRatio > 1.15) {
      return <North sx={iconSize} />;
    }
    if (performanceRatio > 1.05) {
      return <NorthEast sx={iconSize} />;
    }
    if (performanceRatio < 1.05 && performanceRatio > 0.95) {
      return <East sx={iconSize} />;
    }
    if (performanceRatio < 0.85) {
      return <South sx={iconSize} />;
    }
    if (performanceRatio < 0.95) {
      return <SouthEast sx={iconSize} />;
    }
    return <HorizontalRule sx={iconSize} />;
  };

  // get arrow direction
  const getArrowDirection = (label, value, steamid) => {
    if (!analytics || !lastMapStats || !team) {
      return <HorizontalRule sx={{ fontSize: '16px' }} />;
    }

    switch (label) {
      case 'Kills/Deaths': {
        const currentKills = lastMapStats.reduce(
          (sum, map) => sum + (map.kills[steamid] || 0),
          0,
        );
        const currentDeaths = lastMapStats.reduce(
          (sum, map) => sum + (map.deaths[steamid] || 0),
          0,
        );
        const currentKd = currentKills / currentDeaths;
        const performanceRatio = currentKd / value;
        return getIconByPerformanceRatio(performanceRatio);
      }
      case 'Damage/Round': {
        const performanceRatio =
          lastMapStats.reduce((sum, map) => sum + (map.adr[steamid] || 0), 0) /
          lastMapStats.length /
          value;
        return getIconByPerformanceRatio(performanceRatio);
      }
      case 'Headshots': {
        const currentHeadshots = lastMapStats.reduce(
          (sum, map) => sum + (map.headshots[steamid] || 0),
          0,
        );
        const currentKills = lastMapStats.reduce(
          (sum, map) => sum + (map.kills[steamid] || 0),
          0,
        );
        const currentHeadshotPercentage =
          (currentHeadshots / currentKills) * 100;
        const performanceRatio = currentHeadshotPercentage / parseFloat(value);
        return getIconByPerformanceRatio(performanceRatio);
      }
      case 'Flash assists': {
        const currentFlashAssists = lastMapStats.reduce(
          (sum, map) => sum + (map.enemiesFlashed[steamid] || 0),
          0,
        );
        const totalFlashAssists = lastMapStats.reduce(
          (sum, map) =>
            sum +
            Object.entries(map.enemiesFlashed).reduce(
              (mapSum, [key, flashes]) =>
                mapSum + (key in team.players ? flashes : 0),
              0,
            ),
          0,
        );
        const currentFlashAssistPercentage =
          (currentFlashAssists / totalFlashAssists) * 100;
        const performanceRatio =
          currentFlashAssistPercentage / parseFloat(value);
        return getIconByPerformanceRatio(performanceRatio);
      }
      case 'Utility damage': {
        const performanceRatio =
          lastMapStats.reduce(
            (sum, map) => sum + (map.utilityDamage[steamid] || 0),
            0,
          ) /
          lastMapStats.length /
          value;
        return getIconByPerformanceRatio(performanceRatio);
      }
      default:
        return <HorizontalRule sx={{ fontSize: '16px' }} />;
    }
  };

  const getBackgroundColor = (label, value) => {
    const performanceColors = {
      great: 'rgba(76, 175, 80, 0.2)', // Light green with 20% opacity
      ok: 'rgba(255, 193, 7, 0.2)', // Light yellow with 20% opacity
      bad: 'rgba(244, 67, 54, 0.2)', // Light red with 20% opacity
    };

    switch (label) {
      case 'Kills/Deaths': {
        if (value > 1.1) {
          return performanceColors.great;
        }
        if (value < 0.9) {
          return performanceColors.bad;
        }
        return performanceColors.ok;
      }
      case 'Damage/Round': {
        if (value > 80) {
          return performanceColors.great;
        }
        if (value < 70) {
          return performanceColors.bad;
        }
        return performanceColors.ok;
      }
      case 'Headshots': {
        if (parseFloat(value) > 43) {
          return performanceColors.great;
        }
        if (parseFloat(value) < 30) {
          return performanceColors.bad;
        }
        return performanceColors.ok;
      }
      case 'Flash assists': {
        if (value > 0.25) {
          return performanceColors.great;
        }
        if (value > 0.2) {
          return performanceColors.bad;
        }
        return performanceColors.ok;
      }
      case 'Utility damage': {
        if (value > 100) {
          return performanceColors.great;
        }
        if (value < 60) {
          return performanceColors.bad;
        }
        return performanceColors.ok;
      }
      default:
        return '#FFFFF';
    }
  };

  return (
    <Paper sx={{ padding: 3 }}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ mb: 3 }}
      >
        <Typography variant="h6">Player stats</Typography>
        <TooltipTeamName team={team} />
      </Box>
      <div style={{ position: 'relative' }}>
        <Box
          sx={{
            filter: isBlurred ? 'blur(12px)' : 'none',
            pointerEvents: isBlurred ? 'none' : 'auto',
          }}
        >
          {isTeamLoading && <CircularProgress />}
          {!team && !isTeamLoading && (
            <Typography variant="body1">
              Select a team to see player statistics
            </Typography>
          )}
          {team && !isTeamLoading && (
            <Grid container spacing={2}>
              {Object.entries(team.players).map(([steamid, name]) => (
                <Grid item sm={2.4} key={name}>
                  <Card
                    key={steamid}
                    sx={{
                      padding: 1,
                      height: 'fit-content',
                      width: '100%',
                      boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                    }}
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Typography
                        variant="subtitle1"
                        fontWeight="bold"
                        sx={{ mb: 1 }}
                      >
                        {name}
                      </Typography>
                      <Tooltip
                        title={
                          <Box>
                            <Typography variant="subtitle2" gutterBottom>
                              Visual Indicators:
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                              Color Coding:
                            </Typography>
                            <Typography variant="body2">
                              🟢 Green: Great performance
                            </Typography>
                            <Typography variant="body2">
                              🟠 Orange: Average performance
                            </Typography>
                            <Typography variant="body2" mb={1}>
                              🔴 Red: Subpar performance
                            </Typography>
                            <Typography
                              variant="body2"
                              mt={1}
                              mb={1}
                              fontStyle="italic"
                            >
                              Performance is based on the player&apos;s average
                              over all games
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                              Trend Arrows:
                            </Typography>
                            <Typography variant="body2">
                              ↑ Upward trend (improving)
                            </Typography>
                            <Typography variant="body2">
                              → Stable trend
                            </Typography>
                            <Typography variant="body2">
                              ↓ Downward trend (declining)
                            </Typography>
                            <Typography
                              variant="body2"
                              mt={1}
                              fontStyle="italic"
                            >
                              Trend is based on the player&apos;s performance in
                              the last 4 games
                            </Typography>
                          </Box>
                        }
                        placement="top"
                      >
                        <IconButton
                          size="small"
                          sx={{
                            mr: '-8px',
                            mb: 1,
                          }}
                        >
                          <Info fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </Box>
                    {isLoadingAnalytics && <CircularProgress />}
                    {!analytics && !isLoadingAnalytics && (
                      <Typography variant="body1">No data found</Typography>
                    )}
                    {analytics && !isLoadingAnalytics && (
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: 1,
                        }}
                      >
                        {[
                          {
                            label: 'Kills/Deaths',
                            value: (
                              analytics.killsPerPlayer[steamid] /
                              analytics.deathsPerPlayer[steamid]
                            ).toFixed(2),
                          },
                          {
                            label: 'Damage/Round',
                            value: (
                              analytics.adrPerPlayer[steamid] /
                              totalMatchesOfTeam
                            ).toFixed(2),
                          },
                          {
                            label: 'Headshots',
                            value: `${(
                              (analytics.headshotsPerPlayer[steamid] /
                                analytics.killsPerPlayer[steamid]) *
                              100
                            ).toFixed(1)}%`,
                          },
                          {
                            label: 'Flash assists',
                            value: `${(
                              (analytics.flashAssistsPerPlayer[steamid] /
                                analytics.flashAssistsPerPlayer.all) *
                              100
                            ).toFixed(1)}%`,
                          },
                          {
                            label: 'Utility damage',
                            value: (
                              analytics.utilityDamagePerPlayer[steamid] /
                              totalMatchesOfTeam
                            ).toFixed(2),
                          },
                        ].map(({ label, value }) => (
                          <Box
                            key={label}
                            sx={{
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                          >
                            <Typography
                              variant="subtitle2"
                              color="text.secondary"
                            >
                              {label}
                            </Typography>
                            <Box display="flex" alignItems="center">
                              <Typography
                                variant="subtitle2"
                                fontWeight="bold"
                                sx={{
                                  mr: 0.25,
                                  backgroundColor: getBackgroundColor(
                                    label,
                                    value,
                                  ),
                                  padding: '1px 5px 0px 5px',
                                  borderRadius: '15px',
                                }}
                              >
                                {value}
                              </Typography>
                              {getArrowDirection(label, value, steamid)}
                            </Box>
                          </Box>
                        ))}
                      </Box>
                    )}
                  </Card>
                </Grid>
              ))}
            </Grid>
          )}
        </Box>
        {isBlurred && (
          <BlurredBox text="detailed and individual team statistics features" />
        )}
      </div>
    </Paper>
  );
}

PlayerStats.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  analytics: PropTypes.object,
  isLoadingAnalytics: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  team: PropTypes.object,
  isTeamLoading: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  lastMapStats: PropTypes.arrayOf(PropTypes.object),
};

export default PlayerStats;
