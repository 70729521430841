import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  Box,
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useAuth } from '../../utils/AuthContext.js';
import BlurredBox from '../common/BlurredBox.jsx';
import TooltipTeamName from './TooltipTeamName.jsx';

function MatchInsights({ analytics, isLoadingAnalytics, team }) {
  const [maps, setMaps] = React.useState([]);
  const [orderBy, setOrderBy] = React.useState('');
  const [order, setOrder] = React.useState('desc');
  const [isBlurred, setIsBlurred] = useState(false);
  const [totalStats, setTotalStats] = useState({
    wins: 0,
    losses: 0,
    winRate: 0,
    kd: 0,
    adr: 0,
  });
  const lastClickedHeaderRef = useRef(null);
  const { user } = useAuth();

  // check user status
  useEffect(() => {
    if (!user) {
      return;
    }
    if (!user.verified || user.subscription === 0) {
      setIsBlurred(true);
    } else {
      setIsBlurred(false);
    }
  }, [user]);

  useEffect(() => {
    if (analytics) {
      const mapEntries = Object.entries(analytics.countOfAddedMatches);
      let totalWins = 0;
      let totalLosses = 0;
      let totalKills = 0;
      let totalDeaths = 0;
      let totalAdr = 0;
      let totalCount = 0;

      const mapStats = mapEntries.map(([mapName, count]) => {
        const wins = Number(analytics.wins[mapName]) || 0;
        totalWins += wins;
        const losses = Number(analytics.losses[mapName]) || 0;
        totalLosses += losses;
        const kills = Number(analytics.kills[mapName]) || 0;
        totalKills += kills;
        const deaths = Number(analytics.deaths[mapName]) || 0;
        totalDeaths += deaths;
        const totalAdrMap = Number(analytics.adr[mapName]) || 0;
        totalAdr += totalAdrMap;
        totalCount += Number(count);
        const adr = totalAdrMap / count;
        const kd = deaths > 0 ? (kills / deaths).toFixed(2) : kills.toFixed(2);
        return { mapName, wins, losses, adr, kd };
      });
      setMaps(mapStats);

      const totalKd =
        totalDeaths > 0
          ? (totalKills / totalDeaths).toFixed(2)
          : totalKills.toFixed(2);
      const totalAdrAvg =
        totalCount > 0 ? (totalAdr / totalCount).toFixed(2) : 0;
      const totalWinRate =
        totalCount > 0 ? ((totalWins / totalCount) * 100).toFixed(1) : 0;

      setTotalStats({
        wins: totalWins,
        losses: totalLosses,
        winRate: totalWinRate,
        kd: totalKd,
        adr: totalAdrAvg,
      });
    }
  }, [analytics]);

  const handleRequestSort = (property, event) => {
    const isAsc = orderBy === property && order === 'asc';
    const isDesc = orderBy === property && order === 'desc';

    if (isDesc) {
      setOrderBy('');
      setOrder('desc');
    } else {
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    }
    // Blur the last clicked header
    if (lastClickedHeaderRef.current) {
      lastClickedHeaderRef.current.blur();
    }

    // Set the ref to the currently clicked header
    lastClickedHeaderRef.current = event.currentTarget;
  };

  const sortedMaps = useMemo(() => {
    if (!orderBy) {
      return [...maps].sort((a, b) => a.mapName.localeCompare(b.mapName));
    }

    return [...maps].sort((a, b) => {
      if (orderBy === 'winRate') {
        const aWinRate = a.wins / (a.wins + a.losses);
        const bWinRate = b.wins / (b.wins + b.losses);
        return order === 'asc' ? aWinRate - bWinRate : bWinRate - aWinRate;
      }

      if (a[orderBy] < b[orderBy]) return order === 'asc' ? -1 : 1;
      if (a[orderBy] > b[orderBy]) return order === 'asc' ? 1 : -1;
      return 0;
    });
  }, [maps, order, orderBy]);

  const columnLabels = {
    wins: 'Wins',
    losses: 'Losses',
    winRate: 'Win rate',
    kd: 'K/D',
    adr: 'ADR',
  };

  // Helper function to format map names
  const formatMapName = (mapName) => {
    // Remove 'de_' prefix and capitalize the first letter
    const formattedName = mapName.replace(/^de_/, '');
    return formattedName.charAt(0).toUpperCase() + formattedName.slice(1);
  };

  return (
    <Paper sx={{ padding: 3, mb: 3 }}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ mb: 1 }}
      >
        <Typography variant="h6">Performance</Typography>
        <TooltipTeamName team={team} />
      </Box>
      <div style={{ position: 'relative' }}>
        <Grid
          container
          spacing={2}
          sx={{
            filter: isBlurred ? 'blur(12px)' : 'none',
            pointerEvents: isBlurred ? 'none' : 'auto',
          }}
        >
          <Grid item xs={12}>
            {isLoadingAnalytics && <CircularProgress />}
            {!analytics && !isLoadingAnalytics && (
              <Typography variant="body1" sx={{ mt: 2 }}>
                No data found
              </Typography>
            )}
            {analytics && !isLoadingAnalytics && (
              <Table sx={{ tableLayout: 'fixed', width: '100%' }}>
                <TableHead>
                  <TableRow>
                    <TableCell>Map</TableCell>
                    {['wins', 'losses', 'winRate', 'kd', 'adr'].map(
                      (column) => (
                        <TableCell
                          key={column}
                          sx={{
                            whiteSpace: 'nowrap',
                          }}
                        >
                          <Tooltip
                            placement="top"
                            title={
                              column === 'kd'
                                ? 'Kills/Deaths ratio'
                                : column === 'adr'
                                  ? 'Average Damage per Round'
                                  : ''
                            }
                          >
                            <TableSortLabel
                              active={orderBy === column}
                              direction={orderBy === column ? order : 'asc'}
                              onClick={(event) =>
                                handleRequestSort(column, event)
                              }
                            >
                              {columnLabels[column]}
                            </TableSortLabel>
                          </Tooltip>
                        </TableCell>
                      ),
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortedMaps.map((map) => (
                    <TableRow key={map.mapName}>
                      <TableCell>
                        <Typography variant="subtitle2">
                          {formatMapName(map.mapName)}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="subtitle2">{map.wins}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="subtitle2">
                          {map.losses}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="subtitle2">
                          {map.wins + map.losses > 0
                            ? `${((map.wins / (map.wins + map.losses)) * 100).toFixed(1)}%`
                            : '0%'}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="subtitle2">{map.kd}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="subtitle2">
                          {map.adr.toFixed(2)}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                  {team && team._id !== 0 && (
                    <TableRow
                      sx={{
                        '& > td': {
                          borderTop: '2px solid rgba(224, 224, 224, 1)',
                          borderBottom: 'none',
                        },
                      }}
                    >
                      <TableCell>
                        <Typography variant="subtitle2" fontWeight="bold">
                          Total
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="subtitle2" fontWeight="bold">
                          {totalStats.wins}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="subtitle2" fontWeight="bold">
                          {totalStats.losses}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="subtitle2" fontWeight="bold">
                          {totalStats.winRate}%
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="subtitle2" fontWeight="bold">
                          {totalStats.kd}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="subtitle2" fontWeight="bold">
                          {totalStats.adr}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            )}
          </Grid>
        </Grid>
        {isBlurred && <BlurredBox text="advanced performance statistics" />}
      </div>
    </Paper>
  );
}

MatchInsights.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  analytics: PropTypes.object,
  isLoadingAnalytics: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  team: PropTypes.object,
};

export default MatchInsights;
