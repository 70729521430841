import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as Logo } from '../DefuzeLogoColor.svg';

function ErrorFallbackUI({ error, resetErrorBoundary }) {
  const subject = encodeURIComponent('Bug report');
  const body = encodeURIComponent(
    `Hello Defuze Support,\n\nI have encountered a bug. This is what happened:\n\nError code: ${error.code || 'Unknown'}\nError message: ${error.message}`,
  );
  const mailtoHref = `mailto:support@defuze.gg?subject=${subject}&body=${body}`;

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100vh"
      textAlign="center"
      padding={3}
    >
      <Logo width={400} height={200} />
      <Typography variant="h4" gutterBottom>
        Oops! Something went wrong.
      </Typography>
      <Typography variant="body1" paragraph>
        We&apos;re having trouble loading this page. This is on our side.
      </Typography>
      <Typography variant="body2">
        Error code: {error.code || 'Unknown'}
      </Typography>
      <Typography variant="body2">Error message: {error.message}</Typography>
      <Box mt={2}>
        <Button
          variant="contained"
          color="primary"
          onClick={resetErrorBoundary}
          sx={{ mr: 2 }}
        >
          Try Again
        </Button>
        <Button variant="outlined" href={mailtoHref}>
          Report This Bug
        </Button>
      </Box>
    </Box>
  );
}

ErrorFallbackUI.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  error: PropTypes.object,
  resetErrorBoundary: PropTypes.func,
};

export default ErrorFallbackUI;
