import React, { useEffect, useRef, useState } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Button,
  Box,
  Grid,
  Paper,
  CircularProgress,
  Autocomplete,
  TextField,
  ListItem,
  List,
  Tooltip,
  ListItemText,
  Divider,
  IconButton,
} from '@mui/material';
import { Info, PlayArrow as PlayArrowIcon } from '@mui/icons-material';
import PropTypes from 'prop-types';
import { useAuth } from '../../utils/AuthContext.js';
import axiosInstance from '../../axiosInstance.js';
import NotificationSnackbar from '../common/NotificationSnackbar.jsx';

import teamLogoFaze from '../../TeamLogo_Faze.png';
import teamLogoMouz from '../../TeamLogo_Mouz.png';
import teamLogoNavi from '../../TeamLogo_Navi.png';
import teamLogoSpirit from '../../TeamLogo_Spirit.png';
import teamLogoVitality from '../../TeamLogo_Vitality.png';

function RecentMatches({ matches, isLoadingMatches, team }) {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [options, setOptions] = useState([{ label: 'None', id: '0' }]);
  const [preferredTeam, setPreferredTeam] = useState({
    label: 'None',
    id: '0',
  });
  const autocompleteRef = useRef(null);
  const [teamLogoUrl, setTeamLogoUrl] = useState('');
  const { user, setUser } = useAuth();

  const teamLogos = {
    [['FaZe Clan', 'FaZe', 'Faze']]: teamLogoFaze,
    [['mousesports', 'mouz', 'MOUZ']]: teamLogoMouz,
    [['Natus Vincere', 'NAVI', 'Na`Vi']]: teamLogoNavi,
    [['Team Spirit', 'Spirit']]: teamLogoSpirit,
    [['Team Vitality', 'Vitality']]: teamLogoVitality,
  };

  // update preferred team when options or user.preferredTeam change (after updating)
  useEffect(() => {
    if (!user) {
      return;
    }
    const pT = options.find((t) => t.id === user.preferredTeam);
    setPreferredTeam(pT || { label: 'None', id: '0' });
  }, [options, user.preferredTeam]);

  useEffect(() => {
    if (team && team.name) {
      let logoUrl = '';
      // Create an array of all names including aliases
      const aliases = team.aliases || [];
      const allNames = [team.name, ...aliases];

      // eslint-disable-next-line no-restricted-syntax
      for (const [namesString, logo] of Object.entries(teamLogos)) {
        // Convert the string back to an array
        const names = namesString.split(',');
        if (names.some((name) => allNames.includes(name))) {
          logoUrl = logo;
          break;
        }
      }

      setTeamLogoUrl(logoUrl);
    } else {
      setTeamLogoUrl('');
    }
  }, [team]);

  // get teams on component mount
  useEffect(() => {
    const getTeams = async () => {
      try {
        const response = await axiosInstance.get('/api/teams');
        let tmp = [{ label: 'None', id: '0' }];
        tmp = tmp.concat(
          response.data.map((t) => ({
            label: t.name,

            id: t._id,
          })),
        );
        setOptions(tmp);
      } catch (error) {
        console.error('Failed to fetch teams');
      }
    };

    getTeams().then();
  }, []);

  const handlePlayClick = (match) => {
    const url = `/replay?map=${match._id}`;
    window.open(url, '_blank');
  };

  // Helper function to format map names
  const formatMapName = (mapName) => {
    // Remove 'de_' prefix and capitalize the first letter
    const formattedName = mapName.replace(/^de_/, '');
    return formattedName.charAt(0).toUpperCase() + formattedName.slice(1);
  };

  // Helper function to format dates
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    });
  };

  const handleTeamChange = (event, value) => {
    setPreferredTeam(value);
  };

  const handleSaveClick = async (event, newValue) => {
    if (!newValue || !user) {
      return;
    }
    try {
      // Send request to patch users endpoint
      const userId = user._id;
      const updatedData = {
        preferredTeam: newValue.id,
      };
      if (updatedData.preferredTeam === '0') {
        updatedData.preferredTeam = null;
      }
      const response = await axiosInstance.patch(
        `/api/users/${userId}`,
        updatedData,
      );
      if (
        response.status === 200 &&
        response.data.message === 'User successfully updated'
      ) {
        setUser(response.data.user);
        const pT = options.find((t) => t.id === user.preferredTeam) || {
          label: 'None',
          id: '0',
        };
        setPreferredTeam(pT);
      }
    } catch (error) {
      console.debug('Update user failed');
      setSnackbarMessage('Failed to update preferred team');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  return (
    <>
      <NotificationSnackbar
        open={snackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
        autoHideDuration={4000}
        onClose={() => setSnackbarOpen(false)}
      />
      <Paper
        sx={{
          padding: 3,
          position: 'relative',
          overflow: 'hidden',
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
          '&::before': {
            content: '""',
            position: 'absolute',
            top: '50%',
            left: '50%',
            width: '650px',
            height: '650px',
            backgroundImage: `url(${teamLogoUrl})`,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            transform: 'translate(-50%, -50%) rotate(20deg)',
            opacity: 0.1,
            filter: 'grayscale(100%)',
            pointerEvents: 'none',
            zIndex: 0,
          },
        }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{ mb: 1, position: 'relative', zIndex: 1 }}
        >
          <Typography variant="h6">Recent matches</Typography>
          <Box display="flex" alignItems="center" gap={1}>
            {team && team.aliases.length !== 0 && (
              <Tooltip
                title={
                  <List
                    dense
                    sx={{
                      maxHeight: '300px',
                      overflowY: 'auto',
                      '&::-webkit-scrollbar': { width: '0.4em' },
                      '&::-webkit-scrollbar-thumb': {
                        backgroundColor: 'rgba(0,0,0,.1)',
                      },
                      mb: 1,
                    }}
                  >
                    {team && team.aliases.length > 0 ? (
                      team.aliases.map((alias, index) => (
                        <React.Fragment key={alias}>
                          <ListItem>
                            <ListItemText primary={alias} />
                          </ListItem>
                          {index < team.aliases.length - 1 && (
                            <Divider component="li" />
                          )}
                        </React.Fragment>
                      ))
                    ) : (
                      <ListItem>
                        <ListItemText primary="No aliases" />
                      </ListItem>
                    )}
                  </List>
                }
              >
                <IconButton size="small">
                  <Info sx={{ fontSize: '0.9em' }} />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip
              title={
                !user.verified || user.subscription === 0
                  ? 'Subscribe to select a team'
                  : options.length === 1
                    ? 'No teams available'
                    : ''
              }
              disableHoverListener={
                !(
                  !user.verified ||
                  user.subscription === 0 ||
                  options.length === 1
                )
              }
            >
              <span>
                <Autocomplete
                  ref={autocompleteRef}
                  disabled={
                    !user.verified ||
                    user.subscription === 0 ||
                    options.length === 1
                  }
                  options={options}
                  name="preferredTeam"
                  value={preferredTeam}
                  onChange={(event, newValue) => {
                    handleTeamChange(event, newValue);
                    handleSaveClick(event, newValue).then(() => {
                      requestAnimationFrame(() => {
                        if (autocompleteRef.current) {
                          const input =
                            autocompleteRef.current.querySelector('input');
                          if (input) {
                            input.blur();
                            // Remove focus from the entire Autocomplete component
                            autocompleteRef.current.blur();
                          }
                        }
                        // Move focus to the body
                        // to ensure it's completely removed from the Autocomplete
                        document.body.focus();
                      });
                    });
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  getOptionLabel={(option) => option.label}
                  disableClearable
                  renderInput={(params) => (
                    <TextField
                      /* eslint-disable-next-line react/jsx-props-no-spreading */
                      {...params}
                      label="Team"
                      fullWidth
                      size="small"
                      InputLabelProps={{
                        sx: { fontSize: '0.875rem' },
                      }}
                    />
                  )}
                  sx={{
                    width: '200px',
                    '& .MuiInputBase-root': {
                      fontSize: '0.875rem',
                    },
                    '& .MuiAutocomplete-input': {
                      padding: '4px 8px !important',
                    },
                  }}
                />
              </span>
            </Tooltip>
          </Box>
        </Box>
        <Grid container spacing={2} sx={{ marginTop: 1 }}>
          {isLoadingMatches && (
            <Grid item xs={12} sm={12}>
              <CircularProgress />
            </Grid>
          )}
          {matches.length === 0 && !isLoadingMatches && (
            <Grid item xs={12} sm={12}>
              <Typography variant="body1">No matches found</Typography>
            </Grid>
          )}
          {matches.map((match, index) => {
            const isTeam1Winner = match.scoreTeam1 > match.scoreTeam2;
            const team1 = match.winner.name;
            const team2 = match.loser.name;
            return (
              // eslint-disable-next-line react/no-array-index-key
              <Grid item xs={12} sm={3} key={index}>
                <Card
                  sx={{
                    position: 'relative',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                    '&::before': {
                      content: '""',
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      backgroundColor: 'white',
                      zIndex: 1,
                    },
                    '& > *': {
                      position: 'relative',
                      zIndex: 2,
                    },
                  }}
                >
                  <CardContent sx={{ flex: '1 0 auto' }}>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'baseline',
                        mb: 0.5,
                      }}
                    >
                      <Typography variant="h6" component="span">
                        Score:
                      </Typography>
                      <Typography variant="h6" fontWeight="bold" sx={{ ml: 1 }}>
                        {isTeam1Winner
                          ? `${match.scoreTeam1} - ${match.scoreTeam2}`
                          : `${match.scoreTeam2} - ${match.scoreTeam1}`}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        width: '100%',
                        mb: 0.5,
                        alignItems: 'center',
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: '0.875rem',
                          fontWeight: 'bold',
                          color: 'rgba(76, 175, 80, 1)',
                          maxWidth: '60%',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {team1}
                      </Typography>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          flexShrink: 0,
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: '0.875rem',
                            px: 1,
                          }}
                        >
                          -
                        </Typography>
                      </Box>
                      <Typography
                        sx={{
                          fontSize: '0.875rem',
                          fontWeight: 'normal',
                          color: '#B22222',
                          maxWidth: '60%',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          textAlign: 'right',
                        }}
                      >
                        {team2}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'flex-start',
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'baseline',
                          }}
                        >
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            sx={{ fontSize: '0.75em', mr: 0.5 }}
                          >
                            played on
                          </Typography>
                          <Typography
                            color="textSecondary"
                            sx={{ fontWeight: 'bold' }}
                          >
                            {formatMapName(match.mapName)}
                          </Typography>
                        </Box>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          sx={{ fontSize: '0.75em', mt: 0.5 }}
                        >
                          {formatDate(match.uploadedDate)}
                        </Typography>
                      </Box>
                      <Button
                        variant="contained"
                        startIcon={<PlayArrowIcon />}
                        onClick={() => handlePlayClick(match)}
                        sx={{ mt: 0.75 }}
                      >
                        Play
                      </Button>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </Paper>
    </>
  );
}

RecentMatches.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  matches: PropTypes.arrayOf(PropTypes.object),
  isLoadingMatches: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  team: PropTypes.object,
};

export default RecentMatches;
