import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  Add,
  Autorenew,
  Delete,
  Info as InfoIcon,
  Save,
} from '@mui/icons-material';
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import axiosInstance from '../../axiosInstance.js';
import OutcomeFilter from './OutcomeFilter.jsx';
import NotificationSnackbar from '../common/NotificationSnackbar.jsx';
import BlurredBox from '../common/BlurredBox.jsx';
import { useAuth } from '../../utils/AuthContext.js';
import WinnerFilter from './WinnerFilter.jsx';
import AiFilter from './AiFilter.jsx';
import MoneyFilter from './MoneyFilter.jsx';

const presetFilters = [
  {
    _id: 'pistol-rounds',
    name: 'Pistol rounds',
    sliderFilter: {
      deviation: [0, 100],
      team1Start: [800, 800],
      team2Start: [800, 800],
      team1Spent: [0, 800],
      team2Spent: [0, 800],
      team1EquipStart: [0, 2000],
      team2EquipStart: [0, 2000],
      team1EquipFreeze: [0, 2000],
      team2EquipFreeze: [0, 2000],
    },
    checkedValues: {
      bombExploded: false,
      bombDefused: false,
      noTime: false,
      tElimination: false,
      cTElimination: false,
    },
    roundStatusRadio: 'all',
    deviationSwitch: false,
  },
  {
    _id: 'unexpected-win',
    name: 'Unexpected win',
    sliderFilter: {
      deviation: [75, 100],
      team1Start: [0, 16000],
      team2Start: [0, 16000],
      team1Spent: [0, 16000],
      team2Spent: [0, 16000],
      team1EquipStart: [0, 8000],
      team2EquipStart: [0, 8000],
      team1EquipFreeze: [0, 8000],
      team2EquipFreeze: [0, 8000],
    },
    checkedValues: {
      bombExploded: false,
      bombDefused: false,
      noTime: false,
      tElimination: false,
      cTElimination: false,
    },
    roundStatusRadio: 'team1',
    deviationSwitch: false,
  },
  {
    _id: 'unexpected-loss',
    name: 'Unexpected loss',
    sliderFilter: {
      deviation: [75, 100],
      team1Start: [0, 16000],
      team2Start: [0, 16000],
      team1Spent: [0, 16000],
      team2Spent: [0, 16000],
      team1EquipStart: [0, 8000],
      team2EquipStart: [0, 8000],
      team1EquipFreeze: [0, 8000],
      team2EquipFreeze: [0, 8000],
    },
    checkedValues: {
      bombExploded: false,
      bombDefused: false,
      noTime: false,
      tElimination: false,
      cTElimination: false,
    },
    roundStatusRadio: 'team2',
    deviationSwitch: false,
  },
  {
    _id: 'buy-vs-buy',
    name: 'Buy vs. buy',
    sliderFilter: {
      deviation: [0, 100],
      team1Start: [1000, 16000],
      team2Start: [1000, 16000],
      team1Spent: [500, 16000],
      team2Spent: [500, 16000],
      team1EquipStart: [0, 8000],
      team2EquipStart: [0, 8000],
      team1EquipFreeze: [4000, 8000],
      team2EquipFreeze: [4000, 8000],
    },
    checkedValues: {
      bombExploded: false,
      bombDefused: false,
      noTime: false,
      tElimination: false,
      cTElimination: false,
    },
    roundStatusRadio: 'all',
    deviationSwitch: false,
  },
  {
    _id: 'won-eco-round',
    name: 'Eco round wins',
    sliderFilter: {
      deviation: [0, 100],
      team1Start: [0, 3000],
      team2Start: [0, 16000],
      team1Spent: [0, 2000],
      team2Spent: [500, 16000],
      team1EquipStart: [0, 1500],
      team2EquipStart: [0, 8000],
      team1EquipFreeze: [0, 1000],
      team2EquipFreeze: [2000, 8000],
    },
    checkedValues: {
      bombExploded: false,
      bombDefused: false,
      noTime: false,
      tElimination: false,
      cTElimination: false,
    },
    roundStatusRadio: 'team1',
    deviationSwitch: false,
  },
  {
    _id: 'won-force-buy',
    name: 'Force buy wins',
    sliderFilter: {
      deviation: [0, 100],
      team1Start: [1000, 4500],
      team2Start: [0, 16000],
      team1Spent: [800, 4000],
      team2Spent: [500, 16000],
      team1EquipStart: [0, 3000],
      team2EquipStart: [0, 8000],
      team1EquipFreeze: [0, 3500],
      team2EquipFreeze: [2000, 8000],
    },
    checkedValues: {
      bombExploded: false,
      bombDefused: false,
      noTime: false,
      tElimination: false,
      cTElimination: false,
    },
    roundStatusRadio: 'team1',
    deviationSwitch: false,
  },
];

function MatchFilter({
  sliderFilter,
  setSliderFilter,
  team1Filter,
  setTeam1Filter,
  team2Filter,
  setTeam2Filter,
  mapFilter,
  setMapFilter,
  tabSelection,
  setTabSelection,
  checkedValues,
  setCheckedValues,
  roundStatusRadio,
  setRoundStatusRadio,
  deviationSwitch,
  setDeviationSwitch,
}) {
  // Initial default values for sliders
  const defaultSliderValues = {
    deviation: [0, 100],
    team1Start: [0, 16000],
    team2Start: [0, 16000],
    team1Spent: [0, 16000],
    team2Spent: [0, 16000],
    team1EquipStart: [0, 8000],
    team2EquipStart: [0, 8000],
    team1EquipFreeze: [0, 8000],
    team2EquipFreeze: [0, 8000],
  };
  // State to hold the current values of sliders
  const [sliderValues, setSliderValues] = useState(() => {
    const storedValues = JSON.parse(sessionStorage.getItem('sliderFilter'));
    return storedValues || defaultSliderValues;
  });
  // State to manage the position and visibility of the save menu
  const [anchorElementSave, setAnchorElementSave] = useState(null);
  // State to manage the value of the save filter input field
  const [filterName, setFilterName] = useState('');
  // State to manage the load filter combination field
  const [filterList, setFilterList] = useState([
    { _id: 'custom', name: 'Custom' },
    ...presetFilters,
  ]);
  const [selectedFilter, setSelectedFilter] = useState('custom');
  const [mouseUp, setMouseUp] = useState(false);
  // Notification Snackbar state
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  // ref to anchor of context menu
  const anchorRef = useRef(null);
  // State for blurr
  const [isBlurred, setIsBlurred] = useState(false);
  // fetch trigger
  const [fetchTrigger, setFetchTrigger] = useState(false);
  const { user } = useAuth();

  // store slider values in session storage
  useEffect(() => {
    sessionStorage.setItem('sliderFilter', JSON.stringify(sliderValues));
  }, [sliderValues]);

  // Update the sliderFilter state when slider values change or switches are toggled
  useEffect(() => {
    setSliderFilter({
      ...sliderValues,
    });
  }, [mouseUp]);

  // Fetch the list of saved filters
  useEffect(() => {
    axiosInstance
      .get('/api/filters')
      .then((response) => {
        const updatedFilterList = [
          { _id: 'custom', name: 'Custom' },
          ...presetFilters,
          ...response.data.filter((filter) => filter._id !== 'custom'),
        ];
        setFilterList(updatedFilterList);
      })
      .catch(() => {
        console.debug('Failed to fetch filters');
      });
  }, [fetchTrigger]);

  // check if values change from selected filter
  useEffect(() => {
    if (selectedFilter === 'custom') {
      return;
    }

    const selectedFilterData = filterList.find(
      (filter) => filter._id === selectedFilter,
    );
    if (!selectedFilterData) {
      return;
    }

    const isEqual = (obj1, obj2) =>
      JSON.stringify(obj1) === JSON.stringify(obj2);

    if (presetFilters.find((filter) => filter._id === selectedFilter)) {
      if (selectedFilter === 'pistol-rounds') {
        const filtersMatch =
          isEqual(selectedFilterData.sliderFilter, sliderFilter) &&
          isEqual(selectedFilterData.checkedValues, checkedValues) &&
          selectedFilterData.deviationSwitch === deviationSwitch;

        if (!filtersMatch) {
          setSelectedFilter('custom');
        }
        return;
      }
      const filtersMatch =
        isEqual(selectedFilterData.sliderFilter, sliderFilter) &&
        isEqual(selectedFilterData.checkedValues, checkedValues) &&
        selectedFilterData.roundStatusRadio === roundStatusRadio &&
        selectedFilterData.deviationSwitch === deviationSwitch;

      if (!filtersMatch) {
        setSelectedFilter('custom');
      }
      return;
    }
    const filtersMatch =
      isEqual(selectedFilterData.sliderFilter, sliderFilter) &&
      selectedFilterData.team1 === team1Filter &&
      selectedFilterData.team2 === team2Filter &&
      selectedFilterData.map === mapFilter &&
      selectedFilterData.tabSelection === tabSelection &&
      isEqual(selectedFilterData.checkedValues, checkedValues) &&
      selectedFilterData.roundStatusRadio === roundStatusRadio &&
      selectedFilterData.deviationSwitch === deviationSwitch;

    if (!filtersMatch) {
      setSelectedFilter('custom');
    }
  }, [
    mapFilter,
    team1Filter,
    team2Filter,
    tabSelection,
    sliderFilter,
    checkedValues,
    roundStatusRadio,
    deviationSwitch,
  ]);

  // check user status
  useEffect(() => {
    if (!user) {
      return;
    }
    if (!user.verified || user.subscription === 0) {
      setIsBlurred(true);
    } else {
      setIsBlurred(false);
    }
  }, [user]);

  // Function to reset slider values to default
  const resetFilters = () => {
    setSliderValues(defaultSliderValues);
    setRoundStatusRadio('all');
    setCheckedValues({
      bombExploded: false,
      bombDefused: false,
      noTime: false,
      tElimination: false,
      cTElimination: false,
    });
    setDeviationSwitch(false);
    // to trigger refresh of matches
    setMouseUp(!mouseUp);
  };

  // Function to handle saving filter combination
  const handleSaveFilter = () => {
    if (!filterName) {
      setSnackbarMessage('Please enter a filter name');
      setSnackbarOpen(true);
      setSnackbarSeverity('error');
      return;
    }
    if (filterName === 'Custom') {
      setSnackbarMessage('Filter name cannot be "Custom"');
      setSnackbarOpen(true);
      setSnackbarSeverity('error');
      return;
    }
    // test if filter name is char only
    if (!/^[0-9a-zA-Z-_ ]+$/.test(filterName)) {
      setSnackbarMessage('Filter name can only contain letters and numbers');
      setSnackbarOpen(true);
      setSnackbarSeverity('error');
      return;
    }
    if (filterName.length > 25) {
      setSnackbarMessage('Filter name cannot be longer than 25 characters');
      setSnackbarOpen(true);
      setSnackbarSeverity('error');
      return;
    }
    axiosInstance
      .post('/api/filters', {
        name: filterName,
        sliderFilter,
        team1Filter,
        team2Filter,
        mapFilter,
        tabSelection,
        checkedValues,
        roundStatusRadio,
        deviationSwitch,
      })
      .then((response) => {
        if (
          response.status === 201 &&
          response.data.message === 'Filter saved'
        ) {
          console.debug('Filter saved');
          setSelectedFilter(response.data.id);
          setSnackbarMessage(`Filter ${filterName} successfully saved`);
          setSnackbarOpen(true);
          setSnackbarSeverity('success');
          setFilterName('');
          setAnchorElementSave(null);

          setFetchTrigger(!fetchTrigger);
        }
      })
      .catch(() => {
        console.error('Failed to save filter');
        setSnackbarMessage('Failed to save filter');
        setSnackbarOpen(true);
        setSnackbarSeverity('error');
      });
  };

  const handleLoadFilter = (filter) => {
    if (!filter || filter._id === 'custom') {
      return;
    }
    // set filter values
    setSliderFilter(filter.sliderFilter || sliderFilter);
    setRoundStatusRadio(filter.roundStatusRadio || roundStatusRadio);
    setSliderValues({
      deviation: filter.sliderFilter.deviation || sliderValues.deviation,
      team1Start: filter.sliderFilter.team1Start || sliderValues.team1Start,
      team2Start: filter.sliderFilter.team2Start || sliderValues.team2Start,
      team1Spent: filter.sliderFilter.team1Spent || sliderValues.team1Spent,
      team2Spent: filter.sliderFilter.team2Spent || sliderValues.team2Spent,
      team1EquipStart:
        filter.sliderFilter.team1EquipStart || sliderValues.team1EquipStart,
      team2EquipStart:
        filter.sliderFilter.team2EquipStart || sliderValues.team2EquipStart,
      team1EquipFreeze:
        filter.sliderFilter.team1EquipFreeze || sliderValues.team1EquipFreeze,
      team2EquipFreeze:
        filter.sliderFilter.team2EquipFreeze || sliderValues.team2EquipFreeze,
    });
    // set selection values
    setTeam1Filter(filter.team1 || team1Filter);
    setTeam2Filter(filter.team2 || team2Filter);
    setMapFilter(filter.map || mapFilter);
    setTabSelection(filter.tabSelection || tabSelection);
    // set checked values
    setCheckedValues({
      bombExploded: filter.checkedValues.bombExploded,
      bombDefused: filter.checkedValues.bombDefused,
      noTime: filter.checkedValues.noTime,
      tElimination: filter.checkedValues.tElimination,
      cTElimination: filter.checkedValues.cTElimination,
    });
    setDeviationSwitch(filter.deviationSwitch);

    console.debug('Filter loaded');
  };

  const handleDeleteFilter = () => {
    const { name } = filterList.find((f) => f._id === selectedFilter);
    axiosInstance.delete(`/api/filters/${selectedFilter}`).then((response) => {
      if (
        response.status === 200 &&
        response.data.message === 'Filter deleted'
      ) {
        console.debug('Filter deleted');
        setSnackbarMessage(`Filter ${name} successfully deleted`);
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
        setSelectedFilter('custom');

        setFetchTrigger(!fetchTrigger);
      }
    });
  };

  const handleSaveButtonClick = () => {
    setAnchorElementSave(anchorRef.current);
  };

  const handleClose = () => {
    setAnchorElementSave(null);
  };

  const isPresetFilter = (filterId) =>
    presetFilters.some((filter) => filter._id === filterId);

  return (
    <>
      <NotificationSnackbar
        message={snackbarMessage}
        onClose={() => setSnackbarOpen(false)}
        open={snackbarOpen}
        autoHideDuration={3000}
        severity={snackbarSeverity}
      />
      {/* Save filter menu */}
      <Menu
        anchorEl={anchorElementSave}
        open={Boolean(anchorElementSave)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Grid container item xs={12} sx={{ padding: 1 }}>
          <Grid item xs={10}>
            <TextField
              label="Filter name"
              value={filterName}
              size="small"
              onKeyDown={(e) => {
                // Prevent default behavior for all keys except Tab
                if (e.key !== 'Tab') {
                  e.stopPropagation();
                }
                // Prevent menu from closing on Enter key but save on Enter
                if (e.key === 'Enter') {
                  e.preventDefault();
                  handleSaveFilter();
                }
              }}
              onChange={(e) => setFilterName(e.target.value)}
              fullWidth
              sx={{ ml: 1 }}
            />
          </Grid>
          <Grid item xs={2} container justifyContent="flex-end">
            <IconButton onClick={handleSaveFilter}>
              <Save />
            </IconButton>
          </Grid>
        </Grid>
      </Menu>
      <Paper elevation={3} sx={{ padding: 3 }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box display="flex" alignItems="center">
            <Typography variant="h6">Round filter</Typography>
            <Tooltip
              title="If no team is selected, the filter applies to both the
                    winner and loser.
                    If one team is selected, the other is automatically set to
                    their opponent. Click 'Play' to load rounds that match the filter."
            >
              <IconButton>
                <InfoIcon />
              </IconButton>
            </Tooltip>
          </Box>
          <Tooltip
            title={
              isBlurred ? 'Subscribe to save your custom filter settings' : ''
            }
            disableHoverListener={!isBlurred}
          >
            <Box display="flex" alignItems="center" sx={{ width: '50%' }}>
              {selectedFilter !== 'custom' ? (
                <Tooltip
                  title={
                    !isBlurred
                      ? isPresetFilter(selectedFilter)
                        ? 'Cannot delete preset filters'
                        : 'Delete this filter'
                      : ''
                  }
                  disableHoverListener={isBlurred}
                >
                  <span>
                    <IconButton
                      disabled={isBlurred || isPresetFilter(selectedFilter)}
                      onClick={handleDeleteFilter}
                    >
                      <Delete />
                    </IconButton>
                  </span>
                </Tooltip>
              ) : (
                <Tooltip
                  title={!isBlurred ? 'Add a new filter' : ''}
                  disableHoverListener={isBlurred}
                >
                  <IconButton
                    disabled={isBlurred}
                    onClick={handleSaveButtonClick}
                  >
                    <Add />
                  </IconButton>
                </Tooltip>
              )}
              <Select
                disabled={isBlurred}
                value={selectedFilter}
                fullWidth
                size="small"
                onChange={(e) => {
                  setSelectedFilter(e.target.value);
                  handleLoadFilter(
                    filterList.find((f) => f._id === e.target.value),
                  );
                }}
                sx={{
                  '& .MuiSelect-select': {
                    padding: '4px 14px',
                    fontSize: '0.875rem',
                    maxWidth: '75px',
                  },
                }}
              >
                {filterList.map((filter) => (
                  <MenuItem
                    key={filter.name}
                    value={filter._id}
                    sx={{ fontSize: '0.875rem' }}
                  >
                    {filter.name}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Tooltip>
        </Box>

        <div style={{ position: 'relative' }}>
          <Grid
            container
            spacing={1.5}
            sx={{
              marginTop: 0,
              filter: isBlurred ? 'blur(12px)' : 'none',
              pointerEvents: isBlurred ? 'none' : 'auto',
            }}
            ref={anchorRef}
          >
            {/* round result selection */}
            <Grid item xs={12}>
              <WinnerFilter
                team1Filter={team1Filter}
                team2Filter={team2Filter}
                roundStatusRadio={roundStatusRadio}
                setRoundStatusRadio={setRoundStatusRadio}
              />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>

            {/* AI */}
            <Grid item xs={12}>
              <AiFilter
                sliderValues={sliderValues}
                deviationSwitch={deviationSwitch}
                setDeviationSwitch={setDeviationSwitch}
                setSliderValues={setSliderValues}
                mouseUp={mouseUp}
                setMouseUp={setMouseUp}
              />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>

            {/* money slider */}
            <Grid item xs={12}>
              <MoneyFilter
                mouseUp={mouseUp}
                sliderValues={sliderValues}
                setMouseUp={setMouseUp}
                setSliderValues={setSliderValues}
                team1Filter={team1Filter}
                team2Filter={team2Filter}
              />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>

            {/* additional filter section */}
            <Grid item xs={12}>
              <OutcomeFilter
                checkedValues={checkedValues}
                setCheckedValues={setCheckedValues}
              />
            </Grid>

            {/* Reset Filter Button */}
            <Grid item xs={12}>
              <Button
                fullWidth
                onClick={resetFilters}
                startIcon={<Autorenew />}
              >
                Reset filter
              </Button>
            </Grid>
          </Grid>
          {isBlurred && <BlurredBox text="advanced filtering features" />}
        </div>
      </Paper>
    </>
  );
}

MatchFilter.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  sliderFilter: PropTypes.object.isRequired,
  setSliderFilter: PropTypes.func.isRequired,
  team1Filter: PropTypes.string.isRequired,
  setTeam1Filter: PropTypes.func.isRequired,
  team2Filter: PropTypes.string.isRequired,
  setTeam2Filter: PropTypes.func.isRequired,
  mapFilter: PropTypes.string.isRequired,
  setMapFilter: PropTypes.func.isRequired,
  tabSelection: PropTypes.string.isRequired,
  setTabSelection: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  checkedValues: PropTypes.object.isRequired,
  setCheckedValues: PropTypes.func.isRequired,
  roundStatusRadio: PropTypes.string.isRequired,
  setRoundStatusRadio: PropTypes.func.isRequired,
  deviationSwitch: PropTypes.bool.isRequired,
  setDeviationSwitch: PropTypes.func.isRequired,
};

export default MatchFilter;
