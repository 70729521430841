import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { EditNote, NoteAdd, Share } from '@mui/icons-material';
import {
  Box,
  Button,
  Divider,
  Typography,
  Tooltip,
  IconButton,
} from '@mui/material';
import PropTypes from 'prop-types';
import axiosInstance from '../../axiosInstance.js';
import { useAuth } from '../../utils/AuthContext.js';
import { useMatchData } from '../../utils/MatchDataContext.js';
import NoteCard from './NoteCard.jsx';
import ShareDialog from './ShareDialog.jsx';

function NoteCardEditor({ shareCode, note, setNote }) {
  const [sortedNotes, setSortedNotes] = useState([]);
  const { user } = useAuth();
  const { currentTickIndex, currentRoundData, mapID, deleteDrawing } =
    useMatchData();
  const [editingTickIndex, setEditingTickIndex] = useState(null);
  const [sharePopupOpen, setSharePopupOpen] = useState(false);
  // reference to the scroll box
  const scrollContainerRef = useRef(null);
  const [containerHeight, setContainerHeight] = useState(800);

  // sort notes by tick index
  useEffect(() => {
    if (note && note.content) {
      const sorted = Object.entries(note.content)
        .map(([tickIndex, content]) => ({
          tickIndex: Number(tickIndex),
          content,
        }))
        .sort((a, b) => a.tickIndex - b.tickIndex);
      setSortedNotes(sorted);
    } else {
      setSortedNotes([]);
    }
  }, [note]);

  // update container height on resize
  useLayoutEffect(() => {
    const updateContainerHeight = () => {
      if (scrollContainerRef.current) {
        const drawerPadding = 20; // Adjust this value based on your drawer's padding
        const availableHeight =
          window.innerHeight -
          scrollContainerRef.current.offsetTop -
          drawerPadding;
        setContainerHeight(availableHeight);
      }
    };

    updateContainerHeight();
    window.addEventListener('resize', updateContainerHeight);

    return () => window.removeEventListener('resize', updateContainerHeight);
  }, []);

  // calculate position of highlighted card
  useEffect(() => {
    if (scrollContainerRef.current) {
      const highlightedCard =
        scrollContainerRef.current.querySelector('.highlighted-card');
      if (highlightedCard) {
        const cardPosition = highlightedCard.offsetTop;
        const cardHeight = highlightedCard.clientHeight;
        const scrollPosition =
          cardPosition - containerHeight / 1.75 + cardHeight / 2;

        scrollContainerRef.current.scrollTo({
          top: scrollPosition,
          behavior: 'smooth',
        });
      }
    }
  }, [currentTickIndex, sortedNotes, containerHeight]);

  const addNewNote = async () => {
    try {
      if (!note) {
        console.debug('Creating new note');
        const newNote = {
          content: { [currentTickIndex]: '' },
          drawings: { [currentTickIndex]: [] },
          user: user._id,
          round: currentRoundData._id,
          map: mapID,
        };
        const response = await axiosInstance.post('/api/notes', {
          note: newNote,
        });
        if (response.status === 201) {
          setNote(response.data.note);
        } else {
          console.error('Error saving note:', response);
        }
      } else if (!note.content[currentTickIndex]) {
        setNote((prevNote) => {
          const updatedContent = {
            ...prevNote.content,
            [currentTickIndex]: '',
          };
          return {
            ...prevNote,
            content: updatedContent,
          };
        });
      }
      if (note && !note.drawings[currentTickIndex]) {
        setNote((prevNote) => {
          const updatedDrawings = { ...note.drawings, [currentTickIndex]: [] };
          return {
            ...prevNote,
            drawings: updatedDrawings,
          };
        });
      }
      setEditingTickIndex(currentTickIndex);
    } catch (error) {
      console.error('Error adding note');
    }
  };

  const handleSave = async (tickIndex, content, drawings) => {
    try {
      const updatedContent = { ...note.content, [tickIndex]: content };
      const updatedDrawings = { ...note.drawings, [tickIndex]: drawings };
      const response = await axiosInstance.put(`/api/notes/${note._id}`, {
        note: { content: updatedContent, drawings: updatedDrawings },
      });
      if (response.status === 200) {
        setNote(response.data);
        setEditingTickIndex(null);
      }
    } catch (error) {
      console.error('Error updating note:', error);
    }
  };

  const handleDelete = async (tickIndex) => {
    console.debug('Deleting note with tick:', tickIndex);
    try {
      if (note) {
        const updatedContent = { ...note.content };
        delete updatedContent[tickIndex];
        const updatedDrawings = { ...note.drawings };
        delete updatedDrawings[tickIndex];
        const response = await axiosInstance.put(`/api/notes/${note._id}`, {
          note: { content: updatedContent, drawings: updatedDrawings },
        });
        if (response.status === 200) {
          setNote(response.data);
          deleteDrawing(tickIndex);
          if (
            Object.keys(response.data.content).length === 0 &&
            Object.keys(response.data.content).length === 0
          ) {
            console.debug('Note is empty, deleting note:', note._id);
            axiosInstance
              .delete(`/api/notes/${note._id}`)
              .then(() => {
                console.debug('Note deleted successfully');
                setNote(null);
              })
              .catch(() => {
                console.error('Error deleting note');
              });
          }
        }
      }
    } catch (error) {
      console.error('Error deleting note');
    }
  };

  return (
    <>
      <ShareDialog
        setSharePopupOpen={setSharePopupOpen}
        sharePopupOpen={sharePopupOpen}
        note={note}
        shareCode={shareCode}
      />
      <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
          {note &&
          (note.content || note.drawings) &&
          (note.content[currentTickIndex] ||
            note.drawings[currentTickIndex]) ? (
            <Tooltip title="Edit note for current timestamp">
              <Button
                startIcon={<EditNote />}
                onClick={() => setEditingTickIndex(currentTickIndex)}
              >
                Edit Note
              </Button>
            </Tooltip>
          ) : (
            <Tooltip title="Add note for current timestamp" placement="left">
              <Button startIcon={<NoteAdd />} onClick={addNewNote}>
                Add Note
              </Button>
            </Tooltip>
          )}
          <Tooltip title="Share match" placement="left">
            <IconButton onClick={() => setSharePopupOpen(true)} color="primary">
              <Share />
            </IconButton>
          </Tooltip>
        </Box>
        <Divider sx={{ mb: 1 }} />
        <Box
          ref={scrollContainerRef}
          sx={{
            flexGrow: 1,
            overflowY: 'auto',
            height: containerHeight,
            padding: 0.75,
          }}
        >
          {sortedNotes.length === 0 ? (
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Typography variant="subtitle2" sx={{ mb: 2 }}>
                No notes for this round
              </Typography>
            </Box>
          ) : (
            sortedNotes.map(({ tickIndex, content }) => (
              <NoteCard
                key={tickIndex}
                tickIndex={tickIndex}
                content={content}
                onSave={handleSave}
                onDelete={handleDelete}
                editingTickIndex={editingTickIndex}
                drawingsSaved={note.drawings[tickIndex]}
                className={
                  currentTickIndex === tickIndex ? 'highlighted-card' : ''
                }
              />
            ))
          )}
        </Box>
      </Box>
    </>
  );
}

NoteCardEditor.propTypes = {
  shareCode: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  note: PropTypes.object,
  setNote: PropTypes.func.isRequired,
};

export default NoteCardEditor;
