import {
  Close,
  ContentCopy,
  Info as InfoIcon,
  Link as LinkIcon,
  CheckCircleOutlined as Check,
} from '@mui/icons-material';
import {
  Box,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import axiosInstance from '../../axiosInstance.js';
import { useMatchData } from '../../utils/MatchDataContext.js';

function ShareDialog({ sharePopupOpen, setSharePopupOpen, note, shareCode }) {
  const [copySuccessRound, setCopySuccessRound] = useState(false);
  const [copySuccessMap, setCopySuccessMap] = useState(false);
  const [copySuccessNoNote, setCopySuccessNoNote] = useState(false);
  const [mapShareCode, setMapShareCode] = useState(null);
  const [fetchedMapShareCode, setFetchedMapShareCode] = useState(false);
  const [includeReplayState, setIncludeReplayState] = useState(false);

  const { currentTickIndex, currentRoundNumber } = useMatchData();
  const url = new URL(window.location.href);
  const urlParams = url.searchParams;

  useEffect(() => {
    if (!note || fetchedMapShareCode) return;
    try {
      setFetchedMapShareCode(true);
      // Fetch map share code
      axiosInstance
        .post('/api/notes/map-share', {
          map: note.map,
          user: note.user,
          shareCode,
        })
        .then((response) => {
          console.debug('Successfully fetched map share code', response.data);
          setMapShareCode(response.data.shareCode);
        })
        .catch(() => {
          console.error('Error fetching map share code');
        });
    } catch (error) {
      console.error('Error fetching map share code');
    }
  }, [note]);

  const updateUrlParameters = (newParams) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(newParams)) {
      if (value !== null) {
        urlParams.set(key, value);
      } else {
        urlParams.delete(key);
      }
    }

    return `${url.origin}${url.pathname}?${urlParams.toString()}`;
  };

  const handleCopyToClipboardRound = () => {
    let updatedUrl = includeReplayState
      ? updateUrlParameters({
          currentRound: currentRoundNumber,
          currentTick: currentTickIndex,
        })
      : updateUrlParameters({
          currentRound: null,
          currentTick: null,
        });
    updatedUrl += `&shareCode=${note.shareCode}`;
    navigator.clipboard.writeText(updatedUrl).then(() => {
      setCopySuccessRound(true);
      setTimeout(() => setCopySuccessRound(false), 1500);
    });
  };

  const handleCopyToClipboardMap = () => {
    let updatedUrl = includeReplayState
      ? updateUrlParameters({
          currentRound: currentRoundNumber,
          currentTick: currentTickIndex,
        })
      : updateUrlParameters({
          currentRound: null,
          currentTick: null,
        });
    updatedUrl += `&shareCode=${mapShareCode}`;
    navigator.clipboard.writeText(updatedUrl).then(() => {
      setCopySuccessMap(true);
      setTimeout(() => setCopySuccessMap(false), 1500);
    });
  };

  const handleCopyToClipboardNoNotes = () => {
    const updatedUrl = includeReplayState
      ? updateUrlParameters({
          currentRound: currentRoundNumber,
          currentTick: currentTickIndex,
        })
      : updateUrlParameters({
          currentRound: null,
          currentTick: null,
        });
    navigator.clipboard.writeText(updatedUrl).then(() => {
      setCopySuccessNoNote(true);
      setTimeout(() => setCopySuccessNoNote(false), 1500);
    });
  };

  return (
    <Dialog open={sharePopupOpen} onClose={() => setSharePopupOpen(false)}>
      <DialogTitle>Share match</DialogTitle>
      <DialogContent sx={{ width: '598px' }}>
        <IconButton
          aria-label="close"
          onClick={() => setSharePopupOpen(false)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <Close />
        </IconButton>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <LinkIcon color="primary" />
          <Typography variant="body1" sx={{ ml: 1 }}>
            Match only
          </Typography>
          <Tooltip title="Any user with the link can view" placement="right">
            <IconButton size="small">
              <InfoIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            padding: '4px',
            marginTop: '4px',
            border: `${copySuccessNoNote ? '1px solid #4caf50' : '1px solid #ccc'}`,
            borderRadius: '4px',
            backgroundColor: '#f9f9f9',
            cursor: 'pointer',
            overflowX: 'auto',
            whiteSpace: 'nowrap',
            scrollbarWidth: 'none', // For Firefox
            '&::-webkit-scrollbar': {
              display: 'none', // For Chrome, Safari, and Opera
            },
          }}
          onClick={handleCopyToClipboardNoNotes}
        >
          {!includeReplayState ? (
            <>
              {copySuccessNoNote ? (
                <Check sx={{ ml: 1, mr: 1 }} fontSize="small" color="success" />
              ) : (
                <ContentCopy sx={{ ml: 1, mr: 1 }} fontSize="small" />
              )}
              {`${updateUrlParameters({
                currentRound: null,
                currentTick: null,
              })}`}
            </>
          ) : (
            <>
              {copySuccessNoNote ? (
                <Check sx={{ ml: 1, mr: 1 }} fontSize="small" color="success" />
              ) : (
                <ContentCopy sx={{ ml: 1, mr: 1 }} fontSize="small" />
              )}
              {`${updateUrlParameters({
                currentRound: currentRoundNumber,
                currentTick: currentTickIndex,
              })}`}
            </>
          )}
        </Box>
        {note ? (
          <>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                mt: 2,
              }}
            >
              <LinkIcon color="primary" />
              <Typography variant="body1" sx={{ ml: 1 }}>
                Match with current round notes
              </Typography>
              <Tooltip
                title="Any user with the link can edit"
                placement="right"
              >
                <IconButton size="small">
                  <InfoIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                padding: '4px',
                marginTop: '4px',
                border: `${copySuccessRound ? '1px solid #4caf50' : '1px solid #ccc'}`,
                borderRadius: '4px',
                backgroundColor: '#f9f9f9',
                cursor: 'pointer',
                overflowX: 'auto',
                whiteSpace: 'nowrap',
                scrollbarWidth: 'none', // For Firefox
                '&::-webkit-scrollbar': {
                  display: 'none', // For Chrome, Safari, and Opera
                },
              }}
              onClick={handleCopyToClipboardRound}
            >
              {!includeReplayState ? (
                <>
                  {copySuccessRound ? (
                    <Check
                      sx={{ ml: 1, mr: 1 }}
                      fontSize="small"
                      color="success"
                    />
                  ) : (
                    <ContentCopy sx={{ ml: 1, mr: 1 }} fontSize="small" />
                  )}
                  {`${updateUrlParameters({
                    currentRound: null,
                    currentTick: null,
                  })}&shareCode=${note.shareCode}`}
                </>
              ) : (
                <>
                  {copySuccessRound ? (
                    <Check
                      sx={{ ml: 1, mr: 1 }}
                      fontSize="small"
                      color="success"
                    />
                  ) : (
                    <ContentCopy sx={{ ml: 1, mr: 1 }} fontSize="small" />
                  )}
                  {`${updateUrlParameters({
                    currentRound: currentRoundNumber,
                    currentTick: currentTickIndex,
                  })}&shareCode=${note.shareCode}`}
                </>
              )}
            </Box>
            {mapShareCode && (
              <>
                <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                  <LinkIcon color="primary" />
                  <Typography variant="body1" sx={{ ml: 1 }}>
                    Match with all notes
                  </Typography>
                  <Tooltip
                    title="Any user with the link can edit"
                    placement="right"
                  >
                    <IconButton size="small">
                      <InfoIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: '4px',
                    marginTop: '4px',
                    border: `${copySuccessMap ? '1px solid #4caf50' : '1px solid #ccc'}`,
                    borderRadius: '4px',
                    backgroundColor: '#f9f9f9',
                    cursor: 'pointer',
                    overflowX: 'auto',
                    whiteSpace: 'nowrap',
                    scrollbarWidth: 'none', // For Firefox
                    '&::-webkit-scrollbar': {
                      display: 'none', // For Chrome, Safari, and Opera
                    },
                  }}
                  onClick={handleCopyToClipboardMap}
                >
                  {!includeReplayState ? (
                    <>
                      {copySuccessMap ? (
                        <Check
                          sx={{ ml: 1, mr: 1 }}
                          fontSize="small"
                          color="success"
                        />
                      ) : (
                        <ContentCopy sx={{ ml: 1, mr: 1 }} fontSize="small" />
                      )}
                      {`${updateUrlParameters({
                        currentRound: null,
                        currentTick: null,
                      })}&shareCode=${mapShareCode}`}
                    </>
                  ) : (
                    <>
                      {copySuccessMap ? (
                        <Check
                          sx={{ ml: 1, mr: 1 }}
                          fontSize="small"
                          color="success"
                        />
                      ) : (
                        <ContentCopy sx={{ ml: 1, mr: 1 }} fontSize="small" />
                      )}
                      {`${updateUrlParameters({
                        currentRound: currentRoundNumber,
                        currentTick: currentTickIndex,
                      })}&shareCode=${mapShareCode}`}
                    </>
                  )}
                </Box>
              </>
            )}
          </>
        ) : (
          <Typography sx={{ ml: 1, mt: 1 }} variant="body1">
            No notes available for sharing
          </Typography>
        )}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            mt: 2,
          }}
        >
          <Checkbox
            size="small"
            checked={includeReplayState}
            onChange={() => setIncludeReplayState(!includeReplayState)}
          />
          <Typography variant="body1">
            Include current replay position
          </Typography>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

ShareDialog.propTypes = {
  sharePopupOpen: PropTypes.bool.isRequired,
  setSharePopupOpen: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  note: PropTypes.object,
  shareCode: PropTypes.string,
};

export default ShareDialog;
