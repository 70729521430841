import { Grid, InputAdornment, TextField, Typography } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';

function RangeInput({ label, value1, value2, onChange1, onChange2 }) {
  const handleChange = (handler) => (event) => {
    const { value } = event.target;
    if (/^\d*$/.test(value)) {
      handler(value === '' ? '0' : value);
    }
  };

  return (
    <>
      <Grid item xs={12} sm={5}>
        <Typography
          variant="body2"
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            ml: 2,
          }}
        >
          {label}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        sm={7}
        container
        justifyContent="center"
        alignItems="center"
      >
        <TextField
          value={value1}
          onChange={handleChange(onChange1)}
          label="from"
          sx={{
            width: '47.5%',
            '& .MuiInputBase-root': { height: '35px' },
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment
                position="end"
                sx={{
                  marginRight: '-5px',
                }}
              >
                $
              </InputAdornment>
            ),
            inputMode: 'numeric',
            pattern: '[0-9]*',
          }}
        />
        <TextField
          value={value2}
          onChange={handleChange(onChange2)}
          label="to"
          sx={{
            width: '47.5%',
            '& .MuiInputBase-root': { height: '35px' },
            ml: 1,
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment
                position="end"
                sx={{
                  marginRight: '-5px',
                }}
              >
                $
              </InputAdornment>
            ),
            inputMode: 'numeric',
            pattern: '[0-9]*',
          }}
        />
      </Grid>
    </>
  );
}

RangeInput.propTypes = {
  label: PropTypes.string.isRequired,
  value1: PropTypes.number.isRequired,
  value2: PropTypes.number.isRequired,
  onChange1: PropTypes.func.isRequired,
  onChange2: PropTypes.func.isRequired,
};

export default RangeInput;
