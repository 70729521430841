import {
  Autocomplete,
  Box,
  Grid,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import axiosInstance from '../../axiosInstance.js';

const mapOptions = [
  'Ancient',
  'Anubis',
  'Dust2',
  'Inferno',
  'Mirage',
  'Nuke',
  'Overpass',
  'Vertigo',
];

function MatchSelection({
  mapFilter,
  setMapFilter,
  team1Filter,
  setTeam1Filter,
  team2Filter,
  setTeam2Filter,
}) {
  const [teamOptions, setTeamOptions] = useState([]);

  useEffect(() => {
    const fetchTeams = async () => {
      try {
        const response = await axiosInstance.get('/api/teams');
        if (response.data) {
          const teams = response.data.map((team) => ({
            name: team.name,
            aliases: team.aliases || [],
          }));
          setTeamOptions(teams);
        }
      } catch (error) {
        console.error('Error fetching team options:', error);
      }
    };

    fetchTeams().then();
  }, []);

  const handleTeam1Change = (event, newValue, reason) => {
    if (reason === 'clear') {
      setTeam1Filter('');
    } else if (newValue !== null) {
      setTeam1Filter(newValue.name || '');
    }
  };
  const handleTeam1InputChange = (event, newInputValue) => {
    setTeam1Filter(newInputValue);
  };

  const handleTeam2Change = (event, newValue, reason) => {
    if (reason === 'clear') {
      setTeam2Filter('');
    } else if (newValue !== null) {
      setTeam2Filter(newValue.name || '');
    }
  };
  const handleTeam2InputChange = (event, newInputValue) => {
    setTeam2Filter(newInputValue);
  };

  const handleMapChange = (event, newValue, reason) => {
    if (reason === 'clear') {
      setMapFilter('');
    } else if (newValue !== null) {
      setMapFilter(newValue);
    }
  };
  const handleMapInputChange = (event, newInputValue) => {
    setMapFilter(newInputValue);
  };

  const sortedTeamOptions = useMemo(
    () =>
      teamOptions
        .map((team) => ({
          group: /[0-9]/.test(team.name[0])
            ? '0-9'
            : team.name[0].toUpperCase(),
          ...team,
        }))
        .sort((a, b) => a.name.localeCompare(b.name)),
    [teamOptions],
  );

  const filterTeams = (options, { inputValue }) => {
    const regex = new RegExp(inputValue, 'i');
    return options.filter(
      (option) =>
        regex.test(option.name) ||
        option.aliases.some((alias) => regex.test(alias)),
    );
  };

  return (
    <Paper elevation={3} sx={{ padding: 3 }}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ mb: 2 }}
      >
        <Typography variant="h6">Match selection</Typography>
      </Box>
      <Grid container>
        <Grid item xs={12} sm={6}>
          <Box justifyContent="space-between" display="flex">
            <Autocomplete
              fullWidth
              freeSolo
              value={team1Filter}
              onChange={handleTeam1Change}
              onInputChange={handleTeam1InputChange}
              options={sortedTeamOptions}
              groupBy={(option) => option.group || ''}
              getOptionLabel={(option) => {
                if (typeof option === 'string') {
                  return option;
                }
                return option.name ? option.name : '';
              }}
              filterOptions={filterTeams}
              renderGroup={(params) => (
                <li key={params.group}>
                  <Typography
                    sx={{ fontWeight: 'bold', ml: 2 }}
                    variant="body2"
                  >
                    {params.group}
                  </Typography>
                  <ul style={{ padding: 0 }}>{params.children}</ul>
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  /* eslint-disable-next-line react/jsx-props-no-spreading */
                  {...params}
                  label="Team"
                  fullWidth
                  InputProps={{
                    ...params.InputProps,
                    type: 'search',
                  }}
                  InputLabelProps={{
                    sx: { fontSize: '0.875rem' },
                  }}
                />
              )}
              ListboxProps={{
                style: {
                  maxHeight: '250px',
                },
              }}
              sx={{
                '& .MuiInputBase-root': {
                  fontSize: '0.875rem',
                  lineHeight: '1em',
                },
                '& .MuiAutocomplete-input': {
                  padding: '4px 8px !important',
                },
                '& .MuiInputLabel-root': {
                  lineHeight: '1em',
                },
              }}
            />
            <Typography variant="body2" sx={{ padding: 1, mt: 1 }}>
              vs
            </Typography>
            <Autocomplete
              fullWidth
              freeSolo
              value={team2Filter}
              onChange={handleTeam2Change}
              onInputChange={handleTeam2InputChange}
              options={sortedTeamOptions}
              groupBy={(option) => option.group || ''}
              getOptionLabel={(option) => {
                if (typeof option === 'string') {
                  return option;
                }
                return option.name ? option.name : '';
              }}
              filterOptions={filterTeams}
              renderGroup={(params) => (
                <li key={params.group}>
                  <Typography
                    sx={{ fontWeight: 'bold', ml: 2 }}
                    variant="body2"
                  >
                    {params.group}
                  </Typography>
                  <ul style={{ padding: 0 }}>{params.children}</ul>
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  /* eslint-disable-next-line react/jsx-props-no-spreading */
                  {...params}
                  label="Team"
                  InputProps={{
                    ...params.InputProps,
                    type: 'search',
                  }}
                  InputLabelProps={{
                    sx: { fontSize: '0.875rem' },
                  }}
                />
              )}
              ListboxProps={{
                style: {
                  maxHeight: '250px',
                },
              }}
              sx={{
                '& .MuiInputBase-root': {
                  fontSize: '0.875rem',
                  lineHeight: '1em',
                },
                '& .MuiAutocomplete-input': {
                  padding: '4px 8px !important',
                },
                '& .MuiInputLabel-root': {
                  lineHeight: '1em',
                },
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={3} />
        <Grid item xs={12} sm={3}>
          <Autocomplete
            fullWidth
            freeSolo
            value={mapFilter}
            onChange={handleMapChange}
            onInputChange={handleMapInputChange}
            options={mapOptions}
            filterOptions={() => {
              if (mapFilter !== '') {
                return mapOptions.filter((option) =>
                  option.toLowerCase().startsWith(mapFilter.toLowerCase()),
                );
              }
              return mapOptions;
            }}
            renderInput={(params) => (
              <TextField
                /* eslint-disable-next-line react/jsx-props-no-spreading */
                {...params}
                label="Map name"
                InputProps={{
                  ...params.InputProps,
                  type: 'search',
                }}
                InputLabelProps={{
                  sx: { fontSize: '0.875rem' },
                }}
              />
            )}
            sx={{
              '& .MuiInputBase-root': {
                fontSize: '0.875rem',
                lineHeight: '1em',
              },
              '& .MuiAutocomplete-input': {
                padding: '4px 8px !important',
              },
              '& .MuiInputLabel-root': {
                lineHeight: '1em',
              },
            }}
          />
        </Grid>
      </Grid>
    </Paper>
  );
}

MatchSelection.propTypes = {
  mapFilter: PropTypes.string.isRequired,
  setMapFilter: PropTypes.func.isRequired,
  team1Filter: PropTypes.string.isRequired,
  setTeam1Filter: PropTypes.func.isRequired,
  team2Filter: PropTypes.string.isRequired,
  setTeam2Filter: PropTypes.func.isRequired,
};

export default MatchSelection;
