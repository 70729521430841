import React, { useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  Paper,
  TextField,
  Link as MuiLink,
  Snackbar,
  Alert as MuiAlert,
  InputAdornment,
  IconButton,
} from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Link, useNavigate } from 'react-router-dom';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import logo from '../DefuzeLogoColor.svg';
import axiosInstance from '../axiosInstance.js';

export async function userLogin(
  email,
  password,
  setOpenSnackbar,
  setSnackbarMessage,
  rememberMe,
  navigate,
  firstSignIn = false,
) {
  try {
    await axiosInstance
      .post(
        '/api/users/login',
        {
          email,
          password,
          rememberMe,
        },
        { withCredentials: true },
      )
      .then(async (res) => {
        if (res.status === 200) {
          localStorage.setItem('jwt-token', res.data.token);
          const loginDate = new Date();
          await axiosInstance
            .patch(`/api/users/${res.data.userId}`, {
              lastLoginDate: loginDate,
            })
            .then()
            .catch(() => console.debug('Failed to update last login date'));

          // request email verification
          if (firstSignIn) {
            await axiosInstance.post('/api/users/request-verification', {});
            localStorage.setItem('tutDashboard', 'true');
            localStorage.setItem('tutMatches', 'true');
            localStorage.setItem('tutReplay', 'true');
          }
          // Redirect to dashboard
          navigate('/dashboard');
        }
      })
      .catch((err) => {
        // Handle invalid email or password
        if (
          err.response.status === 401 &&
          err.response.data.message === 'Invalid email or password'
        ) {
          setOpenSnackbar(true);
          setSnackbarMessage('Invalid email or password. Please try again.');
          setTimeout(() => {
            setOpenSnackbar(false);
          }, 5000);
        }
      });
  } catch (err) {
    console.error(err);
  }
}

const validationSchema = Yup.object({
  email: Yup.string()
    .email('Invalid email address')
    .matches(
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      'Invalid email address',
    )
    .required('Email is required'),
  password: Yup.string().required('Password is required'),
});

export default function SignIn() {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema,
    onSubmit: (values) => {
      userLogin(
        values.email,
        values.password,
        setOpenSnackbar,
        setSnackbarMessage,
        rememberMe,
        navigate,
      ).then();
    },
  });

  return (
    <Paper
      elevation={3}
      sx={{
        padding: 1,
        width: 350,
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
      }}
    >
      <Snackbar
        open={openSnackbar}
        autoHideDuration={10000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          severity="error"
          onClose={() => setOpenSnackbar(false)}
          sx={{ width: 300 }}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 4,
            marginBottom: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <a href="/">
            <img src={logo} alt="Logo" style={{ height: '60px' }} />
          </a>
          <Box component="form" onSubmit={formik.handleSubmit} sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email address"
              name="email"
              autoComplete="email"
              autoFocus
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type={showPassword ? 'text' : 'password'}
              id="password"
              autoComplete="current-password"
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      onMouseDown={(event) => {
                        event.preventDefault();
                      }}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={rememberMe}
                  onChange={(e) => setRememberMe(e.target.checked)}
                  color="primary"
                />
              }
              label="Remember me"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                <MuiLink component={Link} to="/forgot-password" variant="body2">
                  Forgot password?
                </MuiLink>
              </Grid>
              <Grid item>
                <MuiLink component={Link} to="/signup" variant="body2">
                  Sign Up
                </MuiLink>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </Paper>
  );
}
