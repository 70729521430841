import { Container, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../utils/AuthContext.js';
import EmailVerificationSnackbar from './common/EmailVerificationSnackbar.jsx';
import NotificationSnackbar from './common/NotificationSnackbar.jsx';
import GeneralInfo from './profile/GeneralInfo.jsx';
import SubscriptionInfo from './profile/SubscriptionInfo.jsx';
import PaymentHistory from './profile/PaymentHistory.jsx';
import AccountManagement from './profile/AccountManagement.jsx';
import OnboardDialog from './profile/OnboardDialog.jsx';
import axiosInstance from '../axiosInstance.js';

function Profile() {
  const { user, setUser } = useAuth();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const [paymentSuccess, setPaymentSuccess] = useState(
    urlParams.get('payment_success'),
  );
  const [onboardDialogOpen, setOnboardDialogOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (paymentSuccess === 'true') {
      const updateStripeDataAndUser = async () => {
        try {
          // open onboard dialog
          setOnboardDialogOpen(true);

          await axiosInstance.post('/api/actions/update-stripe-data');
          console.debug('Stripe data updated successfully');

          // Fetch user data and update state after successful payment
          if (!user || !user._id) {
            return;
          }
          const { data: userData } = await axiosInstance.get(
            `/api/users/${user._id}`,
          );
          setUser(userData);

          // set onboarding trigger (should only bet set if user is new)
          localStorage.setItem('tutDashboardSub', 'true');
          localStorage.setItem('tutMatchesSub', 'true');
        } catch (error) {
          console.error('Failed to update data after payment', error);
        }
      };

      updateStripeDataAndUser();
    }
  }, [paymentSuccess]);

  const handleClose = () => {
    setPaymentSuccess(null);
    urlParams.delete('payment_success');
    navigate({ search: urlParams.toString() }, { replace: true });
  };

  if (!user) {
    return null;
  }
  return (
    <Container>
      <EmailVerificationSnackbar />
      <NotificationSnackbar
        open={paymentSuccess === 'true'}
        message="Payment successful!"
        severity="success"
        onClose={handleClose}
      />
      <NotificationSnackbar
        open={paymentSuccess === 'false'}
        message="Payment failed. Please try again."
        severity="error"
        onClose={handleClose}
      />
      <OnboardDialog
        setOnboardDialogOpen={setOnboardDialogOpen}
        onboardDialogOpen={onboardDialogOpen}
      />
      <Grid container spacing={1}>
        {/* General Info */}
        <Grid item xs={12} sm={12}>
          <GeneralInfo />
        </Grid>

        {/* Subscription */}
        <Grid item xs={12} sm={12}>
          <SubscriptionInfo />
        </Grid>

        {/* Payment History */}
        {user.subscription === 1 || user.subscription >= 2 ? (
          <Grid item xs={12} sm={12}>
            <PaymentHistory />
          </Grid>
        ) : null}

        {/* Account Management */}
        <Grid item xs={12} sm={12}>
          <AccountManagement />
        </Grid>
      </Grid>
    </Container>
  );
}

export default Profile;
