import {
  ExpandMore,
  KeyboardArrowRight,
  SportsScore,
} from '@mui/icons-material';
import {
  Box,
  Button,
  Checkbox,
  Collapse,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

function OutcomeFilter({ checkedValues, setCheckedValues }) {
  // State to manage the expanded section
  const [expanded, setExpanded] = useState(
    JSON.parse(sessionStorage.getItem('expandedAdditional')) || false,
  );

  // save the filters in the session storage
  useEffect(() => {
    sessionStorage.setItem('expandedAdditional', expanded);
  }, [expanded]);

  // handle checkbox changes
  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setCheckedValues({
      ...checkedValues,
      [name]: checked,
    });
  };

  // Helper function to convert to camel case
  const toCamelCase = (str) =>
    str
      .replace(/\s(.)/g, (match, group) => group.toUpperCase())
      .replace(/^(.)/, (match) => match.toLowerCase());

  return (
    <>
      <Grid item xs={12}>
        <Button
          variant="text"
          fullWidth
          onClick={() => setExpanded(!expanded)}
          sx={{
            justifyContent: 'flex-start',
            color: 'black',
            fontSize: '1rem',
          }}
          startIcon={expanded ? <ExpandMore /> : <KeyboardArrowRight />}
        >
          <Box display="flex" alignItems="center">
            <SportsScore sx={{ mr: 1 }} />
            Outcome
          </Box>
        </Button>
      </Grid>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Grid container>
          {/* Left half */}
          <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'center' }}>
            <List>
              {['Bomb exploded', 'Bomb defused', 'No time'].map(
                (reason, index) => {
                  const camelCaseReason = toCamelCase(reason);
                  return (
                    // eslint-disable-next-line react/no-array-index-key
                    <ListItem key={index} disablePadding sx={{ marginLeft: 3 }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={checkedValues[camelCaseReason]}
                            onChange={handleCheckboxChange}
                            name={camelCaseReason}
                            size="small"
                          />
                        }
                        label={
                          <Typography
                            variant="body2"
                            sx={{ fontSize: 'small' }}
                          >
                            {reason}
                          </Typography>
                        }
                      />
                    </ListItem>
                  );
                },
              )}
            </List>
          </Grid>
          {/* Right half */}
          <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'center' }}>
            <List>
              {['T elimination', 'CT elimination'].map((reason, index) => {
                const camelCaseReason = toCamelCase(reason);
                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <ListItem key={index} disablePadding sx={{ marginLeft: 3 }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checkedValues[camelCaseReason]}
                          onChange={handleCheckboxChange}
                          name={camelCaseReason}
                          size="small"
                        />
                      }
                      label={
                        <Typography variant="body2" sx={{ fontSize: 'small' }}>
                          {reason}
                        </Typography>
                      }
                    />
                  </ListItem>
                );
              })}
            </List>
          </Grid>
        </Grid>
      </Collapse>
    </>
  );
}

OutcomeFilter.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  checkedValues: PropTypes.object.isRequired,
  setCheckedValues: PropTypes.func.isRequired,
};

export default OutcomeFilter;
