import React, { useState } from 'react';
import {
  Box,
  Button,
  Container,
  Paper,
  TextField,
  Snackbar,
  Alert as MuiAlert,
  ListItem,
  List,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import {
  CancelOutlined,
  CheckCircleOutline,
  LockReset,
} from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import logo from '../DefuzeLogoColor.svg';
import axiosInstance from '../axiosInstance.js';

const validationSchema = Yup.object({
  password: Yup.string()
    .min(6, 'Password must be at least 6 characters long')
    .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
    .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
    .matches(/\d/, 'Password must contain at least one number')
    .required('Password is required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Password confirmation is required'),
});

const passwordRequirements = [
  { label: 'At least 6 characters long', regex: /.{6,}/ },
  { label: 'Contains an uppercase letter', regex: /[A-Z]/ },
  { label: 'Contains a lowercase letter', regex: /[a-z]/ },
  { label: 'Contains a number', regex: /\d/ },
];

export default function resetPassword() {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('error');
  const location = useLocation();
  const token = new URLSearchParams(location.search).get('token');
  const navigate = useNavigate();

  const handleFormSubmit = async (values) => {
    try {
      await axiosInstance
        .post('/api/users/reset-password', {
          token,
          password: values.password,
        })
        .then((response) => {
          if (
            response.status === 200 &&
            response.data.message === 'Password successfully reset'
          ) {
            setSnackbarMessage(
              'Password successfully reset, redirecting to sign-in page',
            );
            setSnackbarSeverity('success');
            setOpenSnackbar(true);
            setTimeout(() => {
              navigate('/signin');
            }, 5000);
          }
        })
        .catch((error) => {
          if (
            error.response.status === 403 &&
            error.response.data.message === 'Invalid or expired token'
          ) {
            setSnackbarMessage(
              'Token to reset your password is expired or invalid, please request a new one',
            );
          } else if (
            error.response.status === 400 &&
            error.response.data.message === 'No token provided'
          ) {
            setSnackbarMessage(
              'Please reset your password using the link sent to your email',
            );
          } else if (
            error.response.status === 409 &&
            error.response.data.message ===
              'New password must be different from the old password'
          ) {
            setSnackbarMessage(
              'New password must be different from the old one',
            );
          } else {
            setSnackbarMessage('An error occurred. Please try again later.');
          }
          setSnackbarSeverity('error');
          setOpenSnackbar(true);
        });
    } catch (error) {
      console.debug(error);
    }
  };

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validationSchema,
    onSubmit: handleFormSubmit,
  });

  const getPasswordStrength = (password) =>
    passwordRequirements.map((requirement) => ({
      ...requirement,
      isMet: requirement.regex.test(password),
    }));

  return (
    <Paper
      elevation={3}
      sx={{
        padding: 1,
        width: 350,
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
      }}
    >
      <Snackbar
        open={openSnackbar}
        autoHideDuration={10000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          severity={snackbarSeverity}
          onClose={() => setOpenSnackbar(false)}
          sx={{ width: 300 }}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 2,
            marginBottom: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <img src={logo} alt="Logo" style={{ height: '60px' }} />
          <Box component="form" onSubmit={formik.handleSubmit} sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="password"
              label="New Password"
              name="password"
              type="password"
              autoComplete="new-password"
              autoFocus
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.password && Boolean(formik.errors.password)}
            />
            {(formik.values.password !== '' || formik.touched.password) &&
              formik.errors.password && (
                <List
                  dense
                  sx={{
                    fontSize: '0.75rem',
                    padding: 0,
                  }}
                >
                  {getPasswordStrength(formik.values.password).map(
                    (requirement, index) => (
                      <ListItem
                        /* eslint-disable-next-line react/no-array-index-key */
                        key={index}
                        sx={{
                          padding: '0 0',
                        }}
                      >
                        <ListItemIcon>
                          {requirement.isMet ? (
                            <CheckCircleOutline
                              color="success"
                              sx={{ fontSize: '1rem' }}
                            />
                          ) : (
                            <CancelOutlined
                              color="error"
                              sx={{ fontSize: '1rem' }}
                            />
                          )}
                        </ListItemIcon>
                        <ListItemText
                          primary={requirement.label}
                          sx={{
                            color: requirement.isMet
                              ? 'text.primary'
                              : 'text.secondary',
                            fontSize: '0.75rem',
                            margin: 0,
                          }}
                        />
                      </ListItem>
                    ),
                  )}
                </List>
              )}
            <TextField
              margin="normal"
              required
              fullWidth
              id="confirmPassword"
              label="Confirm New Password"
              name="confirmPassword"
              type="password"
              autoComplete="new-password"
              value={formik.values.confirmPassword}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.confirmPassword &&
                Boolean(formik.errors.confirmPassword)
              }
              helperText={
                formik.touched.confirmPassword && formik.errors.confirmPassword
              }
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              endIcon={<LockReset />}
            >
              Reset Password
            </Button>
          </Box>
        </Box>
      </Container>
    </Paper>
  );
}
