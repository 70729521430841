import React from 'react';
import { Box, Typography, LinearProgress, Paper, Tooltip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ReactComponent as C4Icon } from './assets/weapon_c4.svg';
import { useMatchData } from '../../utils/MatchDataContext.js';

const TICKS_PER_SECOND = 16;
const BOMB_TIMER = 40;

function InfoBar() {
  const theme = useTheme();
  const ctColor = theme.palette.cs2.ct;
  const tColor = theme.palette.cs2.t;

  // get matchData from MatchDataContext
  const { currentTickIndex, currentRoundData, currentMapData, metadata } =
    useMatchData();
  // calculate necessary values
  const tWinPercentageStart = currentRoundData.startPredictionT;
  const tWinPercentageBombPlant = currentRoundData.plantPredictionT;
  let tSideLeft;
  const roundStartTickIndex = 0;
  let leftScore = 0;
  let rightScore = 0;
  if (currentRoundData.winner[0] === currentMapData.winner._id) {
    tSideLeft = currentRoundData.winnerSide === 'T';
    leftScore = currentRoundData.roundWinnerScore;
    rightScore = currentRoundData.roundLoserScore;
  } else {
    tSideLeft = currentRoundData.loserSide === 'T';
    leftScore = currentRoundData.roundLoserScore;
    rightScore = currentRoundData.roundWinnerScore;
  }

  const { bombPlant } = metadata;

  const getTimeDisplay = () => {
    const ticksSinceRoundStart = currentTickIndex - roundStartTickIndex;

    const roundTime = Math.floor(ticksSinceRoundStart / TICKS_PER_SECOND);

    if (
      bombPlant &&
      bombPlant.from <= currentTickIndex &&
      currentTickIndex <= bombPlant.to
    ) {
      const bombTimeLeft = Math.max(
        0,
        BOMB_TIMER -
          Math.floor((currentTickIndex - bombPlant.from) / TICKS_PER_SECOND),
      );
      return {
        time: `0:${bombTimeLeft.toString().padStart(2, '0')}`,
        color: 'red',
        isBombPlanted: true,
      };
    }

    if (bombPlant && currentTickIndex >= bombPlant.to) {
      return {
        time: '0:00',
        color: 'black',
        isBombPlanted: false,
      };
    }

    return {
      time: `${Math.floor(roundTime / 60)}:${(roundTime % 60)
        .toString()
        .padStart(2, '0')}`,
      color: 'black',
      isBombPlanted: false,
    };
  };

  const { time, color, isBombPlanted } = getTimeDisplay();

  const currentTWinPercentage =
    bombPlant && currentTickIndex >= bombPlant.from
      ? tWinPercentageBombPlant
      : tWinPercentageStart;

  return (
    <Paper sx={{ p: 1, borderRadius: 0, borderRight: 0, borderLeft: 0 }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          position: 'relative',
          mb: 1,
          height: '30px',
          width: '100%',
        }}
      >
        <Tooltip
          title={tSideLeft ? 'T round score' : 'CT round score'}
          placement="right"
        >
          <Typography
            variant="h5"
            sx={{
              color: tSideLeft ? tColor : ctColor,
              position: 'absolute',
              left: '25%',
              transform: 'translateX(-50%)',
              fontWeight: 'bold',
              textShadow: '0 0 1px rgba(0, 0, 0, 0.5)',
              fontSize: '2.25rem',
            }}
          >
            {leftScore}
          </Typography>
        </Tooltip>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            position: 'absolute',
            left: '50%',
            transform: 'translateX(-50%)',
          }}
        >
          <Typography
            variant="h5"
            sx={{
              color,
              position: 'absolute',
              left: '50%',
              transform: 'translateX(-50%)',
            }}
          >
            {time}
          </Typography>
          <Box
            sx={{
              width: '30px',
              visibility: isBombPlanted ? 'visible' : 'hidden',
              ml: 11,
            }}
          >
            <C4Icon
              style={{
                width: '100%',
                height: '100%',
                fill: color,
              }}
            />
          </Box>
        </Box>
        <Tooltip
          title={!tSideLeft ? 'T round score' : 'CT round score'}
          placement="right"
        >
          <Typography
            variant="h5"
            sx={{
              color: tSideLeft ? ctColor : tColor,
              position: 'absolute',
              left: '75%',
              transform: 'translateX(-50%)',
              fontWeight: 'bold',
              textShadow: '0 0 1px rgba(0, 0, 0, 0.5)',
              fontSize: '2.25rem',
            }}
          >
            {rightScore}
          </Typography>
        </Tooltip>
      </Box>
      <Tooltip
        title={`Win probability ${!isBombPlanted ? ' at round start' : ' at bomb plant'}`}
        arrow
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography
            variant="h6"
            sx={{
              width: '60px',
              color: tSideLeft ? tColor : ctColor,
              textAlign: 'center',
              fontWeight: 'bold',
              textShadow: '0 0 1px rgba(0, 0, 0, 0.5)',
            }}
          >
            {tSideLeft
              ? `${Math.round(currentTWinPercentage * 100)}%`
              : `${Math.round((1 - currentTWinPercentage) * 100)}%`}
          </Typography>

          <Box
            sx={{ position: 'relative', display: 'inline-flex', width: '100%' }}
          >
            <LinearProgress
              variant="determinate"
              value={
                tSideLeft
                  ? currentTWinPercentage * 100
                  : 100 - currentTWinPercentage * 100
              }
              sx={{
                flexGrow: 1,
                mx: 2,
                height: 32,
                borderRadius: 2,
                '& .MuiLinearProgress-bar': {
                  backgroundColor: tSideLeft ? tColor : ctColor,
                  borderRadius: tSideLeft ? '8px 0 0 8px' : '0 8px 8px 0',
                },
                backgroundColor: tSideLeft ? ctColor : tColor,
              }}
            />
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Typography
                variant="subtitle2"
                sx={{
                  fontWeight: 'bold',
                  fontSize: '1.2rem',
                  textTransform: 'uppercase',
                  letterSpacing: '0.05em',
                  color: '#FFFFFF',
                  textShadow: '0px 0px 3px rgba(0, 0, 0, 1)',
                }}
              >
                AI Prediction
              </Typography>
            </Box>
          </Box>

          <Typography
            variant="h6"
            sx={{
              width: '60px',
              textAlign: 'center',
              color: tSideLeft ? ctColor : tColor,
              fontWeight: 'bold',
              textShadow: '0 0 1px rgba(0, 0, 0, 0.5)',
            }}
          >
            {tSideLeft
              ? `${Math.round((1 - currentTWinPercentage) * 100)}%`
              : `${Math.round(currentTWinPercentage * 100)}%`}
          </Typography>
        </Box>
      </Tooltip>
    </Paper>
  );
}

export default InfoBar;
