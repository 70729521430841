import React from 'react';
import { Typography } from '@mui/material';
// RIFLES
import { ReactComponent as AK47 } from '../assets/weapons/rifles/weapon_ak-47.svg';
import { ReactComponent as AUG } from '../assets/weapons/rifles/weapon_aug.svg';
import { ReactComponent as AWP } from '../assets/weapons/rifles/weapon_awp.svg';
import { ReactComponent as FAMAS } from '../assets/weapons/rifles/weapon_famas.svg';
import { ReactComponent as GALIL } from '../assets/weapons/rifles/weapon_galilar.svg';
import { ReactComponent as M4 } from '../assets/weapons/rifles/weapon_m4a1.svg';
import { ReactComponent as M4S } from '../assets/weapons/rifles/weapon_m4a1-s.svg';
import { ReactComponent as SG556 } from '../assets/weapons/rifles/weapon_sg556.svg';
import { ReactComponent as SSG08 } from '../assets/weapons/rifles/weapon_ssg08.svg';
import { ReactComponent as SCAR20 } from '../assets/weapons/rifles/weapon_scar20.svg';
import { ReactComponent as G3SG1 } from '../assets/weapons/rifles/weapon_g3sg1.svg';
// PISTOLS
import { ReactComponent as CZ } from '../assets/weapons/pistols/weapon_cz75a.svg';
import { ReactComponent as DEAGLE } from '../assets/weapons/pistols/weapon_deserteagle.svg';
import { ReactComponent as ELITE } from '../assets/weapons/pistols/weapon_elite.svg';
import { ReactComponent as FIVE7 } from '../assets/weapons/pistols/weapon_five-seven.svg';
import { ReactComponent as GLOCK } from '../assets/weapons/pistols/weapon_glock-18.svg';
import { ReactComponent as P2000 } from '../assets/weapons/pistols/weapon_hkp2000.svg';
import { ReactComponent as P250 } from '../assets/weapons/pistols/weapon_p250.svg';
import { ReactComponent as TEC9 } from '../assets/weapons/pistols/weapon_tec-9.svg';
import { ReactComponent as USPS } from '../assets/weapons/pistols/weapon_usp-s.svg';
import { ReactComponent as REVOLVER } from '../assets/weapons/pistols/weapon_revolver.svg';
// SMG
import { ReactComponent as MAC10 } from '../assets/weapons/smg/weapon_mac-10.svg';
import { ReactComponent as MP7 } from '../assets/weapons/smg/weapon_mp7.svg';
import { ReactComponent as MP9 } from '../assets/weapons/smg/weapon_mp9.svg';
import { ReactComponent as UMP } from '../assets/weapons/smg/weapon_ump45.svg';
import { ReactComponent as BIZON } from '../assets/weapons/smg/weapon_bizon.svg';
import { ReactComponent as P90 } from '../assets/weapons/smg/weapon_p90.svg';
import { ReactComponent as MP5 } from '../assets/weapons/smg/weapon_mp5sd.svg';
// HEAVY
import { ReactComponent as MAG7 } from '../assets/weapons/heavy/weapon_mag7.svg';
import { ReactComponent as XM } from '../assets/weapons/heavy/weapon_xm1014.svg';
import { ReactComponent as M249 } from '../assets/weapons/heavy/weapon_m249.svg';
import { ReactComponent as NEGEV } from '../assets/weapons/heavy/weapon_negev.svg';
import { ReactComponent as NOVA } from '../assets/weapons/heavy/weapon_nova.svg';
import { ReactComponent as SAWEDOFF } from '../assets/weapons/heavy/weapon_sawedoff.svg';
// UTILITY
import { ReactComponent as DEFUSER } from '../assets/weapons/utility/item_defuser.svg';
import { ReactComponent as KEVLAR } from '../assets/weapons/utility/item_kevlar.svg';
import { ReactComponent as KEVLARHELM } from '../assets/weapons/utility/item_kevlar_helmet.svg';
// GRENADES
import { ReactComponent as DECOY } from '../assets/weapons/utility/weapon_decoy.svg';
import { ReactComponent as HE } from '../assets/weapons/utility/weapon_highexplosivegrenade.svg';
import { ReactComponent as INC } from '../assets/weapons/utility/weapon_incendiarygrenade.svg';
import { ReactComponent as MOLLY } from '../assets/weapons/utility/weapon_molotov.svg';
import { ReactComponent as SMOKE } from '../assets/weapons/utility/weapon_smokegrenade.svg';
import { ReactComponent as FLASHBANG } from '../assets/weapons/utility/weapon_flashbang.svg';
// KNIFES
import { ReactComponent as KNIFE } from '../assets/weapons/knifes/knife.svg';
import { ReactComponent as KNIFE_BAYONET } from '../assets/weapons/knifes/knife_bayonet.svg';
import { ReactComponent as KNIFE_BUTTERFLY } from '../assets/weapons/knifes/knife_butterfly.svg';
import { ReactComponent as KNIFE_CANIS } from '../assets/weapons/knifes/knife_canis.svg';
import { ReactComponent as KNIFE_CORD } from '../assets/weapons/knifes/knife_cord.svg';
import { ReactComponent as KNIFE_CSS } from '../assets/weapons/knifes/knife_css.svg';
import { ReactComponent as KNIFE_FALCHION } from '../assets/weapons/knifes/knife_falchion.svg';
import { ReactComponent as KNIFE_FLIP } from '../assets/weapons/knifes/knife_flip.svg';
import { ReactComponent as KNIFE_GUT } from '../assets/weapons/knifes/knife_gut.svg';
import { ReactComponent as KNIFE_GYPSY_JACKKNIFE } from '../assets/weapons/knifes/knife_gypsy_jackknife.svg';
import { ReactComponent as KNIFE_KARAMBIT } from '../assets/weapons/knifes/knife_karambit.svg';
import { ReactComponent as KNIFE_M9_BAYONET } from '../assets/weapons/knifes/knife_m9_bayonet.svg';
import { ReactComponent as KNIFE_OUTDOOR } from '../assets/weapons/knifes/knife_outdoor.svg';
import { ReactComponent as KNIFE_PUSH } from '../assets/weapons/knifes/knife_push.svg';
import { ReactComponent as KNIFE_SKELETON } from '../assets/weapons/knifes/knife_skeleton.svg';
import { ReactComponent as KNIFE_STILETTO } from '../assets/weapons/knifes/knife_stiletto.svg';
import { ReactComponent as KNIFE_SURVIVAL_BOWIE } from '../assets/weapons/knifes/knife_survival_bowie.svg';
import { ReactComponent as KNIFE_T } from '../assets/weapons/knifes/knife_t.svg';
import { ReactComponent as KNIFE_TACTICAL } from '../assets/weapons/knifes/knife_tactical.svg';
import { ReactComponent as KNIFE_URSUS } from '../assets/weapons/knifes/knife_ursus.svg';
import { ReactComponent as KNIFE_WIDOWMAKER } from '../assets/weapons/knifes/knife_widowmaker.svg';
// OTHER
import { ReactComponent as ZEUS } from '../assets/weapons/weapon_taser.svg';
import { ReactComponent as C4 } from '../assets/weapons/utility/weapon_c4.svg';

export function mapNameToIcon(weaponName, width, height, style) {
  if (!weaponName) return null;
  switch (weaponName) {
    case 'ak47':
    case 'AK-47':
      return <AK47 width={width} height={height} style={style} />;
    case 'glock':
    case 'Glock-18':
      return <GLOCK width={width} height={height} style={style} />;
    case 'hkp2000':
    case 'P2000':
      return <P2000 width={width} height={height} style={style} />;
    case 'usp_silencer':
    case 'USP-S':
      return <USPS width={width} height={height} style={style} />;
    case 'elite':
    case 'Dual Berettas':
      return <ELITE width={width} height={height} style={style} />;
    case 'p250':
    case 'P250':
      return <P250 width={width} height={height} style={style} />;
    case 'tec9':
    case 'Tec-9':
      return <TEC9 width={width} height={height} style={style} />;
    case 'cz75a':
    case 'CZ75-Auto':
      return <CZ width={width} height={height} style={style} />;
    case 'revolver':
    case 'R8 Revolver':
      return <REVOLVER width={width} height={height} style={style} />;
    case 'fn57':
    case 'Five-SeveN':
      return <FIVE7 width={width} height={height} style={style} />;
    case 'deagle':
    case 'Desert Eagle':
      return <DEAGLE width={width} height={height} style={style} />;
    case 'galilar':
    case 'Galil AR':
      return <GALIL width={width} height={height} style={style} />;
    case 'famas':
    case 'FAMAS':
      return <FAMAS width={width} height={height} style={style} />;
    case 'm4a1':
    case 'M4A4':
      return <M4 width={width} height={height} style={style} />;
    case 'm4a1_silencer':
    case 'M4A1-S':
      return <M4S width={width} height={height} style={style} />;
    case 'ssg08':
    case 'SSG 08':
      return <SSG08 width={width} height={height} style={style} />;
    case 'aug':
    case 'AUG':
      return <AUG width={width} height={height} style={style} />;
    case 'sg556':
    case 'SG 553':
      return <SG556 width={width} height={height} style={style} />;
    case 'awp':
    case 'AWP':
      return <AWP width={width} height={height} style={style} />;
    case 'scar20':
    case 'SCAR-20':
      return <SCAR20 width={width} height={height} style={style} />;
    case 'g3sg1':
    case 'G3SG1':
      return <G3SG1 width={width} height={height} style={style} />;
    case 'nova':
    case 'Nova':
      return <NOVA width={width} height={height} style={style} />;
    case 'xm1014':
    case 'XM1014':
      return <XM width={width} height={height} style={style} />;
    case 'mag7':
    case 'MAG-7':
      return <MAG7 width={width} height={height} style={style} />;
    case 'sawedoff':
    case 'Sawed-Off':
      return <SAWEDOFF width={width} height={height} style={style} />;
    case 'm249':
    case 'M249':
      return <M249 width={width} height={height} style={style} />;
    case 'negev':
    case 'Negev':
      return <NEGEV width={width} height={height} style={style} />;
    case 'mac10':
    case 'MAC-10':
      return <MAC10 width={width} height={height} style={style} />;
    case 'mp9':
    case 'MP9':
      return <MP9 width={width} height={height} style={style} />;
    case 'mp7':
    case 'MP7':
      return <MP7 width={width} height={height} style={style} />;
    case 'mp5sd':
    case 'MP5-SD':
      return <MP5 width={width} height={height} style={style} />;
    case 'ump45':
    case 'UMP-45':
      return <UMP width={width} height={height} style={style} />;
    case 'p90':
    case 'P90':
      return <P90 width={width} height={height} style={style} />;
    case 'bizon':
    case 'PP-Bizon':
      return <BIZON width={width} height={height} style={style} />;
    case 'vest':
      return <KEVLAR width={width} height={height} style={style} />;
    case 'vesthelm':
      return <KEVLARHELM width={width} height={height} style={style} />;
    case 'taser':
    case 'Zeus x27':
      return <ZEUS width={width} height={height} style={style} />;
    case 'defuser':
    case 'Defuse Kit':
      return <DEFUSER width={width} height={height} style={style} />;
    case 'molotov':
    case 'Molotov':
      return <MOLLY width={width} height={height} style={style} />;
    case 'incgrenade':
    case 'Incendiary Grenade':
      return <INC width={width} height={height} style={style} />;
    case 'decoy':
    case 'Decoy Grenade':
      return <DECOY width={width} height={height} style={style} />;
    case 'flashbang':
    case 'Flashbang':
      return <FLASHBANG width={width} height={height} style={style} />;
    case 'hegrenade':
    case 'HE Grenade':
    case 'High Explosive Grenade':
      return <HE width={width} height={height} style={style} />;
    case 'smokegrenade':
    case 'Smoke Grenade':
      return <SMOKE width={width} height={height} style={style} />;
    case 'knife':
      return <KNIFE width={width} height={height} style={style} />;
    case 'knife_t':
      return <KNIFE_T width={width} height={height} style={style} />;
    case 'knife_bayonet':
    case 'bayonet':
      return <KNIFE_BAYONET width={width} height={height} style={style} />;
    case 'knife_css':
      return <KNIFE_CSS width={width} height={height} style={style} />;
    case 'knife_flip':
      return <KNIFE_FLIP width={width} height={height} style={style} />;
    case 'knife_gut':
      return <KNIFE_GUT width={width} height={height} style={style} />;
    case 'knife_karambit':
      return <KNIFE_KARAMBIT width={width} height={height} style={style} />;
    case 'knife_m9_bayonet':
      return <KNIFE_M9_BAYONET width={width} height={height} style={style} />;
    case 'knife_tactical':
      return <KNIFE_TACTICAL width={width} height={height} style={style} />;
    case 'knife_falchion':
      return <KNIFE_FALCHION width={width} height={height} style={style} />;
    case 'knife_survival_bowie':
      return (
        <KNIFE_SURVIVAL_BOWIE width={width} height={height} style={style} />
      );
    case 'knife_butterfly':
      return <KNIFE_BUTTERFLY width={width} height={height} style={style} />;
    case 'knife_push':
      return <KNIFE_PUSH width={width} height={height} style={style} />;
    case 'knife_cord':
      return <KNIFE_CORD width={width} height={height} style={style} />;
    case 'knife_canis':
      return <KNIFE_CANIS width={width} height={height} style={style} />;
    case 'knife_ursus':
      return <KNIFE_URSUS width={width} height={height} style={style} />;
    case 'knife_gypsy_jackknife':
      return (
        <KNIFE_GYPSY_JACKKNIFE width={width} height={height} style={style} />
      );
    case 'knife_outdoor':
      return <KNIFE_OUTDOOR width={width} height={height} style={style} />;
    case 'knife_stiletto':
      return <KNIFE_STILETTO width={width} height={height} style={style} />;
    case 'knife_widowmaker':
      return <KNIFE_WIDOWMAKER width={width} height={height} style={style} />;
    case 'knife_skeleton':
      return <KNIFE_SKELETON width={width} height={height} style={style} />;
    case 'C4':
    case 'c4':
    case 'C4 Explosive':
    case 'planted_c4':
      return <C4 width={width} height={height} style={style} />;
    default:
      return <Typography>{weaponName}</Typography>;
  }
}

export default React.memo(mapNameToIcon);
