import React, { useState } from 'react';
import {
  Box,
  Button,
  Container,
  Grid,
  Paper,
  TextField,
  Link as MuiLink,
  Snackbar,
  Alert as MuiAlert,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  InputAdornment,
  IconButton,
} from '@mui/material';
import {
  CheckCircleOutline,
  CancelOutlined,
  VisibilityOff,
  Visibility,
} from '@mui/icons-material';
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import logo from '../DefuzeLogoColor.svg';
import axiosInstance from '../axiosInstance.js';
import { userLogin } from './SignIn.jsx';

// validation schema with yup
const validationSchema = Yup.object({
  username: Yup.string()
    .matches(
      /^[a-zA-Z0-9_-]+$/,
      'Only letters, numbers, hyphens, and underscores are allowed',
    )
    .min(4, 'Username must be at least 4 characters long')
    .required('Username is required'),
  email: Yup.string()
    .email('Invalid email address')
    .matches(
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      'Invalid email address',
    )
    .required('Email is required'),
  password: Yup.string()
    .min(8, 'Password must be at least 8 characters long')
    .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
    .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
    .matches(/\d/, 'Password must contain at least one number')
    .required('Password is required'),
});

const passwordRequirements = [
  { label: 'At least 8 characters long', regex: /.{8,}/ },
  { label: 'Contains an uppercase letter', regex: /[A-Z]/ },
  { label: 'Contains a lowercase letter', regex: /[a-z]/ },
  { label: 'Contains a number', regex: /\d/ },
];

export default function SignUp() {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  // show snack bar with a message for a certain duration
  const showSnackbar = (message, duration) => {
    setSnackbarMessage(message);
    setOpenSnackbar(true);
    setTimeout(() => setOpenSnackbar(false), duration);
  };

  const handleSubmit = async (values) => {
    try {
      await axiosInstance
        .post('/api/users/signup', {
          username: values.username,
          email: values.email,
          password: values.password,
        })
        .then((res) => {
          if (res.status === 201 && res.data.message === 'New user created') {
            // forward to log in logic in SignIn component
            userLogin(
              values.email,
              values.password,
              undefined,
              undefined,
              true,
              navigate,
              true,
            );
          }
        })
        .catch((err) => {
          if (
            err.response.status === 409 &&
            err.response.data.message === 'Email already in use'
          ) {
            showSnackbar(
              'Email already in use! Please use another email.',
              5000,
            );
          } else if (
            err.response.status === 500 &&
            err.response.data.message === 'Error in creating user'
          ) {
            showSnackbar(
              'Error in creating user! Please try again or contact support.',
              10000,
            );
          } else {
            showSnackbar(
              'An unexpected error occurred. Please try again later.',
              5000,
            );
          }
        });
    } catch (err) {
      console.log(err);
    }
  };

  // formik initialization
  const formik = useFormik({
    initialValues: {
      username: '',
      email: '',
      password: '',
    },
    validationSchema,
    onSubmit: handleSubmit,
  });

  // helper function to check password strength
  const getPasswordStrength = (password) =>
    passwordRequirements.map((requirement) => ({
      ...requirement,
      isMet: requirement.regex.test(password),
    }));

  return (
    <Paper
      elevation={3}
      sx={{
        padding: 1,
        width: 350,
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
      }}
    >
      <Snackbar
        open={openSnackbar}
        autoHideDuration={10000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          severity="error"
          onClose={() => setOpenSnackbar(false)}
          sx={{ width: 300 }}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 4,
            marginBottom: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <a href="/">
            <img src={logo} alt="Logo" style={{ height: '60px' }} />
          </a>
          <Box component="form" onSubmit={formik.handleSubmit} sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label="Username"
              name="username"
              autoComplete="username"
              value={formik.values.username}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.username && Boolean(formik.errors.username)}
              helperText={formik.touched.username && formik.errors.username}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email address"
              name="email"
              autoComplete="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type={showPassword ? 'text' : 'password'}
              id="password"
              autoComplete="new-password"
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.password && Boolean(formik.errors.password)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      onMouseDown={(event) => {
                        event.preventDefault();
                      }}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <List
              dense
              sx={{
                fontSize: '0.75rem',
                padding: 0,
              }}
            >
              {getPasswordStrength(formik.values.password).map(
                (requirement, index) => (
                  <ListItem
                    /* eslint-disable-next-line react/no-array-index-key */
                    key={index}
                    sx={{
                      padding: '0 0',
                    }}
                  >
                    <ListItemIcon>
                      {requirement.isMet ? (
                        <CheckCircleOutline
                          color="success"
                          sx={{ fontSize: '1rem' }}
                        />
                      ) : (
                        <CancelOutlined
                          color="error"
                          sx={{ fontSize: '1rem' }}
                        />
                      )}
                    </ListItemIcon>
                    <ListItemText
                      primary={requirement.label}
                      sx={{
                        color: requirement.isMet
                          ? 'text.primary'
                          : 'text.secondary',
                        fontSize: '0.75rem',
                        margin: 0,
                      }}
                    />
                  </ListItem>
                ),
              )}
            </List>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign Up
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <MuiLink component={Link} to="/signin" variant="body2">
                  Already have an account? Sign in
                </MuiLink>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </Paper>
  );
}
