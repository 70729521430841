import React from 'react';
import { Box, Slider } from '@mui/material';
import PropTypes from 'prop-types';

function RangeSlider({ value, sliderOnChange, mouseUp, setMouseUp, min, max }) {
  const handleSliderChange = (event, newValue) => {
    // Ensure newValue[0] does not exceed max - 50 and newValue[1] does not go below min + 50
    if (newValue[0] >= max - 50) {
      // eslint-disable-next-line no-param-reassign
      newValue[0] = max - 50;
    }
    if (newValue[1] <= min + 50) {
      // eslint-disable-next-line no-param-reassign
      newValue[1] = min + 50;
    }
    sliderOnChange(event, newValue);
  };

  return (
    <Box>
      <Slider
        value={value}
        onChange={handleSliderChange}
        valueLabelDisplay="off"
        min={min}
        max={max}
        step={50}
        onChangeCommitted={() => {
          setMouseUp(!mouseUp);
        }}
        sx={{
          '& .MuiSlider-rail': {
            height: 3,
          },
          '& .MuiSlider-track': {
            height: 3,
          },
          '& .MuiSlider-thumb': {
            width: 14,
            height: 14,
          },
        }}
      />
    </Box>
  );
}

RangeSlider.propTypes = {
  value: PropTypes.arrayOf(PropTypes.number),
  sliderOnChange: PropTypes.func,
  mouseUp: PropTypes.bool,
  setMouseUp: PropTypes.func,
  min: PropTypes.number,
  max: PropTypes.number,
};

export default RangeSlider;
