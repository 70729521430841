/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import { Box, Card, CardContent, Tooltip, Typography } from '@mui/material';
import { ReactComponent as Skull } from './assets/skull.svg';
import { mapNameToIcon } from './utils/mapNameToIcon.js';

const grenades = [
  'High Explosive Grenade',
  'Smoke Grenade',
  'Incendiary Grenade',
  'Decoy Grenade',
  'Flashbang',
  'Molotov',
];
const pistol = [
  'CZ75-Auto',
  'Desert Eagle',
  'Dual Berettas',
  'Five-SeveN',
  'Glock-18',
  'P2000',
  'P250',
  'Tec-9',
  'USP-S',
  'R8 Revolver',
];
function PlayerCard({
  steamId,
  playerName,
  playerHealth,
  playerMoney,
  playerInventory,
  playerHasKevlar,
  playerHasHelmet,
  playerHasDefuser,
  playerActiveWeaponName,
  playerKills,
  isSelected,
  teamColor,
  teamColorDark,
  onToggleSelection,
}) {
  if (!playerInventory) {
    return null;
  }

  const equipmentArray = new Array(7).fill(null);
  const sortedGrenades = playerInventory
    .filter((item) => grenades.includes(item))
    .sort((a, b) => grenades.indexOf(a) - grenades.indexOf(b));

  sortedGrenades.forEach((item, index) => {
    if (index + 3 < equipmentArray.length) {
      equipmentArray[index + 3] = item;
    }
  });

  const filteredInventory = playerInventory.filter(
    (item) => !grenades.includes(item) && !item.includes('C4'),
  );

  let playerHasZeus = false;
  filteredInventory.forEach((item) => {
    if (item.includes('knife') || item.includes('bayonet')) {
      equipmentArray[2] = item;
    } else if (pistol.includes(item)) {
      if (
        filteredInventory.length === 3 &&
        !filteredInventory.includes('Zeus x27')
      ) {
        equipmentArray[1] = item;
      } else {
        equipmentArray[0] = item;
      }
    } else if (item.includes('Zeus x27')) {
      playerHasZeus = true;
    } else {
      equipmentArray[0] = item;
    }
  });

  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        borderRadius: 0,
        borderWidth: '2px',
        mb: 0.5,
        borderColor: isSelected ? teamColor : 'defaultColor',
      }}
      onClick={() => onToggleSelection(steamId)}
    >
      <CardContent sx={{ flex: '1 0 auto' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              width: '100%',
            }}
          >
            <Box sx={{ width: '100px' }}>
              <Typography
                sx={{
                  fontSize: '0.8rem',
                  fontWeight: 'bold',
                }}
              >
                {playerName.length > 15
                  ? `${playerName.slice(0, 12)}...`
                  : playerName}
              </Typography>
            </Box>
            <Box
              sx={{
                width: '55px',
                height: '20px',
                alignItems: 'center',
              }}
            >
              {playerInventory.includes('C4') && (
                <Tooltip title="Bomb" placement="top">
                  {mapNameToIcon('C4', 50, 20)}
                </Tooltip>
              )}
              {playerHasDefuser && (
                <Tooltip title="Defuse Kit" placement="top">
                  {mapNameToIcon('Defuse Kit', 50, 20)}
                </Tooltip>
              )}
              {playerHasZeus && mapNameToIcon('Zeus x27', 50, 20)}
            </Box>
            {playerKills > 0 && (
              <Tooltip title="Kills" placement="top">
                <Box
                  sx={{
                    width: '30px',
                    height: '20px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Skull width="20" height="16" />
                  <Typography
                    sx={{
                      fontSize: '0.8rem',
                      fontWeight: 'bold',
                    }}
                  >
                    {playerKills}
                  </Typography>
                </Box>
              </Tooltip>
            )}
          </Box>
          <Typography
            sx={{
              fontSize: '0.8rem',
              fontWeight: 'bold',
            }}
          >
            {`${playerMoney}$`}
          </Typography>
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            alignItems: 'center',
            gap: '4px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              width: '85%',
              gap: '2px',
            }}
          >
            <Box
              sx={{
                height: '10px',
                width: '100%',
                backgroundColor: 'transparent',
                overflow: 'hidden',
                border: '1px solid #ccc',
                borderColor: 'black',
              }}
            >
              <Box
                sx={{
                  height: '100%',
                  width: `${playerHealth}%`,
                  backgroundColor: teamColor,
                  transition: 'width 0.3s ease-in-out',
                }}
              />
            </Box>
          </Box>
          <Tooltip
            title={
              playerHasHelmet
                ? 'Armor & Helmet'
                : playerHasKevlar
                  ? 'Armor'
                  : ''
            }
          >
            <Box
              sx={{
                width: '7.5%',
                height: '20px',
                alignItems: 'center',
              }}
            >
              {(playerHasHelmet && mapNameToIcon('vesthelm', 20, 20)) ||
                (playerHasKevlar && mapNameToIcon('vest', 20, 20))}
            </Box>
          </Tooltip>
          <Box sx={{ width: '7.5%' }}>
            <Typography
              sx={{
                fontSize: '0.8rem',
                fontWeight: 'bold',
              }}
            >
              {playerHealth}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            height: '15px',
          }}
        >
          {equipmentArray.slice(0, 7).map((equipment, index) => (
            <Tooltip
              title={
                equipment && equipment.includes('knife_')
                  ? equipment
                      .replace('knife_', '')
                      .replace(/_/g, ' ')
                      .split(' ')
                      .map(
                        (word) => word.charAt(0).toUpperCase() + word.slice(1),
                      )
                      .join(' ')
                  : equipment
              }
              key={index}
            >
              <Box
                key={index}
                sx={{
                  width: index === 0 ? '25%' : '10%',
                  height: '30px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {equipment === playerActiveWeaponName
                  ? index === 0
                    ? pistol.includes(equipment)
                      ? mapNameToIcon(equipment, 50, 25, {
                          fill: teamColorDark,
                          filter: 'drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.5))',
                        })
                      : mapNameToIcon(equipment, 60, 30, {
                          fill: teamColorDark,
                          filter: 'drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.5))',
                        })
                    : mapNameToIcon(equipment, 50, 20, {
                        fill: teamColorDark,
                        filter: 'drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.5))',
                      })
                  : index === 0
                    ? pistol.includes(equipment)
                      ? mapNameToIcon(equipment, 50, 25, {
                          fill: '#575656',
                        })
                      : mapNameToIcon(equipment, 60, 30, {
                          fill: '#575656',
                        })
                    : mapNameToIcon(equipment, 50, 20, {
                        fill: '#575656',
                      })}
              </Box>
            </Tooltip>
          ))}
        </Box>
      </CardContent>
    </Card>
  );
}

PlayerCard.propTypes = {
  steamId: PropTypes.string.isRequired,
  playerName: PropTypes.string.isRequired,
  playerHealth: PropTypes.number.isRequired,
  playerMoney: PropTypes.number.isRequired,
  playerInventory: PropTypes.array.isRequired,
  playerHasKevlar: PropTypes.bool.isRequired,
  playerHasHelmet: PropTypes.bool.isRequired,
  playerHasDefuser: PropTypes.bool.isRequired,
  playerActiveWeaponName: PropTypes.string,
  playerKills: PropTypes.number.isRequired,
  isSelected: PropTypes.bool,
  teamColor: PropTypes.string.isRequired,
  teamColorDark: PropTypes.string.isRequired,
  onToggleSelection: PropTypes.func.isRequired,
};

// areEqual function for React.memo
function areEqual(prevProps, nextProps) {
  // Compare primitive props
  if (
    prevProps.steamId !== nextProps.steamId ||
    prevProps.playerName !== nextProps.playerName ||
    prevProps.playerTeam !== nextProps.playerTeam ||
    prevProps.playerHealth !== nextProps.playerHealth ||
    prevProps.playerMoney !== nextProps.playerMoney ||
    prevProps.playerIsAlive !== nextProps.playerIsAlive ||
    prevProps.playerHasKevlar !== nextProps.playerHasKevlar ||
    prevProps.playerHasHelmet !== nextProps.playerHasHelmet ||
    prevProps.playerHasDefuser !== nextProps.playerHasDefuser ||
    prevProps.playerActiveWeaponName !== nextProps.playerActiveWeaponName ||
    prevProps.playerKills !== nextProps.playerKills ||
    prevProps.isSelected !== nextProps.isSelected ||
    prevProps.teamColor !== nextProps.teamColor ||
    prevProps.teamColorDark !== nextProps.teamColorDark
  ) {
    return false;
  }

  // Deep comparison for playerInventory
  if (!prevProps.playerInventory || !nextProps.playerInventory) {
    return false;
  }
  if (prevProps.playerInventory.length !== nextProps.playerInventory.length) {
    return false;
  }
  for (let i = 0; i < prevProps.playerInventory.length; i += 1) {
    if (prevProps.playerInventory[i] !== nextProps.playerInventory[i]) {
      return false;
    }
  }

  // Compare function references
  if (prevProps.onToggleSelection !== nextProps.onToggleSelection) {
    return false;
  }

  // If we've made it here, all props are equal
  return true;
}

// Export the memoized component
export default React.memo(PlayerCard, areEqual);
