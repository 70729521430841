import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import axiosInstance from '../axiosInstance.js';
import { useAuth } from './AuthContext.js';

function computePlayers(roundData, mapData) {
  const team1 = mapData.winner;
  const team2 = mapData.loser;

  if (team1.name === roundData.winnerName) {
    team1.side = roundData.winnerSide;
    team2.side = roundData.loserSide;
  } else {
    team1.side = roundData.loserSide;
    team2.side = roundData.winnerSide;
  }

  const newPlayers = {};

  // Add players from team1 (winner)
  Object.entries(team1.players).forEach(([steamId, playerName]) => {
    newPlayers[steamId] = {
      name: playerName,
      team: team1.name,
      side: team1.side,
    };
  });

  // Add players from team2 (loser)
  Object.entries(team2.players).forEach(([steamId, playerName]) => {
    newPlayers[steamId] = {
      name: playerName,
      team: team2.name,
      side: team2.side,
    };
  });

  return newPlayers;
}

function mapEventsToTickIndices(events, ticks) {
  const tickIndices = {};
  events.forEach((event) => {
    const tickIndex = ticks.findIndex((tick) => tick._id === event.tick);
    if (tickIndex !== -1) {
      if (tickIndices[event.name]) {
        tickIndices[event.name].push({ tickIndex, event });
      } else {
        tickIndices[event.name] = [{ tickIndex, event }];
      }
    }
  });
  return tickIndices;
}

function computeGrenadeData(
  detonateEvents,
  expireEvents,
  players,
  lastTickIndex,
  duration = null,
) {
  return detonateEvents.map(
    ({ tickIndex: fromIndex, event: detonateEvent }) => {
      const expiredEvent = expireEvents
        ? expireEvents.find(
            ({ event }) =>
              event.customValues[0].thrower_steamid ===
                detonateEvent.customValues[0].thrower_steamid &&
              event.customValues[0].x === detonateEvent.customValues[0].x &&
              event.customValues[0].y === detonateEvent.customValues[0].y,
          )
        : null;

      const throwerSteamId = detonateEvent.customValues[0].thrower_steamid;
      const throwerSide = players[throwerSteamId]
        ? players[throwerSteamId].side
        : 'Unknown';

      let toIndex;
      if (expiredEvent) {
        toIndex = expiredEvent.tickIndex;
      } else if (duration) {
        toIndex = fromIndex + duration;
      } else {
        toIndex = lastTickIndex;
      }

      return {
        from: fromIndex,
        to: toIndex,
        x: detonateEvent.customValues[0].x,
        y: detonateEvent.customValues[0].y,
        side: throwerSide,
        duration: duration || toIndex - fromIndex,
      };
    },
  );
}

function computeMetadata(roundData, players) {
  const metadata = {};
  const eventMap = mapEventsToTickIndices(roundData.events, roundData.ticks);
  const lastTickIndex = roundData.ticks.length - 1;

  if (eventMap.bomb_planted) {
    const bombPlantTickIndex = eventMap.bomb_planted[0].tickIndex;
    const planter =
      eventMap.bomb_planted[0].event.customValues[0].player_steamid;
    const bombPlantTick = roundData.ticks[bombPlantTickIndex];
    metadata.bombPlant = {
      from: bombPlantTickIndex,
      to: eventMap.round_end[0].tickIndex,
      x: bombPlantTick.playerX[planter],
      y: bombPlantTick.playerY[planter],
      planter: eventMap.bomb_planted[0].event.customValues[0].player_name,
    };
  }
  if (eventMap.player_death) {
    metadata.playerDeaths = eventMap.player_death.map(
      ({ tickIndex, event }) => {
        const playerDeathTick = roundData.ticks[tickIndex];
        const victim = event.customValues[0].victim_steamid;
        return {
          from: tickIndex,
          to: eventMap.round_end[0].tickIndex,
          x: playerDeathTick.playerX[victim],
          y: playerDeathTick.playerY[victim],
          side: players[victim].side,
          victim: event.customValues[0].victim_name,
          attacker: event.customValues[0].attacker_name,
          weapon: event.customValues[0].weapon,
        };
      },
    );
  }

  if (eventMap.smokegrenade_detonate) {
    metadata.smokeGrenades = computeGrenadeData(
      eventMap.smokegrenade_detonate,
      eventMap.smokegrenade_expired,
      players,
      lastTickIndex,
    );
  }

  // Molotovs/Incendiaries
  if (eventMap.inferno_startburn) {
    metadata.molotovs = computeGrenadeData(
      eventMap.inferno_startburn,
      eventMap.inferno_expire,
      players,
      lastTickIndex,
    );
  }

  // Flashbangs
  if (eventMap.flashbang_detonate) {
    metadata.flashbangs = computeGrenadeData(
      eventMap.flashbang_detonate,
      null,
      players,
      lastTickIndex,
      8,
    ); // Flash lasts for 0.5 seconds (8 ticks)
  }

  // HE Grenades
  if (eventMap.hegrenade_detonate) {
    metadata.heGrenades = computeGrenadeData(
      eventMap.hegrenade_detonate,
      null,
      players,
      lastTickIndex,
      8,
    ); // Explosion lasts for 0.5 seconds (8 ticks)
  }

  return metadata;
}

// Create a context for match data
const MatchDataContext = createContext();

export function MatchDataProvider({ children }) {
  // get the current URL location to extract the map ID
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const [mapID, setMapID] = useState(urlParams.get('map') || null);
  // State for current map data, rounds, and ticks
  const [currentMapData, setCurrentMapData] = useState(null);
  const [selectedRounds, setSelectedRounds] = useState([]);
  const [currentRoundNumber, setCurrentRoundNumber] = useState(0);
  const [currentRoundData, setCurrentRoundData] = useState(null);
  const [currentTickIndex, setCurrentTickIndex] = useState(0);
  // State for player data
  const [players, setPlayers] = useState({});
  // State for in Team Info selected players
  const [selectedPlayers, setSelectedPlayers] = useState({});
  // State for metadata to draw stuff on the map, team info etc.
  const [metadata, setMetadata] = useState({});
  // State for error handling
  const [error, setError] = useState(null);
  // user object
  const { user, setUser } = useAuth();
  // drawings object
  const [drawings, setDrawings] = useState([]);
  // navigate function
  const navigate = useNavigate();

  // clear all drawings on round change
  useEffect(() => {
    setDrawings([]);
  }, [currentRoundNumber]);

  // Function to add a drawing
  const addDrawing = useCallback((tickIndex, drawingPoints) => {
    setDrawings((prevDrawings) => ({
      ...prevDrawings,
      [tickIndex]: [...(prevDrawings[tickIndex] || []), drawingPoints],
    }));
  }, []);

  // Function to get drawings for a specific tick
  const getDrawingsForTick = useCallback(
    (tickIndex) => drawings[tickIndex] || [],
    [drawings],
  );

  const deleteDrawing = useCallback((tickIndex) => {
    setDrawings((prevDrawings) => {
      const newDrawings = { ...prevDrawings };
      delete newDrawings[tickIndex];
      return newDrawings;
    });
  }, []);

  // if urlParams change, update mapID
  useEffect(() => {
    setMapID(urlParams.get('map') || null);
  }, [urlParams]);

  const handleError = useCallback((errorMessage) => {
    setError(errorMessage);
    console.error(errorMessage);
  }, []);

  // findTickIndeces function to find tick indices for a given event type
  const findTickIndeces = useCallback((eventType, round) => {
    const events = round.events.filter((e) => e.name === eventType);
    const ticks = events.map((e) => e.tick);

    return ticks.map((tick) => round.ticks.findIndex((t) => t._id === tick));
  }, []);

  // Fetch map data from the backend
  const fetchMapData = useCallback(async () => {
    if (mapID) {
      axiosInstance
        .get(`/api/maps/${mapID}`)
        .then((response) => {
          if (response.status === 200) {
            const mapData = response.data;
            setCurrentMapData(mapData);
            const roundsFromUrl = [...urlParams.entries()]
              .filter(([key]) => key.startsWith('round'))
              .map(([key]) => parseInt(key.replace('round', ''), 10))
              .sort((a, b) => a - b);

            if (roundsFromUrl.length === 0) {
              console.debug('No rounds found in URL, using all rounds');
              for (let i = 0; i < mapData.rounds.length; i += 1) {
                roundsFromUrl.push(mapData.rounds[i].roundNumber);
              }
            }
            setSelectedRounds(roundsFromUrl);
            if (roundsFromUrl.length > 0) {
              setCurrentRoundNumber(roundsFromUrl[0]);
            }
            console.debug('Map and round metadata fetched successfully');
            // delete currentRound and currentTick from last query
            const updatedParams = urlParams;
            if (updatedParams.has('currentRound')) {
              updatedParams.delete('currentRound');
            }
            if (updatedParams.has('currentTick')) {
              updatedParams.delete('currentTick');
            }
            const lastQuery = `?${updatedParams.toString()}`;
            axiosInstance

              .patch(`api/users/${user._id}`, { lastQuery })
              .then((r) => {
                if (r.status === 200) {
                  console.debug('User lastQuery updated successfully');
                  setUser(r.data.user);
                } else {
                  console.debug('Failed to update user lastQuery');
                }
              })
              .catch((err) => {
                console.debug(`Error updating user lastQuery: ${err.message}`);
              });
          } else {
            handleError('Failed to fetch map data');
          }
        })
        .catch(() => {
          // redirect to last match
          axiosInstance
            .get('/api/maps/last')
            .then((response) => {
              if (response.status === 200) {
                const lastMapID = response.data._id;
                navigate(`/replay?map=${lastMapID}`);
              } else {
                handleError('Failed to fetch last map');
              }
            })
            .catch((e) => {
              handleError(`Error fetching last map: ${e.message}`);
              navigate('/matches');
            });
        });
    } else if (user.lastQuery !== '') {
      // redirect to user query
      navigate(`/replay${user.lastQuery}`);
    } else {
      // redirect to last match
      axiosInstance
        .get('/api/maps/last')
        .then((response) => {
          if (response.status === 200) {
            const lastMapID = response.data._id;
            navigate(`/replay?map=${lastMapID}`);
          } else {
            handleError('Failed to fetch last map');
          }
        })
        .catch((err) => {
          handleError(`Error fetching last map: ${err.message}`);
          navigate('/dashboard');
        });
    }
  }, [mapID, urlParams, user.lastQuery]);

  // Fetch round data from the backend
  const fetchRoundData = useCallback(async () => {
    if (currentMapData && currentRoundNumber !== null) {
      setCurrentTickIndex(0);
      let roundIndex = currentRoundNumber - 1;
      if (currentMapData.rounds[0].roundNumber !== 1) {
        roundIndex = currentRoundNumber - 2;
      }

      const roundID = currentMapData.rounds[roundIndex]._id;
      console.debug(
        'Fetching round:',
        roundID,
        'with round number:',
        currentRoundNumber,
      );
      axiosInstance
        .get(`/api/rounds/${roundID}`)
        .then((response) => {
          if (response.status === 200) {
            const roundData = response.data;
            if (roundData.roundNumber === currentRoundNumber) {
              // Remove all ticks before round_freeze_end event
              roundData.ticks = roundData.ticks.slice(
                findTickIndeces('round_freeze_end', roundData)[0],
              );
              setCurrentRoundData(roundData);

              // set players
              const newPlayers = computePlayers(roundData, currentMapData);
              setPlayers(newPlayers);

              // compute metadata
              const newMetadata = computeMetadata(roundData, newPlayers);
              setMetadata(newMetadata);

              console.debug('Round fetched successfully');
            } else {
              handleError(
                'Fetched round number does not match expected round number',
              );
            }
          }
        })
        .catch((err) => {
          if (
            err.response &&
            err.response.status === 400 &&
            err.response.data.message === 'Round is null'
          ) {
            setSelectedRounds((prev) => {
              console.debug('Removing currentRoundNumber from selectedRounds');
              return prev.filter((r) => r !== currentRoundNumber);
            });
            setCurrentRoundNumber((prev) => {
              console.debug('Incrementing currentRoundNumber from:', prev);
              return prev + 1;
            });
          } else {
            handleError(`Error fetching round data: ${err.message}`);
          }
        });
    }
  }, [currentRoundNumber, players, metadata]);

  // export the context value with using useMemo
  const contextValue = useMemo(
    () => ({
      fetchMapData,
      fetchRoundData,
      currentMapData,
      currentRoundData,
      currentRoundNumber,
      setCurrentRoundNumber,
      currentTickIndex,
      setCurrentTickIndex,
      metadata,
      players,
      selectedPlayers,
      setSelectedPlayers,
      selectedRounds,
      error,
      setError,
      mapID,
      drawings,
      addDrawing,
      getDrawingsForTick,
      deleteDrawing,
    }),
    [
      fetchMapData,
      fetchRoundData,
      currentMapData,
      currentRoundData,
      currentRoundNumber,
      setCurrentRoundNumber,
      currentTickIndex,
      setCurrentTickIndex,
      metadata,
      players,
      selectedPlayers,
      setSelectedPlayers,
      selectedRounds,
      error,
      setError,
      mapID,
      drawings,
      addDrawing,
      getDrawingsForTick,
      deleteDrawing,
    ],
  );

  return (
    <MatchDataContext.Provider value={contextValue}>
      {children}
    </MatchDataContext.Provider>
  );
}

MatchDataProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

// Custom hook to use the MatchDataContext
export const useMatchData = () => useContext(MatchDataContext);

export default MatchDataContext;
