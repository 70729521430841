import { Button, Box, Drawer, Tooltip } from '@mui/material';
import { TextSnippet } from '@mui/icons-material';
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import NoteCardEditor from './NoteCardEditor.jsx';
import axiosInstance from '../../axiosInstance.js';
import { useMatchData } from '../../utils/MatchDataContext.js';
import { useAuth } from '../../utils/AuthContext.js';

function NotesDrawer({ setIsNotesDrawerOpen, note, setNote }) {
  const [open, setOpen] = useState(false);
  const [disableNotes, setDisableNotes] = useState(false);
  // prevent multiple fetches, else drawing will be duplicated
  let notesFetched = false;
  // url fetched
  const firstTime = useRef(false);
  // get match data
  const {
    currentRoundData,
    addDrawing,
    setCurrentTickIndex,
    setCurrentRoundNumber,
    currentMapData,
  } = useMatchData();
  const { user } = useAuth();

  // get share code from URL
  let shareCode = null;
  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has('shareCode')) {
    shareCode = urlParams.get('shareCode');
  }
  let tick = null;
  let round = null;
  if (urlParams.has('currentRound')) {
    round = urlParams.get('currentRound');
  }
  if (urlParams.has('currentTick')) {
    tick = urlParams.get('currentTick');
  }

  useEffect(() => {
    if (round) {
      console.debug('Setting round from URL:', round);
      if (!currentMapData) {
        return;
      }
      if (
        (currentMapData.rounds.length < parseInt(round, 10) &&
          currentMapData.rounds[0].roundNumber === 1) ||
        parseInt(round, 10) < 1
      ) {
        console.debug('Invalid round index from url');
        return;
      }
      if (
        currentMapData.rounds.length + 1 < parseInt(round, 10) &&
        currentMapData.rounds[0].roundNumber === 2
      ) {
        console.debug('Invalid round index from url');
        return;
      }
      if (
        currentMapData.rounds[0].roundNumber === 2 &&
        parseInt(round, 10) === 1
      ) {
        console.debug('Invalid round index from url');
        return;
      }
      // set round
      setCurrentRoundNumber(parseInt(round, 10));
    }
  }, [currentMapData]);

  useEffect(() => {
    if (tick) {
      let setTick = false;
      if (!currentRoundData) {
        return;
      }
      if (
        !firstTime.current &&
        currentRoundData.roundNumber === parseInt(round, 10)
      ) {
        firstTime.current = true;
        setTick = true;
      }
      if (currentRoundData.roundNumber !== parseInt(round, 10)) {
        console.debug(
          'Invalid round index from url',
          currentRoundData.roundNumber,
          parseInt(round, 10),
        );
        return;
      }
      if (!setTick) {
        return;
      }
      console.debug('Setting tick from URL:', tick);
      if (!currentRoundData) {
        return;
      }
      if (
        currentRoundData.ticks.length <= parseInt(tick, 10) ||
        parseInt(tick, 10) < 0
      ) {
        console.debug('Invalid tick index from url');
        return;
      }
      // set tick
      setCurrentTickIndex(parseInt(tick, 10));
    }
  }, [currentRoundData]);

  useEffect(() => {
    if (!user) return;
    if (!user.verified || user.subscription === 0) {
      setDisableNotes(true);
    } else {
      setDisableNotes(false);
    }
  }, [user]);

  const getTooltipTitle = () => {
    if (disableNotes) {
      if (!user.verified) {
        return 'Verify your email first';
      }
      if (user.subscription === 0) {
        return 'Subscribe to a plan to make notes';
      }
    }
    return '';
  };

  // Fetch notes for the current round
  useEffect(() => {
    if (!currentRoundData || !user || notesFetched) {
      return;
    }
    notesFetched = true;
    console.debug('Fetching notes for round:', currentRoundData._id);
    try {
      axiosInstance
        .get('/api/notes', {
          params: {
            round: currentRoundData._id,
            user: user._id,
            shareCode,
          },
        })
        .then((response) => {
          // set notes
          setNote(response.data);
          // set drawings
          if (response.data.drawings) {
            Object.entries(response.data.drawings).forEach(
              ([tickIndex, drawings]) => {
                drawings.forEach((drawing) => {
                  addDrawing(tickIndex, drawing);
                });
              },
            );
          }
        })
        .catch((error) => {
          if (error.response.status === 404) {
            console.debug('No notes found');
            setNote(null);
          } else {
            console.error('Error fetching notes');
            setNote(null);
          }
        });
    } catch (error) {
      console.error('Error fetching notes');
    }
  }, [currentRoundData, shareCode]);

  const handleOpen = () => {
    setOpen(true);
    setIsNotesDrawerOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setIsNotesDrawerOpen(false);
  };

  return (
    <>
      <Tooltip title={getTooltipTitle()} disableHoverListener={!disableNotes}>
        <span>
          <Button
            onClick={handleOpen}
            disabled={disableNotes}
            startIcon={<TextSnippet />}
          >
            Notes
          </Button>
        </span>
      </Tooltip>
      <Drawer
        anchor="right"
        open={open}
        onClose={handleClose}
        ModalProps={{
          BackdropProps: {
            style: {
              backgroundColor: 'transparent',
            },
          },
        }}
        PaperProps={{
          sx: {
            borderRadius: '10px 0 0 10px',
          },
        }}
      >
        <Box sx={{ width: 300, p: 1 }}>
          <NoteCardEditor shareCode={shareCode} setNote={setNote} note={note} />
        </Box>
      </Drawer>
    </>
  );
}

NotesDrawer.propTypes = {
  setIsNotesDrawerOpen: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  note: PropTypes.object,
  setNote: PropTypes.func.isRequired,
};

export default NotesDrawer;
