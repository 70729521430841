import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Paper,
  Tooltip,
  Typography,
} from '@mui/material';
import { Star } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import UpgradeDialog from './UpgradeDialog.jsx';
import { useAuth } from '../../utils/AuthContext.js';

function SubscriptionInfo() {
  const [isUpgradeDialogOpen, setIsUpgradeDialogOpen] = useState(false);
  const { user } = useAuth();

  const urlParams = new URLSearchParams(window.location.search);
  const upgradeParam = urlParams.has('upgrade');

  useEffect(() => {
    if (upgradeParam) {
      setIsUpgradeDialogOpen(true);
    }
  }, [upgradeParam]);

  const getSubscriptionText = (subscription) => {
    if (subscription >= 2) {
      return 'CLUB TIER';
    }
    if (subscription === 1) {
      return 'PRO TIER';
    }
    return 'FREE TIER';
  };

  const getSubscriptionDescription = (subscription) => {
    if (subscription >= 2) {
      return 'Tailored features for your needs';
    }
    if (subscription === 1) {
      return 'Full access with advanced features';
    }
    return 'Basic access to our services';
  };

  return (
    <Paper elevation={3} sx={{ padding: 3, marginTop: 1 }}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h6">Subscription</Typography>
      </Box>
      <Grid container spacing={2} sx={{ marginTop: 1 }}>
        <Grid item xs={12} sm={4}>
          <Card
            variant="outlined"
            sx={{
              boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
            }}
          >
            <CardContent>
              <Box display="flex" alignItems="center">
                <Star sx={{ color: '#FFD700', mr: 1 }} />
                <Typography variant="h6">Your subscription</Typography>
              </Box>
              <Typography variant="body2">
                You&apos;re currently enjoying our
              </Typography>
              <Typography
                variant="h5"
                component="p"
                fontWeight="bold"
                color="primary"
                sx={{ mt: 4.25, mb: 4.25 }}
              >
                {getSubscriptionText(user.subscription)}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {getSubscriptionDescription(user.subscription)}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Card
            variant="outlined"
            sx={{
              boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
            }}
          >
            <CardContent>
              <Typography variant="h6">Pro subscription</Typography>
              <Typography variant="body2" sx={{ marginBottom: 4 }}>
                Get access to all features and support the development of{' '}
                <b>DEFUZE</b>
              </Typography>

              <Typography variant="h7">
                {user.subscription <= 1
                  ? '€9.99 per month'
                  : 'Available through club subscription'}
              </Typography>
            </CardContent>
            {user.subscription === 1 ? (
              <Button
                variant="contained"
                // eslint-disable-next-line no-return-assign
                onClick={() =>
                  (window.location.href =
                    'https://billing.stripe.com/p/login/test_8wM005beObsq62YbII')
                }
                sx={{
                  mb: 2,
                  ml: 2,
                }}
              >
                Manage
              </Button>
            ) : (
              <Tooltip
                title="Verify your email to proceed"
                disableHoverListener={user.verified}
              >
                <span>
                  <Button
                    variant="contained"
                    disabled={user.subscription >= 1 || !user.verified}
                    onClick={() => setIsUpgradeDialogOpen(true)}
                    sx={{
                      mb: 2,
                      ml: 2,
                    }}
                  >
                    {user.subscription >= 2 ? 'Subscribed' : 'Upgrade'}
                  </Button>
                </span>
              </Tooltip>
            )}
          </Card>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Card
            variant="outlined"
            sx={{
              boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
            }}
          >
            <CardContent>
              <Typography variant="h6">Club subscription</Typography>
              <Typography variant="body2" sx={{ marginBottom: 6 }}>
                Custom subscription for professional teams, contact us for more
                information
              </Typography>
            </CardContent>
            <CardActions>
              <Button
                disabled={!user.verified}
                variant="contained"
                href="mailto:support@defuze.gg?subject=Club%20Subscription%20Inquiry&body=Hello%20DEFUZE%2C%0D%0A%0D%0AYour%20features%20are%20looking%20great!%20We%20are%20interested%20in%20a%20Club%20subscription%2C%20let's%20have%20a%20talk."
                sx={{
                  mb: 1,
                  ml: 1,
                }}
              >
                {user.subscription >= 2 ? 'Support' : 'Contact'}
              </Button>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
      <UpgradeDialog
        isUpgradeDialogOpen={isUpgradeDialogOpen}
        setIsUpgradeDialogOpen={setIsUpgradeDialogOpen}
      />
    </Paper>
  );
}

export default SubscriptionInfo;
