import { Box, Button, Grid, Paper, Typography } from '@mui/material';
import {
  BugReport,
  DeleteForever as DeleteForeverIcon,
  Help,
  Logout as LogoutIcon,
  Send as SendIcon,
  SyncLock,
} from '@mui/icons-material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../axiosInstance.js';
import NotificationSnackbar from '../common/NotificationSnackbar.jsx';
import PasswordChangeDialog from './PasswordChangeDialog.jsx';
import AccountDeletionDialog from './AccountDeletionDialog.jsx';
import { useAuth } from '../../utils/AuthContext.js';

async function logout(
  removeUser,
  setSnackbarMessage,
  setSnackbarOpen,
  setSnackbarSeverity,
  navigate,
) {
  try {
    // Send request to logout endpoint
    const response = await axiosInstance.post(
      '/api/users/logout',
      {},
      {
        withCredentials: true,
      },
    );
    if (response.status === 200 && response.data.message === 'Logged out') {
      // Remove tokens from local storage
      localStorage.removeItem('jwt-token');
      localStorage.removeItem('uploads');
      sessionStorage.removeItem('team1Filter');
      sessionStorage.removeItem('team2Filter');
      sessionStorage.removeItem('mapFilter');
      sessionStorage.removeItem('roundStatusRadio');
      sessionStorage.removeItem('tabSelection');
      sessionStorage.removeItem('checkedValues');
      sessionStorage.removeItem('sliderFilter');
      sessionStorage.removeItem('expandedAdditional');
      sessionStorage.removeItem('expandedWinner');
      sessionStorage.removeItem('expandedAi');
      sessionStorage.removeItem('expandedMoney');
      sessionStorage.removeItem('deviationSwitch');
      localStorage.removeItem('tutDashboard');
      localStorage.removeItem('tutMatches');
      localStorage.removeItem('tutReplay');
      localStorage.removeItem('tutDashboardSub');
      localStorage.removeItem('tutMatchesSub');
      removeUser();
      navigate('/');
    }
  } catch (error) {
    console.debug('Logout failed');
    setSnackbarMessage(
      'Failed to log out, please try again or contact support',
    );
    setSnackbarSeverity('error');
    setSnackbarOpen(true);
  }
}

function AccountManagement() {
  const [isAccountDeletionPopupOpen, setIsAccountDeletionPopupOpen] =
    useState(false);
  const [isPasswordPopupOpen, setIsPasswordPopupOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const { user, removeUser } = useAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    logout(
      removeUser,
      setSnackbarMessage,
      setSnackbarOpen,
      setSnackbarSeverity,
      navigate,
    ).then();
  };

  const sendEmailVerification = async () => {
    try {
      const response = await axiosInstance.post(
        '/api/users/request-verification',
      );
      if (
        response.status === 200 &&
        response.data.message === 'Verification email sent'
      ) {
        setSnackbarMessage('Verification email sent. Please check your inbox.');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.debug('Failed to send verification email');
      setSnackbarMessage('Failed to send verification email, please try again');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  return (
    <>
      <NotificationSnackbar
        open={snackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
        onClose={() => setSnackbarOpen(false)}
      />
      <PasswordChangeDialog
        isPasswordPopupOpen={isPasswordPopupOpen}
        setIsPasswordPopupOpen={setIsPasswordPopupOpen}
        setSnackbarMessage={setSnackbarMessage}
        setSnackbarOpen={setSnackbarOpen}
        setSnackbarSeverity={setSnackbarSeverity}
      />
      <AccountDeletionDialog
        isAccountDeletionPopupOpen={isAccountDeletionPopupOpen}
        setIsAccountDeletionPopupOpen={setIsAccountDeletionPopupOpen}
        setSnackbarMessage={setSnackbarMessage}
        setSnackbarOpen={setSnackbarOpen}
        setSnackbarSeverity={setSnackbarSeverity}
      />
      <Paper elevation={3} sx={{ padding: 3, marginTop: 1, marginBottom: 3 }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">Account management</Typography>
        </Box>
        <Grid container spacing={2} sx={{ marginTop: 1 }}>
          <Grid item xs={12} sm={12}>
            <Box display="flex" justifyContent="space-evenly">
              <Button
                type="submit"
                sx={{ mt: 3, mb: 2 }}
                startIcon={<BugReport />}
                href="mailto:support@defuze.gg?subject=Bug%20report&body=Hello%20DEFUZE%20Support%2C%0D%0A%0D%0AI%20have%20a%20encountered%20a%20bug.%20This%20is%20what%20happened:"
              >
                Report a bug
              </Button>
              <Button
                type="submit"
                sx={{ mt: 3, mb: 2 }}
                startIcon={<Help />}
                onClick={() => {
                  localStorage.setItem('tutDashboard', 'true');
                  localStorage.setItem('tutMatches', 'true');
                  localStorage.setItem('tutReplay', 'true');
                  setSnackbarMessage('Onboarding re-enabled');
                  setSnackbarSeverity('info');
                  setSnackbarOpen(true);
                }}
              >
                Show onboarding
              </Button>
              <Button
                type="submit"
                sx={{ mt: 3, mb: 2 }}
                startIcon={<DeleteForeverIcon />}
                onClick={() => setIsAccountDeletionPopupOpen(true)}
              >
                Delete account
              </Button>
              {user.verified ? (
                <Button
                  type="submit"
                  sx={{ mt: 3, mb: 2 }}
                  startIcon={<SyncLock />}
                  disabled={!user.verified}
                  onClick={() => setIsPasswordPopupOpen(true)}
                >
                  Change password
                </Button>
              ) : (
                <Button
                  type="submit"
                  sx={{ mt: 3, mb: 2 }}
                  startIcon={<SendIcon />}
                  onClick={() => {
                    sendEmailVerification();
                  }}
                >
                  Resend email verification
                </Button>
              )}
              <Button
                type="submit"
                sx={{ mt: 3, mb: 2 }}
                startIcon={<LogoutIcon />}
                onClick={handleLogout}
                variant="contained"
              >
                Log out
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}

export default AccountManagement;
