import React from 'react';
import { useLocation } from 'react-router-dom';
import NavigationRail from './navigation/NavigationRail';
import TopBarLandingPage from './navigation/TopBarLandingPage';
import TopBarSimple from './navigation/TopBarSimple';

function Navigation() {
  const location = useLocation();
  if (
    location.pathname === '/signin' ||
    location.pathname === '/signup' ||
    location.pathname === '/reset-password' ||
    location.pathname === '/forgot-password'
  ) {
    return <TopBarSimple />;
  }

  if (
    location.pathname === '/' ||
    location.pathname === '/pricing' ||
    location.pathname === '/help'
  ) {
    return <TopBarLandingPage />;
  }

  return <NavigationRail />;
}

export default Navigation;
