import React from 'react';
import { useStripe } from '@stripe/react-stripe-js';
import Button from '@mui/material/Button';
import { Tooltip } from '@mui/material';
import axiosInstance from '../../axiosInstance';
import { useAuth } from '../../utils/AuthContext.js';

function CheckoutForm() {
  const stripe = useStripe();
  const { user } = useAuth();

  const isDisabled = !user.verified || user.subscription !== 0;
  const tooltipTitle = isDisabled ? 'Verify your email to proceed' : '';

  const handleCheckout = async () => {
    try {
      const { data } = await axiosInstance.post(
        'api/actions/create-checkout-session',
      );

      const result = await stripe.redirectToCheckout({
        sessionId: data.sessionId,
      });

      if (result.error) {
        console.error(result.error.message);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <Tooltip title={tooltipTitle} disableHoverListener={!isDisabled}>
      <span>
        <Button
          disabled={isDisabled}
          variant="contained"
          color="primary"
          onClick={handleCheckout}
        >
          Pay with Stripe
        </Button>
      </span>
    </Tooltip>
  );
}

export default CheckoutForm;
