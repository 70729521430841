import {
  Box,
  Button,
  Collapse,
  Grid,
  IconButton,
  InputAdornment,
  Slider,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  ExpandMore,
  Info as InfoIcon,
  KeyboardArrowRight,
  Psychology,
  Start,
} from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as C4 } from '../replay/assets/weapons/utility/weapon_c4.svg';

function AiFilter({
  deviationSwitch,
  setDeviationSwitch,
  sliderValues,
  setSliderValues,
  mouseUp,
  setMouseUp,
}) {
  // State to manage the expanded section
  const [expanded, setExpanded] = useState(
    JSON.parse(sessionStorage.getItem('expandedAi')) || false,
  );
  const [fromValue, setFromValue] = useState(sliderValues.deviation[0] || 0);
  const [toValue, setToValue] = useState(sliderValues.deviation[1] || 0);

  // save the filters in the session storage
  useEffect(() => {
    sessionStorage.setItem('expandedAi', expanded);
  }, [expanded]);

  // set from/to values
  useEffect(() => {
    setFromValue(sliderValues.deviation[0]);
    setToValue(sliderValues.deviation[1]);
  }, [sliderValues.deviation]);

  const updateSliderValues = (from, to) => {
    setSliderValues((prev) => ({
      ...prev,
      deviation: [from, to],
    }));
    setMouseUp(!mouseUp);
  };

  const handleSliderChange = (event, newValue) => {
    setFromValue(newValue[0]);
    setToValue(newValue[1]);
  };

  return (
    <>
      <Button
        variant="text"
        fullWidth
        onClick={() => setExpanded(!expanded)}
        sx={{
          justifyContent: 'flex-start',
          color: 'black',
          fontSize: '1rem',
        }}
        startIcon={expanded ? <ExpandMore /> : <KeyboardArrowRight />}
      >
        <Box display="flex" alignItems="center">
          <Psychology sx={{ mr: 1 }} />
          AI
        </Box>
      </Button>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Grid container alignItems="center">
          <Grid item xs={12} sm={8}>
            <Typography
              variant="subtitle2"
              sx={{ ml: 1, mt: 1, textDecoration: 'underline' }}
            >
              Prediction
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4} container justifyContent="flex-end">
            <Tooltip title="Select the prediction to apply the values to">
              <IconButton size="small">
                <InfoIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={12} sm={12} container justifyContent="center">
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Typography variant="body2" sx={{ mr: 1 }}>
                Round Start
              </Typography>
              <Start sx={{ width: '20px', height: '20px' }} />
              <Switch
                checked={deviationSwitch}
                onChange={() => setDeviationSwitch(!deviationSwitch)}
                sx={{
                  '& .MuiSwitch-switchBase': {
                    '&:not(.Mui-checked)': {
                      color: 'primary.main',
                    },
                  },
                  '& .MuiSwitch-track': {
                    backgroundColor: 'primary.main',
                    opacity: 0.5,
                    '&.Mui-checked': {
                      opacity: 0.5,
                    },
                  },
                }}
              />
              <C4 style={{ width: '20px', height: '20px' }} />
              <Typography variant="body2" sx={{ ml: 1 }}>
                Bomb Plant
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Typography
              variant="subtitle2"
              sx={{ ml: 1, textDecoration: 'underline' }}
            >
              Deviation
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4} container justifyContent="flex-end">
            <Tooltip title="The higher the value, the more the AI deviates from the actual result (round win/loss)">
              <IconButton size="small">
                <InfoIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            container
            justifyContent="space-between"
            alignItems="center"
            sx={{ mt: 1, mb: 1 }}
          >
            <TextField
              value={fromValue}
              label="from"
              onChange={(e) => {
                const { value } = e.target;
                if (value === '' || /^[0-9]+$/.test(value)) {
                  if (value === '') {
                    setFromValue('');
                  } else {
                    const numValue = Math.min(100, parseInt(value, 10));
                    setFromValue(numValue.toString());
                    updateSliderValues(numValue, toValue);
                  }
                }
              }}
              onBlur={() => {
                if (fromValue === '') {
                  setFromValue('0');
                  updateSliderValues(0, toValue);
                }
              }}
              sx={{
                ml: 2,
                width: '22.5%',
                '& .MuiInputBase-root': { height: '35px' },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    sx={{
                      marginRight: '-5px',
                    }}
                  >
                    %
                  </InputAdornment>
                ),
                inputMode: 'numeric',
                pattern: '[0-9]*',
              }}
            />
            <Slider
              value={[fromValue, toValue]}
              valueLabelDisplay="off"
              min={0}
              max={100}
              onChange={handleSliderChange}
              onChangeCommitted={(e, newValue) => {
                updateSliderValues(newValue[0], newValue[1]);
              }}
              sx={{
                width: '35%',
                '& .MuiSlider-rail': {
                  height: 3,
                },
                '& .MuiSlider-track': {
                  height: 3,
                },
                '& .MuiSlider-thumb': {
                  width: 14,
                  height: 14,
                },
              }}
            />
            <TextField
              value={toValue}
              label="to"
              onChange={(e) => {
                const { value } = e.target;
                if (value === '' || /^[0-9]+$/.test(value)) {
                  if (value === '') {
                    setToValue('');
                  } else {
                    const numValue = Math.min(100, parseInt(value, 10));
                    setToValue(numValue.toString());
                    updateSliderValues(fromValue, numValue);
                  }
                }
              }}
              onBlur={() => {
                if (toValue === '') {
                  setToValue('100');
                  updateSliderValues(fromValue, 100);
                }
              }}
              sx={{
                mr: 2,
                width: '22.5%',
                '& .MuiInputBase-root': { height: '35px' },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    sx={{
                      marginRight: '-5px',
                    }}
                  >
                    %
                  </InputAdornment>
                ),
                inputMode: 'numeric',
                pattern: '[0-9]*',
              }}
            />
          </Grid>
        </Grid>
      </Collapse>
    </>
  );
}

AiFilter.propTypes = {
  deviationSwitch: PropTypes.bool.isRequired,
  setDeviationSwitch: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  sliderValues: PropTypes.object.isRequired,
  setSliderValues: PropTypes.func.isRequired,
  mouseUp: PropTypes.bool.isRequired,
  setMouseUp: PropTypes.func.isRequired,
};

export default AiFilter;
