import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Collapse,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  AttachMoney,
  ExpandMore,
  Info as InfoIcon,
  KeyboardArrowRight,
} from '@mui/icons-material';
import PropTypes from 'prop-types';
import RangeSlider from './RangeSlider.jsx';
import RangeInput from './RangeInput.jsx';

function MoneyFilter({
  sliderValues,
  setSliderValues,
  setMouseUp,
  mouseUp,
  team1Filter,
  team2Filter,
}) {
  // State to manage the expanded section
  const [expanded, setExpanded] = useState(
    JSON.parse(sessionStorage.getItem('expandedMoney')) || false,
  );

  // save the filters in the session storage
  useEffect(() => {
    sessionStorage.setItem('expandedMoney', expanded);
  }, [expanded]);

  // Function to handle slider value change
  const handleSliderChange = (name) => (event, newValue) => {
    setSliderValues((prevValues) => ({
      ...prevValues,
      [name]: newValue,
    }));
  };

  const handleInputChange = (name, index) => (value) => {
    let newValue = value;
    if (value > 16000) {
      newValue = 16000;
    }
    if (value < 0) {
      newValue = 0;
    }
    setSliderValues((prevValues) => ({
      ...prevValues,
      [name]: [
        ...prevValues[name].slice(0, index),
        parseInt(newValue, 10),
        ...prevValues[name].slice(index + 1),
      ],
    }));
    setMouseUp(!mouseUp);
  };

  return (
    <>
      <Button
        variant="text"
        fullWidth
        onClick={() => setExpanded(!expanded)}
        sx={{
          justifyContent: 'flex-start',
          color: 'black',
          fontSize: '1rem',
        }}
        startIcon={expanded ? <ExpandMore /> : <KeyboardArrowRight />}
      >
        <Box display="flex" alignItems="center">
          <AttachMoney sx={{ mr: 1 }} />
          Money
        </Box>
      </Button>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Grid container alignItems="center">
          <Grid item xs={12} sm={12}>
            <Typography
              variant="subtitle2"
              sx={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                textDecoration: 'underline',
                ml: 1,
                mt: 1,
                mb: 2,
              }}
            >
              {team1Filter !== '' ? team1Filter : 'Home'}
            </Typography>
          </Grid>
          <RangeInput
            label="Start balance"
            value1={sliderValues.team1Start[0]}
            value2={sliderValues.team1Start[1]}
            onChange1={handleInputChange('team1Start', 0)}
            onChange2={handleInputChange('team1Start', 1)}
          />
          <Grid item xs={12} sm={1.5}>
            <Tooltip title="Average cash balance per player at round start">
              <IconButton size="small" sx={{ ml: 2, mb: 2 }}>
                <InfoIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={12} sm={10.5} container justifyContent="center">
            <Box sx={{ width: '80%', maxWidth: '500px', mb: 1 }}>
              <RangeSlider
                value={(() => sliderValues.team1Start)()}
                sliderOnChange={((newValue) =>
                  handleSliderChange('team1Start', newValue))()}
                mouseUp={mouseUp}
                setMouseUp={setMouseUp}
                min={0}
                max={16000}
              />
            </Box>
          </Grid>
          <RangeInput
            label="Money spent"
            value1={sliderValues.team1Spent[0]}
            value2={sliderValues.team1Spent[1]}
            onChange1={handleInputChange('team1Spent', 0)}
            onChange2={handleInputChange('team1Spent', 1)}
          />
          <Grid item xs={12} sm={1.5}>
            <Tooltip title="Average money spent per player">
              <IconButton size="small" sx={{ ml: 2, mb: 2 }}>
                <InfoIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={12} sm={10.5} container justifyContent="center">
            <Box sx={{ width: '80%', maxWidth: '500px', mb: 1 }}>
              <RangeSlider
                value={(() => sliderValues.team1Spent)()}
                sliderOnChange={((newValue) =>
                  handleSliderChange('team1Spent', newValue))()}
                mouseUp={mouseUp}
                setMouseUp={setMouseUp}
                min={0}
                max={16000}
              />
            </Box>
          </Grid>
          <RangeInput
            label="Equipment start"
            value1={sliderValues.team1EquipStart[0]}
            value2={sliderValues.team1EquipStart[1]}
            onChange1={handleInputChange('team1EquipStart', 0)}
            onChange2={handleInputChange('team1EquipStart', 1)}
          />
          <Grid item xs={12} sm={1.5}>
            <Tooltip title="Average equipment value per player at round start">
              <IconButton size="small" sx={{ ml: 2, mb: 2 }}>
                <InfoIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={12} sm={10.5} container justifyContent="center">
            <Box sx={{ width: '80%', maxWidth: '500px', mb: 1 }}>
              <RangeSlider
                value={(() => sliderValues.team1EquipStart)()}
                sliderOnChange={((newValue) =>
                  handleSliderChange('team1EquipStart', newValue))()}
                mouseUp={mouseUp}
                setMouseUp={setMouseUp}
                min={0}
                max={8000}
              />
            </Box>
          </Grid>
          <RangeInput
            label="Equipment freeze"
            value1={sliderValues.team1EquipFreeze[0]}
            value2={sliderValues.team1EquipFreeze[1]}
            onChange1={handleInputChange('team1EquipFreeze', 0)}
            onChange2={handleInputChange('team1EquipFreeze', 1)}
          />
          <Grid item xs={12} sm={1.5}>
            <Tooltip title="Average equipment value per player at freeze end">
              <IconButton size="small" sx={{ ml: 2, mb: 2 }}>
                <InfoIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={12} sm={10.5} container justifyContent="center">
            <Box sx={{ width: '80%', maxWidth: '500px', mb: 1 }}>
              <RangeSlider
                value={(() => sliderValues.team1EquipFreeze)()}
                sliderOnChange={((newValue) =>
                  handleSliderChange('team1EquipFreeze', newValue))()}
                mouseUp={mouseUp}
                setMouseUp={setMouseUp}
                min={0}
                max={8000}
              />
            </Box>
          </Grid>

          <Grid item xs={12} sm={12} sx={{ mt: 2 }}>
            <Typography
              variant="subtitle2"
              sx={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                textDecoration: 'underline',
                ml: 1,
                mb: 2,
              }}
            >
              {team2Filter !== '' ? team2Filter : 'Away'}
            </Typography>
          </Grid>
          <RangeInput
            label="Start balance"
            value1={sliderValues.team2Start[0]}
            value2={sliderValues.team2Start[1]}
            onChange1={handleInputChange('team2Start', 0)}
            onChange2={handleInputChange('team2Start', 1)}
          />
          <Grid item xs={12} sm={1.5}>
            <Tooltip title="Average cash balance per player at round start">
              <IconButton size="small" sx={{ ml: 2, mb: 2 }}>
                <InfoIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={12} sm={10.5} container justifyContent="center">
            <Box sx={{ width: '80%', maxWidth: '500px', mb: 1 }}>
              <RangeSlider
                value={(() => sliderValues.team2Start)()}
                sliderOnChange={((newValue) =>
                  handleSliderChange('team2Start', newValue))()}
                mouseUp={mouseUp}
                setMouseUp={setMouseUp}
                min={0}
                max={16000}
              />
            </Box>
          </Grid>
          <RangeInput
            label="Money spent"
            value1={sliderValues.team2Spent[0]}
            value2={sliderValues.team2Spent[1]}
            onChange1={handleInputChange('team2Spent', 0)}
            onChange2={handleInputChange('team2Spent', 1)}
          />
          <Grid item xs={12} sm={1.5}>
            <Tooltip title="Average money spent per player">
              <IconButton size="small" sx={{ ml: 2, mb: 2 }}>
                <InfoIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={12} sm={10.5} container justifyContent="center">
            <Box sx={{ width: '80%', maxWidth: '500px', mb: 1 }}>
              <RangeSlider
                value={(() => sliderValues.team2Spent)()}
                sliderOnChange={((newValue) =>
                  handleSliderChange('team2Spent', newValue))()}
                mouseUp={mouseUp}
                setMouseUp={setMouseUp}
                min={0}
                max={16000}
              />
            </Box>
          </Grid>
          <RangeInput
            label="Equipment start"
            value1={sliderValues.team2EquipStart[0]}
            value2={sliderValues.team2EquipStart[1]}
            onChange1={handleInputChange('team2EquipStart', 0)}
            onChange2={handleInputChange('team2EquipStart', 1)}
          />
          <Grid item xs={12} sm={1.5}>
            <Tooltip title="Average equipment value per player at round start">
              <IconButton size="small" sx={{ ml: 2, mb: 2 }}>
                <InfoIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={12} sm={10.5} container justifyContent="center">
            <Box sx={{ width: '80%', maxWidth: '500px', mb: 1 }}>
              <RangeSlider
                value={(() => sliderValues.team2EquipStart)()}
                sliderOnChange={((newValue) =>
                  handleSliderChange('team2EquipStart', newValue))()}
                mouseUp={mouseUp}
                setMouseUp={setMouseUp}
                min={0}
                max={8000}
              />
            </Box>
          </Grid>
          <RangeInput
            label="Equipment freeze"
            value1={sliderValues.team2EquipFreeze[0]}
            value2={sliderValues.team2EquipFreeze[1]}
            onChange1={handleInputChange('team2EquipFreeze', 0)}
            onChange2={handleInputChange('team2EquipFreeze', 1)}
          />
          <Grid item xs={12} sm={1.5}>
            <Tooltip title="Average equipment value per player at freeze end">
              <IconButton size="small" sx={{ ml: 2, mb: 2 }}>
                <InfoIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={12} sm={10.5} container justifyContent="center">
            <Box sx={{ width: '80%', maxWidth: '500px', mb: 1 }}>
              <RangeSlider
                value={(() => sliderValues.team2EquipFreeze)()}
                sliderOnChange={((newValue) =>
                  handleSliderChange('team2EquipFreeze', newValue))()}
                mouseUp={mouseUp}
                setMouseUp={setMouseUp}
                min={0}
                max={8000}
              />
            </Box>
          </Grid>
        </Grid>
      </Collapse>
    </>
  );
}

MoneyFilter.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  sliderValues: PropTypes.object.isRequired,
  setSliderValues: PropTypes.func.isRequired,
  setMouseUp: PropTypes.func.isRequired,
  mouseUp: PropTypes.bool.isRequired,
  team1Filter: PropTypes.string.isRequired,
  team2Filter: PropTypes.string.isRequired,
};

export default MoneyFilter;
