import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import axiosInstance from '../axiosInstance.js';
import { useAuth } from './AuthContext.js';
import Loading from '../components/common/Loading.jsx';

/**
 * ProtectedRoute component
 * @param {Object} children - React component to render
 * @param protectedRoute - Boolean to determine if route is protected
 * @returns {Object} React component
 * @description Route that requires authentication (a signed-in user) to access
 * Uses the API to validate the access token and refresh it if necessary
 * Redirects to /signin if token is invalid or expired
 */
function ProtectedRoute({ children, protectedRoute = true }) {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const token = localStorage.getItem('jwt-token');
  const { setUser } = useAuth();

  const checkForNullValues = (obj) =>
    Object.entries(obj).some(
      ([key, value]) =>
        key !== 'preferredTeam' && (value === null || value === undefined),
    );

  useEffect(() => {
    const validateToken = async () => {
      // if token exists, validate it
      if (token) {
        try {
          const response = await axiosInstance.post(
            '/api/users/validate-token',
            {},
          );
          if (
            response.status === 200 &&
            response.data.message === 'Token is valid'
          ) {
            axiosInstance
              .get(`/api/users/${response.data.user}`)
              .then((res) => {
                if (res.status === 200) {
                  if (checkForNullValues(res.data)) {
                    setIsAuthenticated(false);
                    return;
                  }
                  setUser(res.data);
                  setIsAuthenticated(true);
                }
              })
              .catch((error) => {
                setIsAuthenticated(false);
                console.log('Failed to get user data', error);
              });
            return;
          }
        } catch (error) {
          console.log('Token is invalid or expired!', error);
        }
      }
      // if token does not exist or is invalid, try to refresh it
      try {
        const data = await axiosInstance.post(
          '/api/users/refresh-token',
          {},
          { withCredentials: true },
        );
        if (data.status === 200) {
          localStorage.setItem('jwt-token', data.data.token);
          axiosInstance
            .get(`/api/users/${data.data.userId}`)
            .then((res) => {
              if (res.status === 200) {
                if (checkForNullValues(res.data)) {
                  setIsAuthenticated(false);
                  return;
                }
                setUser(res.data);
                setIsAuthenticated(true);
              }
            })
            .catch((error) => {
              setIsAuthenticated(false);
              console.log('Failed to get user data', error);
            });
          return;
        }
      } catch (error) {
        console.debug(
          'Failed to refresh token (could be that no refresh token is available)',
        );
      }
      setIsAuthenticated(false);
    };
    validateToken().then();
    // could also omit the token dependency and run the function when the component mounts
    // as the token is only set once when the user logs in
  }, [token]);

  // is authenticated is null while checking token, false if token is invalid, true if token is valid
  if (isAuthenticated === null) {
    return <Loading />;
  }
  if (isAuthenticated === false) {
    if (protectedRoute) {
      return <Navigate to="/signin" />;
    }
    return children;
  }
  if (isAuthenticated === true) {
    if (protectedRoute) {
      return children;
    }
    return <Navigate to="/dashboard" />;
  }
}

ProtectedRoute.propTypes = {
  children: PropTypes.node.isRequired,
  protectedRoute: PropTypes.bool,
};

export default ProtectedRoute;
