import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../axiosInstance.js';
import { useAuth } from '../../utils/AuthContext.js';

async function deleteAccount(
  user,
  removeUser,
  setSnackbarMessage,
  setSnackbarSeverity,
  setSnackbarOpen,
  navigate,
) {
  try {
    const response = await axiosInstance.delete(`api/users/${user._id}`, {
      withCredentials: true,
    });
    if (
      response.status === 200 &&
      response.data.message === 'User successfully deleted'
    ) {
      // Remove tokens from local storage
      localStorage.removeItem('jwt-token');
      removeUser();
      setSnackbarMessage(
        'Account successfully deleted. Redirecting to home page...',
      );
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      setTimeout(() => {
        navigate('/');
      }, 5000);
    }
  } catch (error) {
    console.debug('Delete account failed');
    setSnackbarMessage('Failed to delete account. Please try again.');
    setSnackbarSeverity('error');
    setSnackbarOpen(true);
  }
}

function AccountDeletionDialog({
  isAccountDeletionPopupOpen,
  setIsAccountDeletionPopupOpen,
  setSnackbarMessage,
  setSnackbarSeverity,
  setSnackbarOpen,
}) {
  const { user, removeUser } = useAuth();
  const navigate = useNavigate();

  const handleDeleteAccount = () => {
    setIsAccountDeletionPopupOpen(false);
    deleteAccount(
      user,
      removeUser,
      setSnackbarMessage,
      setSnackbarSeverity,
      setSnackbarOpen,
      navigate,
    ).then();
  };

  return (
    <Dialog
      open={isAccountDeletionPopupOpen}
      onClose={() => setIsAccountDeletionPopupOpen(false)}
    >
      <DialogTitle>Delete account?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Your account will be permanently deleted. This action cannot be
          undone.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => setIsAccountDeletionPopupOpen(false)}
          sx={{ mb: 1 }}
        >
          Cancel
        </Button>
        <Button
          color="error"
          variant="contained"
          onClick={handleDeleteAccount}
          sx={{ mb: 1, mr: 1 }}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}

AccountDeletionDialog.propTypes = {
  isAccountDeletionPopupOpen: PropTypes.bool.isRequired,
  setIsAccountDeletionPopupOpen: PropTypes.func.isRequired,
  setSnackbarMessage: PropTypes.func.isRequired,
  setSnackbarSeverity: PropTypes.func.isRequired,
  setSnackbarOpen: PropTypes.func.isRequired,
};

export default AccountDeletionDialog;
