import { Box, Link, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

function BlurredBox({ text }) {
  const navigate = useNavigate();
  const handleSubscribeClick = () => {
    navigate('/profile?upgrade');
  };

  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          display: 'inline-flex',
          flexWrap: 'wrap',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="body1" component="span" fontWeight="bold">
          For {text} please
        </Typography>
        <Box
          component="span"
          sx={{ display: 'inline-block', width: '0.25em' }}
        />
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <Link
          component="button"
          variant="body1"
          fontWeight="bold"
          onClick={handleSubscribeClick}
          sx={{
            color: 'light.primary.main',
            position: 'relative',
            backgroundColor: 'transparent',
            textDecoration: 'none',
            '&:hover': {
              backgroundColor: 'transparent',
              textDecoration: 'none',
            },
            '&::after': {
              content: '""',
              position: 'absolute',
              width: '100%',
              transform: 'scaleX(0)',
              height: '2px',
              bottom: 0,
              left: 0,
              backgroundColor: 'primary.main',
              transformOrigin: 'center',
              transition: 'transform 0.25s ease-out',
            },
            '&:hover::after': {
              transform: 'scaleX(1)',
              transformOrigin: 'center',
            },
          }}
        >
          subscribe to a plan
        </Link>
      </Box>
    </Box>
  );
}

BlurredBox.propTypes = {
  text: PropTypes.string.isRequired,
};

export default BlurredBox;
