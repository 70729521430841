import React, { useState } from 'react';
import {
  Paper,
  Box,
  Typography,
  Grid,
  TextField,
  IconButton,
} from '@mui/material';
import { Save as SaveIcon, Edit as EditIcon } from '@mui/icons-material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axiosInstance from '../../axiosInstance';
import { useAuth } from '../../utils/AuthContext.js';
import NotificationSnackbar from '../common/NotificationSnackbar.jsx';

const validationSchema = Yup.object({
  firstName: Yup.string().matches(/^[a-zA-Z]*$/, 'Only letters are allowed'),
  lastName: Yup.string().matches(/^[a-zA-Z]*$/, 'Only letters are allowed'),
  username: Yup.string()
    .required('Username is required')
    .matches(
      /^[a-zA-Z0-9_-]+$/,
      'Only letters, numbers, hyphens, and underscores are allowed',
    )
    .min(4, 'Username must be at least 4 characters long'),
});

function GeneralInfo() {
  const [isEditing, setIsEditing] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const { user, setUser } = useAuth();

  const handleSaveClick = async (values) => {
    try {
      if (
        // eslint-disable-next-line no-use-before-define
        user.firstName === formik.values.firstName &&
        // eslint-disable-next-line no-use-before-define
        user.lastName === formik.values.lastName &&
        // eslint-disable-next-line no-use-before-define
        user.username === formik.values.username
      ) {
        setIsEditing(false);
        return;
      }
      // Send request to patch users endpoint
      const userId = user._id;
      const response = await axiosInstance.patch(
        `/api/users/${userId}`,
        values,
      );
      if (
        response.status === 200 &&
        response.data.message === 'User successfully updated'
      ) {
        setUser(response.data.user);
        setIsEditing(false);
        setSnackbarMessage('Information successfully updated');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.debug('Update user failed');
      setSnackbarMessage('Failed to update information, please try again');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const formik = useFormik({
    initialValues: {
      firstName: user.firstName || '',
      lastName: user.lastName || '',
      username: user.username || '',
      email: user.email || '',
    },
    validationSchema,
    onSubmit: handleSaveClick,
  });

  return (
    <>
      <NotificationSnackbar
        open={snackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
        autoHideDuration={4000}
        onClose={() => setSnackbarOpen(false)}
      />
      <Paper elevation={3} sx={{ padding: 3, marginTop: 3 }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">General</Typography>
          <IconButton
            onClick={isEditing ? formik.handleSubmit : () => setIsEditing(true)}
          >
            {isEditing ? <SaveIcon /> : <EditIcon />}
          </IconButton>
        </Box>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2} sx={{ marginTop: 1 }}>
            <Grid item xs={12} sm={3}>
              <TextField
                label="First name"
                name="firstName"
                value={formik.values.firstName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.firstName && Boolean(formik.errors.firstName)
                }
                helperText={formik.touched.firstName && formik.errors.firstName}
                fullWidth
                disabled={!isEditing}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Last name"
                name="lastName"
                value={formik.values.lastName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.lastName && Boolean(formik.errors.lastName)
                }
                helperText={formik.touched.lastName && formik.errors.lastName}
                fullWidth
                disabled={!isEditing}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Username"
                name="username"
                value={formik.values.username}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.username && Boolean(formik.errors.username)
                }
                helperText={formik.touched.username && formik.errors.username}
                fullWidth
                disabled={!isEditing}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Email"
                name="email"
                value={formik.values.email}
                fullWidth
                disabled
              />
            </Grid>
            {user && (
              <Grid item xs={12} sm={12}>
                <Box display="flex">
                  <Typography
                    variant="caption"
                    sx={{ color: 'text.secondary', fontSize: '0.75rem' }}
                  >
                    Last login:{' '}
                    {new Date(user.lastLoginDate).toLocaleDateString('en-US', {
                      year: 'numeric',
                      month: 'short',
                      day: 'numeric',
                      hour: '2-digit',
                      minute: '2-digit',
                      hour12: true,
                    })}
                  </Typography>
                  <Typography
                    variant="caption"
                    sx={{ color: 'text.secondary', fontSize: '0.75rem', ml: 2 }}
                  >
                    Account created:{' '}
                    {new Date(user.creationDate).toLocaleDateString('en-US', {
                      year: 'numeric',
                      month: 'short',
                      day: 'numeric',
                      hour: '2-digit',
                      minute: '2-digit',
                      hour12: true,
                    })}
                  </Typography>
                </Box>
              </Grid>
            )}
          </Grid>
        </form>
      </Paper>
    </>
  );
}

export default GeneralInfo;
