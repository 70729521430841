import React from 'react';
import {
  Container,
  Typography,
  Grid,
  Paper,
  Button,
  Box,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { Link } from 'react-router-dom';
import CheckIcon from '@mui/icons-material/Check';
import AddIcon from '@mui/icons-material/Add';

const checkIconStyle = {
  fontSize: 30,
  display: 'block',
  margin: '0 auto',
};

function Pricing() {
  return (
    <Container>
      <Typography variant="h4" align="center">
        <strong>Pricing</strong>
      </Typography>
      <Box mt={1}>
        <Typography align="center" gutterBottom>
          Have a look at our plans with their different features
        </Typography>
      </Box>
      <Box mt={4} mb={4}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={4}>
            <Paper elevation={3} style={{ padding: '20px' }}>
              <Typography variant="h5" align="center">
                <strong>Free</strong>
              </Typography>
              <Typography mb={5} align="center">
                Free of charge
              </Typography>
              <Divider variant="middle" />
              <List>
                <ListItem>
                  <ListItemIcon>
                    <CheckIcon style={checkIconStyle} />
                  </ListItemIcon>
                  <ListItemText
                    primary="2D Replay"
                    secondary="Analyze replays of selected professional matches"
                  />
                </ListItem>
              </List>
              <Box mt={67.5} align="center">
                <Link to="/signup">
                  <Button color="primary" style={{ width: '180px' }}>
                    Get started
                  </Button>
                </Link>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Paper elevation={3} style={{ padding: '20px' }}>
              <Typography variant="h5" align="center">
                <strong>Pro</strong>
              </Typography>
              <Typography align="center" mb={5}>
                9.99€/month
              </Typography>
              <Divider variant="middle" />
              <List>
                <ListItem>
                  <ListItemIcon>
                    <CheckIcon style={checkIconStyle} />
                  </ListItemIcon>
                  <ListItemText
                    primary="2D Replay"
                    secondary="Analyze replays of public and your own matches, take notes, and share them with others"
                  />
                </ListItem>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                    margin: '20px 0',
                    position: 'relative',
                  }}
                >
                  <Box
                    sx={{
                      width: '75%',
                      height: 1.5,
                      bgcolor: 'divider',
                    }}
                  />
                  <AddIcon
                    sx={{
                      position: 'absolute',
                      fontSize: 35,
                      bgcolor: 'white',
                      borderRadius: '70%',
                      padding: '2px',
                      color: 'primary.main',
                    }}
                  />
                </Box>
                <ListItem>
                  <ListItemIcon>
                    <CheckIcon style={checkIconStyle} />
                  </ListItemIcon>
                  <ListItemText
                    primary="Upload Demos"
                    secondary="Upload up to 50 demos monthly"
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <CheckIcon style={checkIconStyle} />
                  </ListItemIcon>
                  <ListItemText
                    primary="Advanced filtering"
                    secondary="Fine-tune your analysis with custom filters"
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <CheckIcon style={checkIconStyle} />
                  </ListItemIcon>
                  <ListItemText
                    primary="Personalized insights"
                    secondary="Focus on specific aspects of your gameplay"
                  />
                </ListItem>
              </List>
              <Box mt={28} align="center">
                <Link to="/signup" style={{ margin: '0 10px' }}>
                  <Button variant="contained" style={{ width: '180px' }}>
                    Get started
                  </Button>
                </Link>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Paper elevation={3} style={{ padding: '20px' }}>
              <Typography variant="h5" align="center">
                <strong>Club</strong>
              </Typography>
              <Typography align="center" mb={2}>
                Bundle subscription for whole team <br />
                (individual pricing)
              </Typography>
              <Divider variant="middle" />
              <List>
                <ListItem>
                  <ListItemIcon>
                    <CheckIcon style={checkIconStyle} />
                  </ListItemIcon>
                  <ListItemText
                    primary="2D Replay"
                    secondary="Analyze replays of public and your own matches, take notes, and share them with your team"
                  />
                </ListItem>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                    margin: '20px 0',
                    position: 'relative',
                  }}
                >
                  <Box
                    sx={{
                      width: '75%',
                      height: 1.5,
                      bgcolor: 'divider',
                    }}
                  />
                  <AddIcon
                    sx={{
                      position: 'absolute',
                      fontSize: 35,
                      bgcolor: 'white',
                      borderRadius: '70%',
                      padding: '2px',
                      color: 'primary.main',
                    }}
                  />
                </Box>
                <ListItem>
                  <ListItemIcon>
                    <CheckIcon style={checkIconStyle} />
                  </ListItemIcon>
                  <ListItemText
                    primary="Upload Demos"
                    secondary="Upload unlimited demos"
                  />
                </ListItem>
              </List>
              <ListItem>
                <ListItemIcon>
                  <CheckIcon style={checkIconStyle} />
                </ListItemIcon>
                <ListItemText
                  primary="Advanced filtering"
                  secondary="Fine-tune your analysis with custom filters"
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckIcon style={checkIconStyle} />
                </ListItemIcon>
                <ListItemText
                  primary="Personalized insights"
                  secondary="Focus on specific aspects of your gameplay"
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckIcon style={checkIconStyle} />
                </ListItemIcon>
                <ListItemText
                  primary="Early access"
                  secondary="Try out new features before anyone else"
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckIcon style={checkIconStyle} />
                </ListItemIcon>
                <ListItemText
                  primary="Prioritized support"
                  secondary="Get help faster with our prioritized support"
                />
              </ListItem>
              <Box mt={5} align="center">
                <Button
                  variant="contained"
                  style={{ width: '180px' }}
                  href="mailto:support@defuze.gg?subject=Club%20Subscription%20Inquiry&body=Hello%20DEFUZE%2C%0D%0A%0D%0AYour%20features%20are%20looking%20great!%20We%20are%20interested%20in%20a%20Club%20subscription%2C%20let's%20have%20a%20talk."
                >
                  Contact us
                </Button>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}

export default Pricing;
