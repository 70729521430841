import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  IconButton,
  MenuItem,
  Pagination,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material';
import {
  PlayArrow as PlayArrowIcon,
  Delete as DeleteIcon,
} from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axiosInstance from '../../axiosInstance.js';
import DeleteDialog from './DeleteDialog.jsx';
import { useAuth } from '../../utils/AuthContext.js';

function MatchList({
  mapFilter,
  team1Filter,
  team2Filter,
  tabSelection,
  sliderFilter,
  checkedValues,
  roundStatusRadio,
  uploadComplete,
  setUploadComplete,
  deviationSwitch,
}) {
  const [matches, setMatches] = React.useState([]);
  // vars for delete logic
  const [selectedMatches, setSelectedMatches] = useState([]);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  // vars for pagination
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(10);
  const [totalMatches, setTotalMatches] = React.useState(0);
  // var for list sorting
  const [sortOrder, setSortOrder] = useState('desc');
  // var to trigger useEffect when match deletion is confirmed
  const [deleteTrigger, setDeleteTrigger] = useState(false);
  // var to loading logic
  const [isLoading, setIsLoading] = useState(true);
  const { user } = useAuth();

  // Fetch matches whenever filters change
  useEffect(() => {
    if (uploadComplete) {
      setUploadComplete(false);
    }
    const maps = mapFilter === '' ? 'all' : mapFilter;
    const team1 = team1Filter === '' ? 'all' : team1Filter;
    const team2 = team2Filter === '' ? 'all' : team2Filter;
    setIsLoading(true);

    axiosInstance
      .get(`/api/maps`, {
        params: {
          maps,
          team1,
          team2,
          tabSelection,
          page,
          pageSize,
          sliderFilter,
          checkedValues,
          roundStatusRadio,
          sortOrder,
          deviationSwitch,
          userSubscription: user.subscription,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setMatches(response.data.matches);
          setTotalMatches(response.data.totalCount);
        } else {
          console.error('Failed to fetch matches');
        }
      })
      .catch(() => {
        console.error('Failed to fetch matches');
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [
    mapFilter,
    team1Filter,
    team2Filter,
    tabSelection,
    page,
    pageSize,
    sliderFilter,
    checkedValues,
    deleteTrigger,
    roundStatusRadio,
    uploadComplete,
    sortOrder,
    deviationSwitch,
  ]);

  const handlePlayClick = (row) => {
    let url = `/replay?map=${row._id}`;
    if (row.roundNumbers.length < row.scoreTeam1 + row.scoreTeam2) {
      // add rounds to url
      for (let index = 0; index < row.roundIds.length; index += 1) {
        url = url.concat(
          `&round${row.roundNumbers[index]}=${row.roundIds[index]}`,
        );
      }
    }
    window.open(url, '_blank');
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
    // Reset to first page whenever page size changes
    setPage(1);
  };

  const handleDeleteClick = () => {
    setDeleteDialogOpen(true);
  };

  const handleSelectMatch = (match) => {
    setSelectedMatches((prevSelected) => {
      const isAlreadySelected = prevSelected.some((m) => m._id === match._id);
      if (isAlreadySelected) {
        return prevSelected.filter((m) => m._id !== match._id);
      }
      return [...prevSelected, match];
    });
  };

  const handleSelectAllMatches = () => {
    setSelectedMatches((currentSelected) => {
      const allSelected = matches.every((match) =>
        currentSelected.some((selected) => selected._id === match._id),
      );

      if (allSelected) {
        return []; // Deselect all if all are currently selected
      }
      // Select all matches that are not already selected
      const newSelected = [...currentSelected];
      matches.forEach((match) => {
        if (!currentSelected.some((selected) => selected._id === match._id)) {
          newSelected.push(match);
        }
      });
      return newSelected;
    });
  };

  const handleSort = () => {
    setSortOrder((prevOrder) => {
      if (prevOrder === 'asc') {
        return 'desc';
      }
      return 'asc';
    });
  };

  return (
    <Paper elevation={3} sx={{ padding: 3 }}>
      <DeleteDialog
        deleteDialogOpen={deleteDialogOpen}
        selectedMatches={selectedMatches}
        setDeleteDialogOpen={setDeleteDialogOpen}
        deleteTrigger={deleteTrigger}
        setDeleteTrigger={setDeleteTrigger}
        matches={matches}
        setMatches={setMatches}
        setSelectedMatches={setSelectedMatches}
      />
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h6">Matches</Typography>
        {tabSelection === 'My demos' && matches.length > 0 && (
          <IconButton
            onClick={handleDeleteClick}
            disabled={selectedMatches.length === 0}
            sx={{
              '&.MuiButtonBase-root.MuiIconButton-root': {
                padding: '4px',
              },
            }}
          >
            <DeleteIcon />
          </IconButton>
        )}
      </Box>
      {isLoading && (
        <Box display="flex" justifyContent="center" alignItems="center">
          <CircularProgress />
        </Box>
      )}
      {matches.length > 0 && !isLoading && (
        <Table>
          <TableHead>
            <TableRow>
              {tabSelection === 'My demos' && (
                <TableCell
                  sx={{
                    '&.MuiTableCell-root': {
                      padding: 0,
                    },
                  }}
                >
                  <Checkbox
                    checked={matches.every((match) =>
                      selectedMatches.some(
                        (selected) => selected._id === match._id,
                      ),
                    )}
                    indeterminate={
                      selectedMatches.length > 0 &&
                      selectedMatches.length < matches.length
                    }
                    onChange={handleSelectAllMatches}
                  />
                </TableCell>
              )}
              <TableCell>
                <TableSortLabel
                  direction={sortOrder}
                  active
                  onClick={handleSort}
                >
                  Uploaded
                </TableSortLabel>
              </TableCell>
              <TableCell>Winner</TableCell>
              <TableCell>Loser</TableCell>
              <TableCell>Map</TableCell>
              <TableCell>Score</TableCell>
              <TableCell>Rounds</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {matches.map((row, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <TableRow key={index}>
                {tabSelection === 'My demos' && (
                  <TableCell
                    sx={{
                      padding: '0 !important', // override padding
                      width: 'auto',
                    }}
                  >
                    <Checkbox
                      checked={selectedMatches.some(
                        (match) => match._id === row._id,
                      )}
                      onChange={() => handleSelectMatch(row)}
                    />
                  </TableCell>
                )}
                <TableCell>
                  <Typography sx={{ fontSize: '0.85rem' }}>
                    {new Date(row.uploadedDate).toLocaleString('default', {
                      month: 'numeric',
                      day: 'numeric',
                      year: 'numeric',
                    }) || 'No date recorded'}
                  </Typography>
                  <Typography sx={{ fontSize: '0.85rem' }}>
                    {new Date(row.uploadedDate).toLocaleString('default', {
                      hour: 'numeric',
                      minute: 'numeric',
                      hour12: false,
                    }) || ''}
                  </Typography>
                </TableCell>
                <TableCell>{row.winner.name}</TableCell>
                <TableCell>{row.loser.name}</TableCell>
                <TableCell>
                  {row.mapName.startsWith('de_')
                    ? row.mapName.slice(3).charAt(0).toUpperCase() +
                      row.mapName.slice(4)
                    : row.mapName}
                </TableCell>
                <TableCell
                  sx={{ whiteSpace: 'nowrap' }}
                >{`${row.scoreTeam1 > row.scoreTeam2 ? row.scoreTeam1 : row.scoreTeam2} - ${row.scoreTeam1 > row.scoreTeam2 ? row.scoreTeam2 : row.scoreTeam1}`}</TableCell>
                <TableCell>
                  {row.roundNumbers.length} / {row.scoreTeam1 + row.scoreTeam2}
                </TableCell>
                <TableCell
                  sx={{
                    padding: '0 !important', // override padding
                    width: 'auto',
                  }}
                >
                  <Button
                    sx={{
                      minWidth: 0,
                      width: '30px',
                      height: '30px',
                      borderRadius: '50%',
                      padding: 0,
                      backgroundColor: 'primary.main',
                      color: 'white',
                      '&:hover': {
                        backgroundColor: 'primary.dark',
                      },
                    }}
                    onClick={() => handlePlayClick(row)}
                  >
                    <PlayArrowIcon fontSize="small" />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
      {!isLoading && matches.length === 0 && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <>
            {tabSelection === 'My demos' && (
              // eslint-disable-next-line react/jsx-no-useless-fragment
              <>
                {user.subscription === 0 || !user.verified ? (
                  <Typography variant="body2" color="error">
                    You need to verify your email and/or subscribe to a plan to
                    upload demos
                  </Typography>
                ) : (
                  <Box>
                    <Typography variant="body2">
                      No matches available, reset your filters or select public
                      demos
                    </Typography>
                    <Typography variant="body2" sx={{ marginTop: 1 }}>
                      You can also upload your own demos to view them here
                    </Typography>
                  </Box>
                )}
              </>
            )}
            {tabSelection === 'Public demos' && (
              <Typography variant="body2">
                No matches available, try resetting your filters
              </Typography>
            )}
          </>
        </Box>
      )}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mt={2}
      >
        <Pagination
          count={Math.ceil(totalMatches / pageSize)}
          page={page}
          onChange={handlePageChange}
        />
        <Typography variant="body2">
          {totalMatches === 0 ? (
            'No matches available'
          ) : (
            <>
              Showing {(page - 1) * pageSize + 1} to{' '}
              {Math.min(page * pageSize, totalMatches)} of {totalMatches}{' '}
              matches
            </>
          )}
        </Typography>
        <Box display="flex" alignItems="center">
          <Select value={pageSize} onChange={handlePageSizeChange} size="small">
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={25}>25</MenuItem>
            <MenuItem value={50}>50</MenuItem>
          </Select>
          <Typography variant="body2" sx={{ marginLeft: 1 }}>
            per page
          </Typography>
        </Box>
      </Box>
    </Paper>
  );
}

MatchList.propTypes = {
  mapFilter: PropTypes.string.isRequired,
  team1Filter: PropTypes.string.isRequired,
  team2Filter: PropTypes.string.isRequired,
  tabSelection: PropTypes.string.isRequired,
  sliderFilter: PropTypes.shape({}).isRequired,
  checkedValues: PropTypes.shape({}).isRequired,
  roundStatusRadio: PropTypes.string.isRequired,
  uploadComplete: PropTypes.bool.isRequired,
  setUploadComplete: PropTypes.func.isRequired,
  deviationSwitch: PropTypes.bool.isRequired,
};

export default MatchList;
