import { AppBar, Toolbar } from '@mui/material';
import styled from '@mui/material/styles/styled';
import React from 'react';
import logo2 from '../../DefuzeLogoColor.svg';
import logo from '../../DefuzeLogoWhite.svg';

const StyledAppBar = styled(AppBar)({
  borderRadius: 0,
  border: 'none',
});

function TopBarSimple() {
  return (
    <StyledAppBar
      position="sticky"
      style={{ backgroundColor: 'transparent', boxShadow: 'none' }}
    >
      <Toolbar>
        <a href="/">
          <img
            src={logo2}
            alt="Logo"
            style={{ marginRight: 'auto', width: '150px', height: 'auto' }}
          />
        </a>
        {/* <Box display="flex" justifyContent="center" flexGrow={1}>
          <Link to="/" style={{ margin: '0 10px' }}>
            <Button variant="text" style={{ color: 'primary.main' }}>
              Home
            </Button>
          </Link>
        </Box> */}
        <a href="/">
          <img
            src={logo}
            alt="Logo"
            style={{ marginRight: 'auto', width: '150px', height: 'auto' }}
          />
        </a>
      </Toolbar>
    </StyledAppBar>
  );
}

export default TopBarSimple;
