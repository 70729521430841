import React, { useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import { FiberManualRecord } from '@mui/icons-material';
import axiosInstance from '../../axiosInstance.js';
import NotificationSnackbar from '../common/NotificationSnackbar.jsx';

function DeleteDialog({
  deleteDialogOpen,
  setDeleteDialogOpen,
  selectedMatches,
  setMatches,
  matches,
  setSelectedMatches,
  deleteTrigger,
  setDeleteTrigger,
}) {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [loading, setLoading] = useState(false);

  const handleConfirmDelete = () => {
    try {
      setLoading(true);
      const deletePromises = selectedMatches.map((match) =>
        axiosInstance

          .delete(`/api/maps/${match._id}`)
          .then((response) => response)
          .catch((error) => {
            console.debug('Failed to delete match:', error);
            return error.response;
          }),
      );

      Promise.all(deletePromises).then((responses) => {
        const successfulDeletions = responses.filter(
          (response) => response.status === 200,
        );
        if (successfulDeletions.length < 0) {
          console.debug('Failed to delete any matches');
          setSnackbarMessage('Failed to delete any matches');
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
          setLoading(false);
          return;
        }
        if (successfulDeletions.length !== selectedMatches.length) {
          console.debug('Failed to delete some matches');
          setSnackbarMessage('Failed to delete some matches');
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
          setLoading(false);
        }
        setMatches(matches.filter((m) => !selectedMatches.includes(m)));
        setSelectedMatches([]);
        setDeleteDialogOpen(false);
        setDeleteTrigger(!deleteTrigger);
        setLoading(false);

        console.debug('Matches deleted successfully');
        setSnackbarMessage('Matches deleted successfully');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
      });
    } catch (error) {
      console.debug('Failed to delete matches');
      setLoading(false);
      setSnackbarMessage('Failed to delete matches');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  return (
    <>
      <NotificationSnackbar
        severity={snackbarSeverity}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        open={snackbarOpen}
      />
      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
      >
        <DialogTitle>Delete demos?</DialogTitle>
        <DialogContent
          sx={{
            minHeight: '200px',
            minWidth: '400px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {loading ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
              }}
            >
              <CircularProgress size={40} />
              <Typography variant="body1" style={{ marginTop: '16px' }}>
                Deleting...
              </Typography>
            </Box>
          ) : (
            <>
              <Typography variant="body1" gutterBottom>
                The selected demos will be permanently deleted
              </Typography>
              <List
                sx={{
                  width: '100%',
                  maxHeight: '400px',
                  overflow: 'auto',
                  mb: 1,
                }}
              >
                {selectedMatches.map((match) => {
                  if (!matches.some((m) => m._id === match._id)) {
                    return null;
                  }
                  const team1Winner = match.scoreTeam1 > match.scoreTeam2;
                  return (
                    <ListItem sx={{ padding: 0 }} key={match._id}>
                      <ListItemIcon sx={{ minWidth: '36px' }}>
                        <FiberManualRecord sx={{ fontSize: '16px' }} />
                      </ListItemIcon>
                      <ListItemText
                        primary={`${match.mapName} - ${team1Winner ? match.winner.name : match.loser.name} vs ${!team1Winner ? match.winner.name : match.loser.name}`}
                        secondary={new Date(
                          match.uploadedDate,
                        ).toLocaleString()}
                        primaryTypographyProps={{ variant: 'body2' }}
                        secondaryTypographyProps={{ variant: 'caption' }}
                      />
                    </ListItem>
                  );
                })}
              </List>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
          <Button
            disabled={loading}
            variant="contained"
            onClick={handleConfirmDelete}
            color="primary"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

DeleteDialog.propTypes = {
  deleteDialogOpen: PropTypes.bool.isRequired,
  setDeleteDialogOpen: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  selectedMatches: PropTypes.arrayOf(PropTypes.object).isRequired,
  setMatches: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  matches: PropTypes.arrayOf(PropTypes.object).isRequired,
  setSelectedMatches: PropTypes.func.isRequired,
  deleteTrigger: PropTypes.bool.isRequired,
  setDeleteTrigger: PropTypes.func.isRequired,
};

export default DeleteDialog;
