import React from 'react';
import { createTheme } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const buttonStyles = {
  defaultProps: {
    disableRipple: true,
    variant: 'outlined',
  },
  styleOverrides: {
    root: {
      textTransform: 'none',
      borderRadius: 20,
      boxShadow: 'none',
      fontWeight: 700,
      '&:hover': {
        boxShadow: 'none',
      },
    },
    contained: {
      boxShadow: 'none',
    },
  },
};

const PaperStyles = {
  styleOverrides: {
    root: {
      boxShadow: 'none',
      borderRadius: 16,
      border: '1px solid #e0e0e0',
    },
  },
};

const AccordionStyles = {
  styleOverrides: {
    root: {
      boxShadow: 'none',
      border: '1px solid #e0e0e0',
      borderRadius: 20,
      '&:before': {
        display: 'none',
      },
      marginBottom: 5,
    },
  },
};

const AccordionSummaryStyles = {
  defaultProps: {
    expandIcon: <ExpandMoreIcon style={{ color: '#032E40' }} />,
  },
  styleOverrides: {
    root: {
      borderBottom: 'none',
      borderRadius: '0px 16px 0 0',
      '&.Mui-expanded': {
        fontWeight: 'bold',
      },
    },
  },
};

const AccordionDetailsStyles = {
  styleOverrides: {
    root: {
      borderRadius: '0 0 20px 20px',
    },
  },
};

const theme = createTheme({
  palette: {
    primary: {
      main: '#032E40',
    },
    secondary: {
      main: '#66B9C8',
    },
    background: {
      default: '#fff',
    },
    cs2: {
      ct: '#5d79ae',
      ctLight: '#aebcd7',
      ctDark: '#4e699c',
      t: '#de9b35',
      tLight: '#efcd9a',
      tDark: '#c98621',
      smoke: {
        base: '#a0a0a0',
        ct: '#b0b8c4',
        t: '#c4b0a0',
      },
      molotov: '#ff6600',
      flash: '#ffff00',
      he: '#ff0000',
    },
  },
  shape: {
    borderRadius: 12, // Rounded corners for components
  },
  shadows: Array(25).fill('none'),
  components: {
    MuiPaper: {
      ...PaperStyles,
    },
    MuiButton: {
      ...buttonStyles,
    },
    MuiIconButton: {
      ...buttonStyles,
    },
    MuiListItemButton: {
      ...buttonStyles,
    },
    MuiAccordion: {
      ...AccordionStyles,
    },
    MuiAccordionSummary: {
      ...AccordionSummaryStyles,
    },
    MuiAccordionDetails: {
      ...AccordionDetailsStyles,
    },
    MuiSnackbar: {
      defaultProps: {
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      },
    },
  },
});

export default theme;
