import React, { useEffect, useState } from 'react';
import {
  CircularProgress,
  Paper,
  Typography,
  Divider,
  Grid,
  Box,
  Tabs,
  Tab,
  Button,
} from '@mui/material';
import PropTypes from 'prop-types';
import { Search } from '@mui/icons-material';
import { useAuth } from '../../utils/AuthContext.js';
import BlurredBox from '../common/BlurredBox.jsx';
import TooltipTeamName from './TooltipTeamName.jsx';

const cs2Maps = [
  'General',
  'Ancient',
  'Anubis',
  'Dust2',
  'Inferno',
  'Mirage',
  'Nuke',
  'Overpass',
  'Vertigo',
];

function MatchInsights({ analytics, isLoadingAnalytics, team }) {
  const [isBlurred, setIsBlurred] = useState(false);
  const [selectedMap, setSelectedMap] = useState(cs2Maps[0]);
  const [showAllInsights, setShowAllInsights] = useState({});
  let displayedInsights = 0;
  const { user } = useAuth();

  // check user status
  useEffect(() => {
    if (!user) {
      return;
    }
    if (!user.verified || user.subscription === 0) {
      setIsBlurred(true);
    } else {
      setIsBlurred(false);
    }
  }, [user]);

  const renderReviewValue = (value, key, map) => {
    if (!key.includes(map.toLowerCase())) {
      return null;
    }
    if (Array.isArray(value)) {
      return value.map((item, index) => {
        if (typeof item === 'object' && item !== null) {
          if (index !== value.length - 1) {
            return null;
          }
          if (key.includes('StartPrediction')) {
            const win = key.split(/(?=[A-Z])/).pop() === 'Won';
            const predictionPercentage = (item.prediction * 100).toFixed(1);

            return (
              <React.Fragment key={item.roundId.toString()}>
                <Box
                  sx={{
                    mb: 1,
                    p: 0,
                    borderRadius: 1,
                    background: win
                      ? 'radial-gradient(ellipse 80% 80% at 50% 50%, rgba(0,255,0,0.1) 0%, rgba(255,255,255,0) 100%)'
                      : 'radial-gradient(ellipse 80% 80% at 50% 50%, rgba(255,0,0,0.1) 0%, rgba(255,255,255,0) 100%)',
                  }}
                >
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                      {win ? (
                        <>
                          🏆 Incredible comeback! You defied the odds and won a
                          round with only a {predictionPercentage}% chance. What
                          a clutch performance! 💪 Let&apos;s see how you pulled
                          it off!
                        </>
                      ) : (
                        <>
                          😮 Oops! Despite a {predictionPercentage}% win
                          prediction, this round slipped away. No worries, it
                          happens to the best! 🧠 Let&apos;s learn from this and
                          come back stronger!
                        </>
                      )}
                    </Typography>
                    <Button
                      startIcon={<Search />}
                      href={`/replay?map=${item.mapId}&round${item.roundNumber}=${item.roundId}`}
                      sx={{
                        ml: 0.5,
                        transition: 'all 0.3s ease',
                        position: 'relative',
                        overflow: 'hidden',
                        minWidth: '80px',
                        height: '25px',
                        borderRadius: 0,
                        border: 'none',
                        outline: 'none',
                        '&::before': {
                          content: '""',
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          width: '100%',
                          height: '100%',
                          background:
                            'linear-gradient(45deg, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0.5) 50%, rgba(255,255,255,0.1) 100%)',
                          transform: 'translateX(-100%)',
                          transition: 'transform 0.6s ease',
                          backgroundColor: 'transparent',
                        },
                        '&:hover': {
                          border: 'none',
                          background: 'inherit',
                          '&::before': {
                            transform: 'translateX(100%)',
                          },
                        },
                      }}
                    >
                      Review
                    </Button>
                  </Box>
                </Box>
                <Divider sx={{ my: 1 }} />
              </React.Fragment>
            );
          }
          if (key.includes('PlantPrediction')) {
            const win = key.split(/(?=[A-Z])/).pop() === 'Won';
            const predictionPercentage = (item.prediction * 100).toFixed(1);

            return (
              <React.Fragment key={item.roundId.toString()}>
                <Box
                  sx={{
                    mb: 1,
                    p: 0,
                    borderRadius: 1,
                    background: win
                      ? 'radial-gradient(ellipse 75% 80% at 50% 50%, rgba(0,255,0,0.1) 0%, rgba(255,255,255,0) 100%)'
                      : 'radial-gradient(ellipse 75% 80% at 50% 50%, rgba(255,0,0,0.1) 0%, rgba(255,255,255,0) 100%)',
                  }}
                >
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                      {win ? (
                        <>
                          🚀 Post-plant perfection! You turned a{' '}
                          {predictionPercentage}% chance into a victory. Your
                          nerves of steel paid off! 🎯 Let&apos;s see those
                          winning moves!
                        </>
                      ) : (
                        <>
                          💔 Heartbreaker! A {predictionPercentage}% advantage
                          slipped away in the post-plant. It&apos;s all part of
                          the game. 📝 Time to analyze and level up your
                          after-plant game!
                        </>
                      )}
                    </Typography>
                    <Button
                      startIcon={<Search />}
                      href={`/replay?map=${item.mapId}&round${item.roundNumber}=${item.roundId}`}
                      sx={{
                        ml: 0.5,
                        transition: 'all 0.3s ease',
                        position: 'relative',
                        overflow: 'hidden',
                        minWidth: '80px',
                        height: '25px',
                        borderRadius: 0,
                        border: 'none',
                        outline: 'none',
                        '&::before': {
                          content: '""',
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          width: '100%',
                          height: '100%',
                          background:
                            'linear-gradient(45deg, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0.5) 50%, rgba(255,255,255,0.1) 100%)',
                          transform: 'translateX(-100%)',
                          transition: 'transform 0.6s ease',
                          backgroundColor: 'transparent',
                        },
                        '&:hover': {
                          border: 'none',
                          background: 'inherit',
                          '&::before': {
                            transform: 'translateX(100%)',
                          },
                        },
                      }}
                    >
                      Review
                    </Button>
                  </Box>
                </Box>
                <Divider sx={{ my: 1 }} />
              </React.Fragment>
            );
          }
        }
        return null;
      });
    }
    return null;
  };

  const renderInsightValue = (value, key, map) => {
    if (!key.includes(map.toLowerCase())) {
      return null;
    }
    if (Array.isArray(value)) {
      return value.map((item, index) => {
        if (typeof item === 'object' && item.text) {
          if (item.importance <= 0.05 && !showAllInsights[selectedMap]) {
            return null;
          }
          displayedInsights += 1;
          return (
            // eslint-disable-next-line react/no-array-index-key
            <React.Fragment key={index}>
              <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                {item.text}
              </Typography>
              <Divider sx={{ my: 1 }} />
            </React.Fragment>
          );
        }
        return null;
      });
    }
    return <Typography variant="subtitle2">{value}</Typography>;
  };

  const handleMapChange = (event, newValue) => {
    setSelectedMap(newValue);
  };

  const toggleShowAllInsights = () => {
    setShowAllInsights((prev) => ({
      ...prev,
      [selectedMap]: !prev[selectedMap],
    }));
  };

  return (
    <Paper sx={{ padding: 3, mb: 3 }}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ mb: 1 }}
      >
        <Typography variant="h6">Insights</Typography>
        <TooltipTeamName team={team} />
      </Box>
      <div style={{ position: 'relative' }}>
        <Grid
          container
          spacing={2}
          sx={{
            marginTop: 0,
            filter: isBlurred ? 'blur(12px)' : 'none',
            pointerEvents: isBlurred ? 'none' : 'auto',
          }}
        >
          <Grid item xs={12}>
            {isLoadingAnalytics && <CircularProgress />}
            {!analytics && !isLoadingAnalytics && (
              <Typography variant="body1">No data found</Typography>
            )}
            {analytics && analytics.insights && !isLoadingAnalytics && (
              // eslint-disable-next-line react/jsx-no-useless-fragment
              <>
                {Object.keys(analytics.insights).length === 0 ? (
                  <Typography variant="body1">
                    Select a team to see insights
                  </Typography>
                ) : (
                  <>
                    <Box
                      sx={{
                        width: 'calc(100% + 48px)',
                        margin: '0 -24px',
                        position: 'relative',
                      }}
                    >
                      <Tabs
                        value={selectedMap}
                        onChange={handleMapChange}
                        variant="scrollable"
                        scrollButtons="auto"
                        allowScrollButtonsMobile
                        sx={{
                          '& .MuiTabs-flexContainer': {
                            justifyContent: 'flex-start',
                          },
                          '& .MuiTabs-scrollButtons': {
                            '&.Mui-disabled': {
                              opacity: 0.3,
                            },
                            height: '100%',
                            width: '25px',
                            '& svg': {
                              position: 'relative',
                              top: '4px', // Adjust this value to move the buttons up or down
                            },
                          },
                          minHeight: '20px',
                          mb: 1,
                        }}
                      >
                        {cs2Maps.map((map) => (
                          <Tab
                            key={map}
                            label={map}
                            value={map}
                            sx={{
                              minWidth: 'auto',
                              fontSize: '0.8rem',
                              padding: '6px 8px',
                              minHeight: '20px',
                            }}
                          />
                        ))}
                      </Tabs>
                    </Box>
                    {(() => {
                      const mapInsights = Object.keys(
                        analytics.insights,
                      ).filter(
                        (key) =>
                          key.includes(selectedMap.toLowerCase()) ||
                          (selectedMap === 'General' &&
                            !cs2Maps
                              .slice(1)
                              .some((map) => key.includes(map.toLowerCase()))),
                      );

                      if (mapInsights.length === 0) {
                        return selectedMap === 'General' ? (
                          <Typography variant="subtitle2">
                            There are no insights for this team
                          </Typography>
                        ) : (
                          <Typography variant="subtitle2">
                            No insights exist for this map for your selected
                            team, go ahead and upload some of your demos! 🎞️
                          </Typography>
                        );
                      }
                      return (
                        <>
                          {mapInsights.map((key) => (
                            <React.Fragment key={`insight-${key}`}>
                              {renderReviewValue(
                                analytics.insights[key],
                                key,
                                selectedMap,
                              )}
                            </React.Fragment>
                          ))}
                          {mapInsights.map((key) => (
                            <React.Fragment key={`review-${key}`}>
                              {renderInsightValue(
                                analytics.insights[key],
                                key,
                                selectedMap,
                              )}
                            </React.Fragment>
                          ))}
                          {displayedInsights === 0 && (
                            <Typography variant="subtitle2">
                              👏 No critical insights found for this map! 🥳
                            </Typography>
                          )}
                          {displayedInsights > 0 && (
                            <Box sx={{ mt: 2, textAlign: 'center' }}>
                              <Typography
                                variant="body2"
                                component="span"
                                onClick={toggleShowAllInsights}
                                sx={{
                                  cursor: isBlurred ? 'default' : 'pointer',
                                  color: 'primary.main',
                                  fontWeight: 'bold',
                                  textDecoration: 'underline',
                                  '&:hover': {
                                    color: 'primary.dark',
                                  },
                                }}
                              >
                                {showAllInsights[selectedMap]
                                  ? 'Show critical insights only'
                                  : 'Show all insights'}
                              </Typography>
                            </Box>
                          )}
                        </>
                      );
                    })()}
                  </>
                )}
              </>
            )}
          </Grid>
        </Grid>
        {isBlurred && <BlurredBox text="detailed team insights" />}
      </div>
    </Paper>
  );
}

MatchInsights.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  analytics: PropTypes.object,
  isLoadingAnalytics: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  team: PropTypes.object,
};

export default MatchInsights;
