import { Box, CssBaseline, ThemeProvider } from '@mui/material';
import React from 'react';
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import './App.css';
import Dashboard from './components/Dashboard.jsx';
import ForgotPassword from './components/ForgotPassword.jsx';
import Help from './components/Help.jsx';
import LandingPage from './components/LandingPage.jsx';
import MatchHistory from './components/MatchHistory.jsx';
import Navigation from './components/Navigation.jsx';
import Pricing from './components/Pricing.jsx';
import Profile from './components/Profile.jsx';
import Replay from './components/Replay.jsx';
import ResetPassword from './components/ResetPassword.jsx';
import SignIn from './components/SignIn.jsx';
import SignUp from './components/SignUp.jsx';
import theme from './theme.jsx';
import { AuthProvider } from './utils/AuthContext.js';
import { MatchDataProvider } from './utils/MatchDataContext.js';
import { RefProvider } from './utils/RefContext.js';
import ProtectedRoute from './utils/ProtectedRoute.js';
import ErrorFallbackUI from './components/ErrorFallbackUI.jsx';

/*
 The App component is the main component that renders the entire application.
 It uses the BrowserRouter component to enable routing in the application.
 Some routes are custom protected,
 to ensure that only authenticated (signed in) users can access them.
 */
// adjust value to match the nav rail width
const NAV_RAIL_WIDTH = 80;

// eslint-disable-next-line react/jsx-props-no-spreading
const errorFallback = (props) => <ErrorFallbackUI {...props} />;
const spacedErrorFallback = (props) => (
  <Box sx={{ ml: `${NAV_RAIL_WIDTH}px` }}>
    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
    <ErrorFallbackUI {...props} />
  </Box>
);

function RoutesWithErrorBoundary() {
  const location = useLocation();

  return (
    <ErrorBoundary FallbackComponent={errorFallback} key={location.pathname}>
      <Routes>
        <Route
          path="/"
          element={
            <ProtectedRoute protectedRoute={false}>
              <LandingPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard"
          element={
            <Box sx={{ ml: `${NAV_RAIL_WIDTH}px` }}>
              <ErrorBoundary
                FallbackComponent={errorFallback}
                key={location.pathname}
              >
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              </ErrorBoundary>
            </Box>
          }
        />
        <Route
          path="/matches"
          element={
            <Box sx={{ ml: `${NAV_RAIL_WIDTH}px` }}>
              <ErrorBoundary
                FallbackComponent={errorFallback}
                key={location.pathname}
              >
                <ProtectedRoute>
                  <MatchHistory />
                </ProtectedRoute>
              </ErrorBoundary>
            </Box>
          }
        />
        <Route
          path="/replay"
          element={
            <ErrorBoundary
              FallbackComponent={spacedErrorFallback}
              key={location.pathname}
            >
              <ProtectedRoute>
                <MatchDataProvider>
                  <Replay />
                </MatchDataProvider>
              </ProtectedRoute>
            </ErrorBoundary>
          }
        />
        <Route
          path="/profile"
          element={
            <Box sx={{ ml: `${NAV_RAIL_WIDTH}px` }}>
              <ErrorBoundary
                FallbackComponent={errorFallback}
                key={location.pathname}
              >
                <ProtectedRoute>
                  <Profile />
                </ProtectedRoute>
              </ErrorBoundary>
            </Box>
          }
        />
        <Route
          path="/signin"
          element={
            <ProtectedRoute protectedRoute={false}>
              <SignIn />
            </ProtectedRoute>
          }
        />
        <Route
          path="/signup"
          element={
            <ProtectedRoute protectedRoute={false}>
              <SignUp />
            </ProtectedRoute>
          }
        />
        <Route
          path="/forgot-password"
          element={
            <ProtectedRoute protectedRoute={false}>
              <ForgotPassword />
            </ProtectedRoute>
          }
        />
        <Route
          path="/reset-password"
          element={
            <ProtectedRoute protectedRoute={false}>
              <ResetPassword />
            </ProtectedRoute>
          }
        />
        <Route
          path="*"
          element={
            <ProtectedRoute protectedRoute={false}>
              <Navigate to="/" />
            </ProtectedRoute>
          }
        />
        <Route
          path="/pricing"
          element={
            <ProtectedRoute protectedRoute={false}>
              <Pricing />
            </ProtectedRoute>
          }
        />
        <Route
          path="/help"
          element={
            <ProtectedRoute protectedRoute={false}>
              <Help />
            </ProtectedRoute>
          }
        />
      </Routes>
    </ErrorBoundary>
  );
}

function App() {
  return (
    <AuthProvider>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <RefProvider>
            <Navigation />
            <RoutesWithErrorBoundary />
          </RefProvider>
        </ThemeProvider>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
