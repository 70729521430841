import React, { useEffect, useRef } from 'react';
import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import {
  Dashboard as DashboardIcon,
  List as ListIcon,
  PlayCircle as PlayCircleIcon,
  AccountCircle as AccountCircleIcon,
} from '@mui/icons-material';
import { Link, useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import logo from '../../assets/DefuzeLogoSmallWhite.svg';
import { useRefs } from '../../utils/RefContext.js';

const drawerWidth = 80;

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  width: drawerWidth,
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    width: drawerWidth,
    boxSizing: 'border-box',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2, 0),
    borderRadius: 0,
    border: 'none',
  },
}));

const Logo = styled('div')({
  width: '100%',
  height: '50px',
  marginBottom: '0',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

const StyledListItem = styled(ListItem)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(1, 0),
  width: '100%',
}));

const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
  borderRadius: '16px',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(1, 0),
  '&:hover, &.Mui-selected': {
    backgroundColor: 'transparent',
  },
}));

const IconContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'selected',
})(({ theme, selected }) => ({
  width: '58px',
  height: '42px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '28px',
  transition: theme.transitions.create(['background-color'], {
    duration: theme.transitions.duration.short,
  }),
  backgroundColor: selected
    ? theme.palette.primary.contrastText
    : 'transparent',
  '.MuiListItemButton-root:hover &': {
    backgroundColor: selected
      ? theme.palette.primary.contrastText
      : theme.palette.primary.light,
  },
}));

const StyledListItemIcon = styled(ListItemIcon, {
  shouldForwardProp: (prop) => prop !== 'selected',
})(({ theme, selected }) => ({
  minWidth: 'unset',
  color: selected
    ? theme.palette.primary.main
    : theme.palette.primary.contrastText, // dark blue color when selected
}));

const StyledListItemText = styled(ListItemText)(({ theme }) => ({
  marginTop: theme.spacing(1),
  '& .MuiTypography-root': {
    fontSize: '0.75rem',
    fontWeight: 700,
    textAlign: 'center',
    color: theme.palette.primary.contrastText,
  },
}));

const navItems = [
  { text: 'Dashboard', icon: <DashboardIcon />, path: '/dashboard' },
  { text: 'Matches', icon: <ListIcon />, path: '/matches' },
  { text: 'Replay', icon: <PlayCircleIcon />, path: '/replay' },
];

function NavigationRail() {
  const location = useLocation();
  const refDashboard = useRef(null);
  const refMatches = useRef(null);
  const refReplay = useRef(null);
  const { setRef } = useRefs();

  useEffect(() => {
    setRef('barDashboard', refDashboard.current);
    setRef('barMatches', refMatches.current);
    setRef('barReplay', refReplay.current);
  }, [refDashboard, refMatches, refReplay]);

  return (
    <StyledDrawer variant="permanent">
      <Logo>
        <Link to="/dashboard">
          <img
            src={logo}
            alt="Logo"
            style={{ width: '50px', height: '50px' }}
          />
        </Link>
      </Logo>
      <List
        sx={{
          width: '100%',
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          marginTop: '20px',
          marginBottom: '60px',
        }}
      >
        {navItems.map((item) => (
          <StyledListItem key={item.text} disablePadding>
            <StyledListItemButton
              ref={
                item.text === 'Dashboard'
                  ? refDashboard
                  : item.text === 'Matches'
                    ? refMatches
                    : item.text === 'Replay'
                      ? refReplay
                      : null
              }
              component={Link}
              to={item.path}
              selected={location.pathname === item.path}
            >
              <IconContainer selected={location.pathname === item.path}>
                <StyledListItemIcon selected={location.pathname === item.path}>
                  {item.icon}
                </StyledListItemIcon>
              </IconContainer>
              <StyledListItemText primary={item.text} />
            </StyledListItemButton>
          </StyledListItem>
        ))}
      </List>
      <Box sx={{ mt: 'auto' }}>
        <StyledListItemButton
          component={Link}
          to="/profile"
          selected={location.pathname === '/profile'}
          sx={{
            width: '56px',
            height: '56px',
          }}
        >
          <IconContainer
            selected={location.pathname === '/profile'}
            sx={{ width: '56px', height: '56px' }}
          >
            <StyledListItemIcon selected={location.pathname === '/profile'}>
              <AccountCircleIcon sx={{ fontSize: '2rem' }} />
            </StyledListItemIcon>
          </IconContainer>
        </StyledListItemButton>
      </Box>
    </StyledDrawer>
  );
}

export default NavigationRail;
