import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import PropTypes from 'prop-types';
import React from 'react';
import {
  AssessmentOutlined,
  CloudUpload,
  TuneOutlined,
  DriveFileRenameOutline,
} from '@mui/icons-material';
import CheckoutForm from './CheckoutForm';

const stripePromise = loadStripe(
  'pk_test_51POIoJHwJjLbaL5jhA89Uy53jQ60vXnUgtI4hKKsXogwJtzdaMoJ7Ghhp7YfG8RzpdMJz0MBFEGerIHJJcOPQbKF00qmO5JlFz',
);

function UpgradeDialog({ isUpgradeDialogOpen, setIsUpgradeDialogOpen }) {
  return (
    <Dialog
      open={isUpgradeDialogOpen}
      onClose={() => setIsUpgradeDialogOpen(false)}
    >
      <DialogTitle>Upgrade and become a Pro</DialogTitle>
      <DialogContent>
        <List>
          <ListItem>
            <ListItemIcon>
              <AssessmentOutlined />
            </ListItemIcon>
            <ListItemText
              primary="Advanced dashboard"
              secondary="Team and player statistics with personalized insights"
            />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <CloudUpload />
            </ListItemIcon>
            <ListItemText
              primary="Demo upload"
              secondary="Review your own matches with AI evaluation"
            />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <TuneOutlined />
            </ListItemIcon>
            <ListItemText
              primary="Advanced round filters"
              secondary="Fine-tune your analysis with custom filters"
            />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <DriveFileRenameOutline />
            </ListItemIcon>
            <ListItemText
              primary="In-game notes"
              secondary="Attach notes and drawings to specific moments and share them"
            />
          </ListItem>
        </List>
        <Typography
          variant="body2"
          color="textSecondary"
          align="center"
          style={{ marginTop: '16px' }}
        >
          Unlock all Pro features for just €9.99 per month
        </Typography>
      </DialogContent>
      <DialogActions>
        <Box sx={{ mb: 1, mr: 1 }}>
          <Button
            variant="text"
            onClick={() => setIsUpgradeDialogOpen(false)}
            color="primary"
            sx={{ mr: 1 }}
          >
            Maybe later
          </Button>
          <Elements stripe={stripePromise}>
            <CheckoutForm />
          </Elements>
        </Box>
      </DialogActions>
    </Dialog>
  );
}

UpgradeDialog.propTypes = {
  isUpgradeDialogOpen: PropTypes.bool.isRequired,
  setIsUpgradeDialogOpen: PropTypes.func.isRequired,
};

export default UpgradeDialog;
