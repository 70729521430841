import React, { useEffect, useRef, useState } from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import EmailVerificationSnackbar from './common/EmailVerificationSnackbar';
import UploadFunctionality from './matches/UploadFunctionality';
import MatchList from './matches/MatchList';
import MatchSelection from './matches/MatchSelection.jsx';
import MatchFilter from './matches/MatchFilter.jsx';
import CustomTooltip from './CustomTooltip.jsx';
import { useAuth } from '../utils/AuthContext.js';

const tooltipConfigs = [
  {
    component: 'Selection',
    content: (
      <Box>
        <Typography variant="subtitle2">
          Select matches by participating team&apos;s and map
        </Typography>
      </Box>
    ),
    placement: 'left',
    anchorRef: null,
  },
  {
    component: 'List',
    content: (
      <Box>
        <Typography variant="subtitle2">
          Select matches that fit your filters and dive right into reviewing
        </Typography>
      </Box>
    ),
    placement: 'left',
    anchorRef: null,
  },
  {
    component: 'Upload',
    content: (
      <Box>
        <Typography variant="subtitle2">
          Upload your personal demo files or explore public demos
        </Typography>
      </Box>
    ),
    placement: 'left',
    anchorRef: null,
  },
  {
    component: 'Filter',
    content: (
      <Box>
        <Typography variant="subtitle2">
          Apply filters to selected match rounds based on AI predictions,
          team&apos;s average money values, and round outcomes to match your
          interests
        </Typography>
      </Box>
    ),
    placement: 'right',
    anchorRef: null,
  },
];

function MatchHistory() {
  const [mapFilter, setMapFilter] = useState(
    sessionStorage.getItem('mapFilter') || '',
  );
  const [team1Filter, setTeam1Filter] = useState(
    sessionStorage.getItem('team1Filter') || '',
  );
  const [team2Filter, setTeam2Filter] = useState(
    sessionStorage.getItem('team2Filter') || '',
  );
  const [tabSelection, setTabSelection] = useState(
    sessionStorage.getItem('tabSelection') || 'Public demos',
  );
  const [deviationSwitch, setDeviationSwitch] = useState(
    JSON.parse(sessionStorage.getItem('deviationSwitch')) || false,
  );
  // bool to trigger refetch when processing is finished
  const [uploadComplete, setUploadComplete] = useState(false);
  const [sliderFilter, setSliderFilter] = useState({});

  const [checkedValues, setCheckedValues] = useState(() => {
    const storedValue = JSON.parse(sessionStorage.getItem('checkedValues'));
    return (
      storedValue || {
        bombExploded: false,
        bombDefused: false,
        noTime: false,
        tElimination: false,
        cTElimination: false,
      }
    );
  });
  // State to manage the Team 1 and Team 2 radio buttons
  const [roundStatusRadio, setRoundStatusRadio] = useState(
    sessionStorage.getItem('roundStatusRadio') || 'all',
  );

  const theme = useTheme();
  const { user } = useAuth();

  const [tutMatches, setTutMatches] = useState(
    localStorage.getItem('tutMatches') === 'true' ||
      localStorage.getItem('tutMatchesSub') === 'true' ||
      false,
  );
  const [currentTooltipIndex, setCurrentTooltipIndex] = useState(
    localStorage.getItem('tutMatchesSub') === 'true' ? 2 : 0,
  );
  const uploadAnchorRef = useRef(null);
  const selectionAnchorRef = useRef(null);
  const listAnchorRef = useRef(null);
  const filterAnchorRef = useRef(null);
  const [isBlurred, setIsBlurred] = useState(false);

  useEffect(() => {
    tooltipConfigs[2].anchorRef = uploadAnchorRef;
    tooltipConfigs[0].anchorRef = selectionAnchorRef;
    tooltipConfigs[1].anchorRef = listAnchorRef;
    tooltipConfigs[3].anchorRef = filterAnchorRef;
  }, [uploadAnchorRef, selectionAnchorRef, listAnchorRef, filterAnchorRef]);

  // Save the filters in the session storage
  useEffect(() => {
    sessionStorage.setItem('mapFilter', mapFilter);
    sessionStorage.setItem('team1Filter', team1Filter);
    sessionStorage.setItem('team2Filter', team2Filter);
    sessionStorage.setItem('roundStatusRadio', roundStatusRadio);
    sessionStorage.setItem('tabSelection', tabSelection);
    sessionStorage.setItem('checkedValues', JSON.stringify(checkedValues));
    sessionStorage.setItem('deviationSwitch', deviationSwitch);
  }, [
    mapFilter,
    team1Filter,
    team2Filter,
    roundStatusRadio,
    tabSelection,
    checkedValues,
    deviationSwitch,
  ]);

  // check user status
  useEffect(() => {
    if (!user) {
      return;
    }
    if (!user.verified || user.subscription === 0) {
      setIsBlurred(true);
    } else {
      setIsBlurred(false);
    }
  }, [user]);

  return (
    <Container>
      <EmailVerificationSnackbar />
      {tooltipConfigs.map((config, index) => {
        let maxIndex = tooltipConfigs.length - 1;
        let offset = 0;
        if (isBlurred) {
          maxIndex = 1;
        }
        if (localStorage.getItem('tutMatchesSub') === 'true') {
          offset = 2;
        }
        if (isBlurred && (index === 2 || index === 3)) {
          return null;
        }
        return (
          <CustomTooltip
            /* eslint-disable-next-line react/no-array-index-key */
            key={index}
            open={tutMatches && currentTooltipIndex === index}
            content={config.content}
            currentTooltipIndex={currentTooltipIndex}
            setCurrentTooltipIndex={setCurrentTooltipIndex}
            setTut={setTutMatches}
            placement={config.placement}
            anchorRef={config.anchorRef}
            maxIndex={maxIndex}
            sessionItem="tutMatches"
            offset={offset}
          />
        );
      })}
      <Grid container spacing={2} sx={{ marginTop: 1, mb: 3 }}>
        <Grid item container spacing={2} xs={12} sm={8} direction="column">
          <Grid item ref={uploadAnchorRef}>
            <Box
              sx={{
                borderRadius: '16px',
                outline:
                  currentTooltipIndex === 2 && tutMatches
                    ? `2px solid ${theme.palette.secondary.main}`
                    : 'none',
              }}
            >
              <UploadFunctionality
                setUploadComplete={setUploadComplete}
                tabSelection={tabSelection}
                setTabSelection={setTabSelection}
              />
            </Box>
          </Grid>
          <Grid item ref={selectionAnchorRef}>
            <Box
              sx={{
                borderRadius: '16px',
                outline:
                  currentTooltipIndex === 0 && tutMatches
                    ? `2px solid ${theme.palette.secondary.main}`
                    : 'none',
              }}
            >
              <MatchSelection
                mapFilter={mapFilter}
                setMapFilter={setMapFilter}
                team1Filter={team1Filter}
                setTeam1Filter={setTeam1Filter}
                team2Filter={team2Filter}
                setTeam2Filter={setTeam2Filter}
              />
            </Box>
          </Grid>
          <Grid item ref={listAnchorRef}>
            <Box
              sx={{
                borderRadius: '16px',
                outline:
                  currentTooltipIndex === 1 && tutMatches
                    ? `2px solid ${theme.palette.secondary.main}`
                    : 'none',
              }}
            >
              <MatchList
                mapFilter={mapFilter}
                team1Filter={team1Filter}
                team2Filter={team2Filter}
                tabSelection={tabSelection}
                checkedValues={checkedValues}
                sliderFilter={sliderFilter}
                roundStatusRadio={roundStatusRadio}
                setUploadComplete={setUploadComplete}
                uploadComplete={uploadComplete}
                deviationSwitch={deviationSwitch}
              />
            </Box>
          </Grid>
        </Grid>

        <Grid item container spacing={2} xs={12} sm={4} direction="column">
          <Grid item ref={filterAnchorRef}>
            <Box
              sx={{
                borderRadius: '16px',
                outline:
                  currentTooltipIndex === 3 && tutMatches
                    ? `2px solid ${theme.palette.secondary.main}`
                    : 'none',
              }}
            >
              <MatchFilter
                sliderFilter={sliderFilter}
                setSliderFilter={setSliderFilter}
                team1Filter={team1Filter}
                setTeam1Filter={setTeam1Filter}
                team2Filter={team2Filter}
                setTeam2Filter={setTeam2Filter}
                mapFilter={mapFilter}
                setMapFilter={setMapFilter}
                tabSelection={tabSelection}
                setTabSelection={setTabSelection}
                checkedValues={checkedValues}
                setCheckedValues={setCheckedValues}
                roundStatusRadio={roundStatusRadio}
                setRoundStatusRadio={setRoundStatusRadio}
                deviationSwitch={deviationSwitch}
                setDeviationSwitch={setDeviationSwitch}
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}

export default MatchHistory;
