import {
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  Typography,
} from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import { Info } from '@mui/icons-material';

function TooltipTeamName({ team }) {
  return (
    team &&
    team.name &&
    team.name !== 'Dummy' && (
      <Box
        display="flex"
        alignItems="center"
        sx={{ justifyContent: 'center' }}
        gap={1}
      >
        {team && team.aliases.length !== 0 && (
          <Tooltip
            title={
              <List
                dense
                sx={{
                  maxHeight: '300px',
                  overflowY: 'auto',
                  '&::-webkit-scrollbar': { width: '0.4em' },
                  '&::-webkit-scrollbar-thumb': {
                    backgroundColor: 'rgba(0,0,0,.1)',
                  },
                  mb: 1,
                }}
              >
                {team && team.aliases.length > 0 ? (
                  team.aliases.map((alias, index) => (
                    <React.Fragment key={alias}>
                      <ListItem>
                        <ListItemText primary={alias} />
                      </ListItem>
                      {index < team.aliases.length - 1 && (
                        <Divider component="li" />
                      )}
                    </React.Fragment>
                  ))
                ) : (
                  <ListItem>
                    <ListItemText primary="No aliases" />
                  </ListItem>
                )}
              </List>
            }
          >
            <IconButton size="small">
              <Info sx={{ fontSize: '0.9em' }} />
            </IconButton>
          </Tooltip>
        )}
        <Typography variant="subtitle2" color="text.secondary">
          {team.name}
        </Typography>
      </Box>
    )
  );
}

TooltipTeamName.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  team: PropTypes.object,
};

export default TooltipTeamName;
